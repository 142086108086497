import constants from "./ggAbonne.constants";
import get from "lodash.get";

function success(result) {
	toastr.success("Demande d'indemnisation enregistrée avec succès");
	setTimeout(redirection, 1000);
	return result;
}
export default {
	[constants.GG_SAVE_DEMANDE](context, payload) {
		const image = get(payload, "demande.justificatif.image");
		const data = new FormData();
		data.append("alreadyexists", get(payload, "alreadyexists"));
		data.append(
			"existingAbonne",
			JSON.stringify(get(payload, "existingAbonne"))
		);
		data.append("abonne", JSON.stringify(get(payload, "abonne")));
		data.append("demande", JSON.stringify(get(payload, "demande")));
		data.append("file", image);
		return $.ajax({
			url: "/api/ggAbonne",
			data: data,
			cache: false,
			contentType: false,
			dataType: "text",
			processData: false,
			type: "POST",
			success: success,
			error: function (err) {
				if (err.status === 409) {
					err.message = "Une demande existe déjà pour cet abonné";
				} else if (err.status === 400) {
					err.message = JSON.parse(err.responseText).message;
				} else {
					err.message =
						"Erreur lors de l'enregistrement de votre demande. Merci de réessayer plus tard.";
				}
				return Promise.reject(err);
			},
		});
	},
	[constants.GG_SAVE_DEMANDE_OCCASIONNEL](context, payload) {
		return $.ajax({
			url: "/api/ggAbonne/occasionnel",
			data: JSON.stringify(payload),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			type: "POST",
			success: success,
			error: function (err) {
				if (err.status === 409) {
					err.message =
						"Une demande existe déjà pour ce client occasionnel";
				} else if (err.status === 400) {
					err.message = JSON.parse(err.responseText).message;
				} else {
					err.message =
						"Erreur lors de l'enregistrement de votre demande. Merci de réessayer plus tard.";
				}
				return Promise.reject(err);
			},
		});
	},
};

const redirection = () => {
	window.location.href = "validation-demande";
};
