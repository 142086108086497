import regionRulesConst from "../regionRules/regionRules.constants";
import subscriptionConst from "./subscription.constants";

const initialPayload = {
	referenceClient: "",
	// premier jour du mois suivant
	startDate: moment().add(1, "M").startOf("M").toJSON(),
	//dernier jour du mois suivant
	endDate: moment().add(1, "M").endOf("M").toJSON(),
	selectedTrains: [
		{
			from: {
				label: "",
				uic: "",
			},
			to: {
				label: "",
				uic: "",
			},
			departureTime: "",
			arrivalTime: "",
			numberTrain: "",
		},
		{
			from: "",
			to: "",
			departureTime: "",
			arrivalTime: "",
			numberTrain: "",
		},
	],
	region: "",
};

export default {
	[subscriptionConst.FETCH_SUBSCRIPTIONS]({ commit, state, dispatch }) {
		return $.ajax({
			url: "/api/souscription",
		}).then(function (result) {
			if (Object.keys(state.regionRules).length !== 0) {
				if (!Array.isArray(result)) {
					result = [result];
				}
				commit(subscriptionConst.FETCH_SUBSCRIPTIONS, result);
				return result;
			}
			return dispatch(regionRulesConst.FETCH_REGION_RULES).then(() => {
				commit(subscriptionConst.FETCH_SUBSCRIPTIONS, result);
				return result;
			});
		});
	},
	// eslint-disable-next-line no-unused-vars
	[subscriptionConst.SAVE_SUBSCRIPTION](
		actions = {},
		payload = initialPayload
	) {
		// TODO Problème format
		const modification = payload.modification;
		delete payload.modification;
		return $.ajax({
			url: "/api/souscription",
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(payload),
			type: "POST",
		})
			.then(function (result) {
				toastr.info("Souscription enregistrée");
				setTimeout(() => {
					window.location.href = `validation-souscription?modification=${modification}`;
				}, 1000);
				return result;
			})
			.catch(function (err) {
				if (err.status === 400 && err.responseText) {
					toastr.error(err.responseText);
				} else {
					toastr.error(
						"Erreur lors de l'enregistrement de la souscription, veuillez réessayer plus tard."
					);
				}
				return Promise.reject(err);
			});
	},
};
