import actions from "./trip.constants";

export default {
	[actions.FETCH_TRIP](state, trip) {
		state.trips = {
			departure: trip.departure.result,
			formattedDateDeparture: trip.departure.formattedDate,
			arrival: trip.arrival.result,
			formattedDateArrival: trip.arrival.formattedDate,
			noMoreTrips: {
				arrival: trip.arrival.result === [],
				departure: trip.departure.result === [],
			},
		};
		return state.trips;
	},
	[actions.CLEAN_TRIPS](state) {
		state.trips = {
			departure: [],
			formattedDateDeparture: null,
			arrival: [],
			formattedDateArrival: null,
			reset: true,
			noMoreTrips: {
				arrival: false,
				departure: false,
			},
		};
	},
	[actions.FETCH_TRIP_LINE](state, tripLine) {
		state.tripLine = tripLine;
		return state.tripLine;
	},
	[actions.PUSH_TRIP](state, trip) {
		// On part du principe que les deux seule valeurs possibles sont "Aller" et "Retour"
		const sens = trip.sens === "Aller" ? "departure" : "arrival";
		if (trip.result.length > 0) {
			state.trips[sens] = state.trips[sens].concat(trip.result);
		} else {
			state.trips.noMoreTrips[sens] = true;
		}
	},
};
