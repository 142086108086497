import constants from "./trip.constants";
import uniqWith from "lodash.uniqwith";
import isEqual from "lodash.isequal";
import reUseConstants from "../constants";
import superagent from "superagent";

const MESSAGE_SUCCESS = "Recherche d'itinéraires";
const MESSAGE_FAIL =
	"Recherche terminée en échec. Merci de réessayer plus tard";

const constructSearchObject = (departure, arrival, time, weekday = "mardi") => {
	return {
		departure: {
			name: departure.name,
			uic: departure.uic,
		},
		arrival: {
			name: arrival.name,
			uic: arrival.uic,
		},
		options: {
			date: moment()
				.day(moment.localeData("fr").weekdaysParse(weekday) + 7)
				.hours(time)
				.startOf("hour")
				.format(), // On fait une recherche dans le futur (+ 7)
			modes: ["TRAIN_TER", "TRAMTRAIN"],
		},
	};
};

async function callService({ commit }, payload) {
	try {
		const departureRes = await callTrip(
			payload.departure,
			payload.arrival,
			payload.time.departure,
			payload.weekday,
			payload.currentTrains,
			"Aller"
		);

		const arrivalRes = await callTrip(
			payload.arrival,
			payload.departure,
			payload.time.arrival,
			payload.weekday,
			payload.currentTrains,
			"Retour"
		);

		return commit(constants.FETCH_TRIP, {
			departure: departureRes.body,
			arrival: arrivalRes.body,
		});
	} catch (err) {
		toastr.error(MESSAGE_FAIL, MESSAGE_SUCCESS);
		return Promise.reject(err);
	}
}

async function pushTrip({ commit }, payload, sens) {
	try {
		const { body } = await callTrip(
			payload.departure,
			payload.arrival,
			payload.time,
			payload.weekday,
			payload.currentTrains,
			sens
		);
		body.sens = sens;
		return commit(constants.PUSH_TRIP, body);
	} catch (err) {
		toastr.error(MESSAGE_FAIL, MESSAGE_SUCCESS);
		return Promise.reject(err);
	}
}
async function callTrip(
	departure,
	arrival,
	time,
	weekday,
	currentTrains,
	label
) {
	const tripSearchObjet = constructSearchObject(
		departure,
		arrival,
		time,
		weekday
	);
	const payload = {
		tripQuery: tripSearchObjet,
		currentTrains: currentTrains,
		label: label,
	};
	try {
		return await superagent.post(`/api/trip`).send(payload);
	} catch (err) {
		toastr.error(MESSAGE_FAIL, MESSAGE_SUCCESS);
		return Promise.reject(err);
	}
}

/**
 * Appel vers le service trip
 */
function callTripLine(departure, arrival, time, weekday) {
	return $.ajax({
		url: "/api/trip",
		data: constructSearchObject(departure, arrival, time, weekday),
	})
		.then((result) => {
			return Promise.resolve(result);
		})
		.catch((err) => {
			toastr.error("Erreur lors de la récupération des trajets");
			return Promise.reject(err);
		});
}

/**
 * Appel récursif vers le trip pour rechercher les trajets de la journée ou jusqu'à un trajet direct
 * @param {any} payload - objet représentant l'O-D
 * @param {any[]} tripsLine - tableau courant des trajets recherchés
 * @param {number} time - heure de début de recherche
 * @returns {Promise<[] | string>}
 */
async function tripOfTheDay(payload, tripsLine = [], time = 0) {
	try {
		//Condition d'arret : s'il n'y a pas de TER de toute la journée
		if (time >= 24) {
			//Si aucun résultat TER n'a été trouvé, erreur
			if (tripsLine.length === 0) {
				return "Aucune ligne TER n'a été trouvée.";
			}

			//S'il y a des lignes non-directes, on les renvoie toutes pour que l'utilisateur puisse choisir
			return buildViafromTrip(tripsLine);
		}

		const trainTypesAccepted = ["TRAIN_TER", "TRAMTRAIN"];
		//Appel à trip qui renvoie les deux premiers trajets à partir de l'heure donnée (time)
		const trips = await callTripLine(
			payload.departure,
			payload.arrival,
			time
		);

		if (!trips.result) {
			return "Erreur lors de la recherche de ligne.";
		}

		trips.result.shift();
		//On filtre les trajets obtenus pour ne garder que les TER
		const tripsTER = trips.result.filter((trip) => {
			return trip[reUseConstants.TRIP_PATTERN].trip[
				reUseConstants.RIDE_IN_TRIP
			].some((rideInTrip) => {
				return trainTypesAccepted.includes(
					rideInTrip.ride[reUseConstants.JOURNEY_PATTERN][
						reUseConstants.VEHICLE_JOURNEY
					].code
				);
			});
		});

		const directTrip = tripsTER.find(
			(trip) =>
				trip[reUseConstants.TRIP_PATTERN].trip[
					reUseConstants.RIDE_IN_TRIP
				].length === 1
		);

		//S'il y a un train direct pour cette O-D, on le renvoie puisque ce sera la meilleure ligne pour l'utilisateur
		if (directTrip) {
			return [buildFormatDirectTrip(directTrip)];
		}

		//On évite les résultats erronés qui pourraient nous etre renvoyés (date différente de celle du jour, train en double)
		const searchDate =
			trips &&
			trips.result.length > 0 &&
			trips.result[0][reUseConstants.TRIP_PATTERN][
				reUseConstants.OPERATING_DAY
			].date;

		const newTrips = tripsTER
			.filter(
				(trip) =>
					trip[reUseConstants.TRIP_PATTERN][
						reUseConstants.OPERATING_DAY
					].date === searchDate
			)
			.filter(
				(trip) =>
					!tripsLine.some(
						(tripLine) =>
							trip[reUseConstants.TRIP_PATTERN].id ===
							tripLine[reUseConstants.TRIP_PATTERN].id
					)
			);

		tripsLine = tripsLine.concat(newTrips);

		//S'il n'y a aucun trajet direct trouvé, on refait donc un appel pour 2h plus tard dans la journée
		return tripOfTheDay(
			{ departure: payload.departure, arrival: payload.arrival },
			tripsLine,
			time + 2
		);
	} catch (e) {
		toastr.error(MESSAGE_FAIL, MESSAGE_SUCCESS);
		return Promise.reject(e);
	}
}

function buildFormatDirectTrip(direct) {
	const ridesInTrip =
		direct[reUseConstants.TRIP_PATTERN].trip[reUseConstants.RIDE_IN_TRIP];
	const rideFrom = ridesInTrip[0].ride.from;
	const rideTo = ridesInTrip[0].ride.to;
	return {
		from: {
			label: rideFrom.name,
			uic: /\d{8}/.exec(rideFrom.id)[0],
		},
		to: {
			label: rideTo.name,
			uic: /\d{8}/.exec(rideTo.id)[0],
		},
	};
}

function buildViafromTrip(tripForLine) {
	let tripFiltered = tripForLine.map((trip) => {
		const ridesInTrip =
			trip[reUseConstants.TRIP_PATTERN].trip[reUseConstants.RIDE_IN_TRIP];
		const builtTrip = {};
		if (ridesInTrip.length > 1) {
			// suppression des OD ne comportant pas d'uic
			const ridesInTripWithId = ridesInTrip.filter((rides) => {
				return rides.ride.to.id && rides.ride.from.id;
			});
			builtTrip.via = ridesInTripWithId.slice(0, -1).map((rides) => {
				return {
					uic: /\d{8}/.exec(rides.ride.to.id)[0],
					label: rides.ride.to.name,
				};
			});

			builtTrip.correspondances = ridesInTripWithId.map((rides) => {
				return {
					from: {
						uic: /\d{8}/.exec(rides.ride.from.id)[0],
						label: rides.ride.from.name,
					},
					to: {
						uic: /\d{8}/.exec(rides.ride.to.id)[0],
						label: rides.ride.to.name,
					},
				};
			});
		}

		return builtTrip;
	});

	// Tableau des tailles des correspondances
	const tmpLength = [];

	tripFiltered.forEach((trip) => {
		if (trip.via) {
			tmpLength.push(trip.via.length);
		}
	});

	// Récupere la plus petite taille
	const minLength = Math.min(...tmpLength);

	// Retourne les correspondances qui on la plus petite taille de tableau
	tripFiltered = tripFiltered.filter((trip) => {
		let res = false;
		if (trip.via) {
			res = trip.via.length === minLength;
		} else {
			res = true;
		}
		return res;
	});

	return uniqWith(tripFiltered, (trip1, trip2) => {
		return isEqual(
			trip1.via.map((via) => via.uic),
			trip2.via.map((via) => via.uic)
		);
	});
}

export default {
	[constants.FETCH_TRIP]({ commit, getters }, payload) {
		payload.currentTrains = getters.getCurrentTrains;
		return callService({ commit }, payload);
	},
	[constants.PUSH_TRIP]({ commit, getters }, payload) {
		const sens = payload.sens;
		payload.currentTrains = getters.getCurrentTrains;
		delete payload.sens;
		return pushTrip({ commit }, payload, sens);
	},
	[constants.CLEAN_TRIPS]({ commit }) {
		commit(constants.CLEAN_TRIPS, {});
	},
	[constants.FETCH_TRIP_LINE](context, payload) {
		return tripOfTheDay(payload);
	},
};
