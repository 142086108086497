<template>
	<div class="text-center my-5 col-12">
		<b-form
			:action="`/mon-remboursement-ter/${region}/formulaire-indemnisation`"
			method="post"
			@submit="checkForm"
		>
			<b-form-group>
				<label>
					<b>N° carte billettique* :</b>
					<i
						v-b-tooltip.hover
						class="fa fa-question-circle fa-2x"
						:title="
							$t('garantieGreve.ggAccueil.billettique.messageNumeroAbonne')
						"
						data-placement="right"
					></i>
				</label>
				<b-form-input
					id="referenceClient"
					v-model="form.referenceClient"
					v-validate="'required'"
					class="m-auto col-sm-4"
					type="number"
					name="referenceClient"
					:state="validateState('referenceClient')"
					aria-describedby="referenceClient-live-feedback"
					data-vv-as="numéro d'abonné"
					placeholder="0123456789"
				></b-form-input>
				<b-form-invalid-feedback id="referenceClient-live-feedback">
					{{ veeErrors.first("referenceClient") }}
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group>
				<label>
					<b>Date de naissance* :</b>
					<i
						v-b-tooltip.hover
						class="fa fa-question-circle fa-2x"
						:title="
							$t('garantieGreve.ggAccueil.billettique.messageDateNaissance')
						"
						data-placement="right"
					></i>
				</label>
				<b-form-input
					id="dateNaissance"
					v-model="form.dateNaissance"
					v-validate="'required'"
					type="date"
					class="m-auto col-sm-4"
					name="dateNaissance"
					:state="validateState('dateNaissance')"
					aria-describedby="dateNaissance-live-feedback"
					data-vv-as="date de naissance"
				></b-form-input>
				<b-form-invalid-feedback id="dateNaissance-live-feedback">
					{{ veeErrors.first("dateNaissance") }}
				</b-form-invalid-feedback>
			</b-form-group>
			<b-button type="submit" class="mt-2 mr-2" variant="success"
				>Se connecter
			</b-button>
		</b-form>
	</div>
</template>

<script>
export default {
	name: "GgAccueilBillettique",
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				referenceClient: "",
				dateNaissance: "",
			},
		};
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
	},
};
</script>

<style scoped></style>
