<template>
	<div class="my-5 pb-5">
		<div class="align-self-center">
			<br />
			<info-abonnement></info-abonnement>
			<br />
			<br />
			<br />
			<div class="d-inline-block d-flex justify-content-center">
				<div class="text-center">
					<div class="d-flex d-inline-block mb-4">
						<div class="my-auto mx-3">
							<h2>
								Date de validité de l'abonnement <br />
								<strong>
									{{ displayDate() }}
								</strong>
							</h2>
						</div>
					</div>
				</div>
			</div>
			<div>
				<h2 class="text-center mb-4">
					<strong>{{ $t("accueilInscrit.rappelIndemnisation") }}</strong>
				</h2>
				<ul>
					<li>{{ $t("accueilInscrit.puce_1") }}</li>
					<li>{{ $t("accueilInscrit.puce_2") }}</li>
				</ul>
			</div>
			<!--			Affichage pour les trajets avec trains			-->
			<tableau-trajet :trips="trips" :need-select="false"></tableau-trajet>
			<br />
			<!--			Affichage des messages concernants l'éligiblité-->
			<div class="d-flex justify-content-center">
				<h5 v-if="abonnement.eligibilite && abonnement.eligibilite.eligible">
					{{ $t("accueilInscrit.messageIndem") }}
				</h5>
				<h5
					v-else-if="
						abonnement.eligibilite && abonnement.eligibilite.eligible === false
					"
				>
					{{ $t("accueilInscrit.messageNonIndem") }}
				</h5>
				<h5 v-else-if="!abonnementEndDatePassed">
					{{ $t("accueilInscrit.pasDeResultatsAbonnement") }}
					{{ addDayToDate(abonnement.dateFin, 2) }}
				</h5>
				<h5 v-else-if="abonnement && abonnement.nbRetry < 3">
					{{ $t("accueilInscrit.enCoursDeRetry") }}
					{{ addDayToDate(abonnement.dateFin, 5) }}
				</h5>
				<h5 v-else>
					{{ $t("accueilInscrit.problemeCRC") }}
					<a
						href="https://formulaire.secure.force.com/TERformulaire?region=FR-PDL&origin=SITE_TER"
					>
						lien
					</a>
				</h5>
			</div>
			<br />
			<!--			Affichage du tableau des perturbations (si elles existent)-->
			<div
				v-if="abonnement.resultat && abonnement.resultat.length > 0"
				class="container"
			>
				<section class="row">
					<div class="col-6 text-center bg-danger">
						<input id="indemnisable" v-model="checkIndem" type="checkbox" />
						<label class="retardEtSuppIndemnisable" for="indemnisable"
							>Retard/Suppression éligible</label
						>
					</div>
					<div class="col-6 text-center bg-warning">
						<input
							id="notIndemnisable"
							v-model="checkNonIndem"
							type="checkbox"
						/>
						<label class="retardEtSuppNonIndemnisable" for="notIndemnisable"
							>Retard non éligible</label
						>
					</div>
				</section>
				<div class="jumbotron h4 pt-1 pb-1">
					Total des retards/suppressions éligibles sur cette période :
					<strong>{{
						getSubObject(abonnement, "eligibilite.nombre_train_eligible")
					}}</strong
					><br />
				</div>
			</div>
			<periode-surveillee
				v-if="abonnement.resultat && abonnement.resultat.length > 0"
				:date="momentDate(abonnement.dateDebut)"
				:event-filter="filterEvents"
				:glissant="abonnementOnTwoMonths"
			></periode-surveillee>
		</div>
		<br /><br /><br />
	</div>
</template>

<script>
import { constants as abonnementConst } from "../../abonnement";
import { constants as regionRulesConst } from "../../regionRules";
import get from "lodash.get";
import moment from "moment";
import _ from "lodash";

export default {
	name: "AccueilInscritDigital",
	data: function () {
		return {
			abonnement: {
				referenceAbonnement: "",
				abonne: {
					firstName: "",
					lastName: "",
					civilite: "",
					iban: {
						value: "",
						bic: "",
					},
				},
				origine: {
					libelle: "",
				},
				destination: {
					libelle: "",
				},
				type: "",
				modeDePaiement: "",
				eligibilite: {
					eligible: "",
					date_calcul: "",
					nombre_train_eligible: "",
				},
				nbRetry: undefined,
				resultat: [],
				selectedTrains: [],
				dateDebut: moment(),
				dateFin: moment(),
				dateInscription: moment(),
				missingData: undefined,
				date: moment(),
			},
			checkIndem: true,
			checkNonIndem: true,
			regionRules: null,
		};
	},
	computed: {
		/**
		 * Détermine si la date du jour est comprise entre la date de calul de l'éligibilité et le nombre de jour déterminé dans règle région
		 * @returns {boolean}
		 */
		onTimeToChooseCompensableMode() {
			const numberDayToChooseCompensableMode = get(
				this,
				"regionRules.indemnisation.timeToChooseCompensableMode"
			);
			const dateOfCalculationEligibility = moment(
				get(this, "abonnement.eligibilite.date_calcul"),
				"YYYY-MM-DD"
			);
			const lastDayToChooseCompensableMode = moment(
				dateOfCalculationEligibility,
				"YYYY-MM-DD"
			).add(numberDayToChooseCompensableMode, "days");
			return moment().isBetween(
				dateOfCalculationEligibility,
				lastDayToChooseCompensableMode
			);
		},
		displayModeRemboursement() {
			return (
				this.isMonthlyOrComptantSubscriber &&
				get(this, "abonnement.eligibilite.eligible")
			);
		},
		isMonthlyOrComptantSubscriber() {
			return this.abonnement.type;
		},
		trips() {
			if (this.abonnement !== undefined) {
				return this.abonnement.selectedTrains;
			}
			return [];
		},
		abonnementEndDatePassed() {
			const today = moment().startOf("day");
			return today.isAfter(moment(this.abonnement.dateFin).add(1, "days"));
		},
		//Retourne true si l'abonnement est glissant
		abonnementOnTwoMonths() {
			const moisDebut = moment(this.abonnement.dateDebut).toDate().getMonth();
			const moisFin = moment(this.abonnement.dateFin).toDate().getMonth();
			return moisDebut !== moisFin;
		},
		filterEvents: function () {
			const events = this.abonnement.resultat;
			return events.filter(
				(event) =>
					(this.checkIndem && event.eligible === true) ||
					(this.checkNonIndem && event.eligible === false)
			);
		},
	},
	mounted() {
		this.$store
			.dispatch(abonnementConst.FETCH_ABONNEMENT)
			.then((abonnements) => {
				const abonnement = abonnements[0];
				this.abonnement.abonne.firstName = abonnement.abonne.identite.prenom;
				this.abonnement.abonne.lastName = abonnement.abonne.identite.nom;
				this.abonnement.referenceAbonnement = abonnement.referenceAbonnement;
				this.abonnement.abonne.civilite = abonnement.abonne.identite.civilite;
				this.abonnement.origine = abonnement.origine.libelle;
				this.abonnement.destination = abonnement.destination.libelle;
				this.abonnement.dateDebut = abonnement.dateDebut;
				this.abonnement.dateFin = abonnement.dateFin;
				this.abonnement.dateInscription = abonnement.dateInscription;
				this.abonnement.missingData = abonnement.missingData;
				this.abonnement.type = abonnement.type;
				this.abonnement.nbRetry = abonnement.nbRetry;
				this.abonnement.modeDePaiement = abonnement.mode_de_paiement;
				this.abonnement.selectedTrains = abonnement.selectedTrains;
				this.abonnement.resultat = abonnement.resultat;
				if (!_.isUndefined(abonnement.eligibilite)) {
					this.abonnement.eligibilite.date_calcul =
						abonnement.eligibilite.date_calcul;
					this.abonnement.eligibilite.eligible =
						abonnement.eligibilite.eligible;
					this.abonnement.eligibilite.nombre_train_eligible =
						abonnement.eligibilite.nombre_train_eligible;
				}
			});

		this.$store
			.dispatch(regionRulesConst.FETCH_REGION_RULES)
			.then((regionRules) => {
				this.regionRules = regionRules;
			});
	},
	methods: {
		formatDate: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
		addDayToDate: function (date, dayToAdd) {
			return moment(date).add(dayToAdd, "days").format("DD/MM/YYYY");
		},
		momentDate: function (date) {
			return moment(date);
		},
		displayDate: function () {
			return (
				this.formatDate(this.abonnement.dateDebut) +
				" au " +
				this.formatDate(this.abonnement.dateFin)
			);
		},
		getSubObject(object, path, defaultValue) {
			return get(object, path, defaultValue);
		},
	},
};
</script>

<style scoped></style>
