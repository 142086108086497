<template>
	<b-card
		header="Votre justificatif : "
		border-variant="info"
		header-bg-variant="info"
		header-text-variant="white"
		class="row"
	>
		<b-input-group>
			<b-form-file
				id="justificatif"
				ref="file-input"
				v-validate="'required|size:5120|mimes:image/jpeg,application/pdf'"
				name="justificatif"
				:value="value"
				:state="validateState('justificatif')"
				accept=".jpg, .pdf, .jpeg"
				placeholder="Choisissez une image ou glissez-la ici*"
				drop-placeholder="Vous pouvez relâcher le fichier."
				data-vv-as="justificatif"
				@input="handleFile"
			></b-form-file>
			<b-button
				v-b-tooltip.hover
				class="ml-2"
				variant="danger"
				title="Supprimer la pièce jointe"
				@click="clearFiles"
				><i class="fa fa-times"></i
			></b-button>
			<b-form-invalid-feedback id="justificatif-live-feedback">
				{{ veeErrors.first("justificatif") }}
			</b-form-invalid-feedback>
		</b-input-group>
		<em>Format .jpg et .pdf autorisés, taille maximale 5 mo.</em>
	</b-card>
</template>

<script>
export default {
	inject: ["$validator"],
	props: {
		value: {
			type: File,
			default: () => {},
		},
		validateState: {
			type: Function,
			default: () => {},
		},
	},
	methods: {
		async handleFile(file) {
			this.$emit("input", file);
		},
		clearFiles() {
			this.$refs["file-input"].reset();
		},
	},
};
</script>
<style>
.custom-file-input ~ .custom-file-label[data-browse]::after {
	content: "Parcourir";
}
</style>
