<template>
	<div class="text-center my-5 col-12">
		<ConnexionMpd v-if="connexionBoolean" :region="region"> </ConnexionMpd>
		<Connexion v-else :region="region"></Connexion>
		<b-form-group>
			<label>
				<em
					v-b-tooltip.hover
					class="fa fa-question-circle fa-2x"
					:title="$t('saisieMensuel.infoChangementFormulaire')"
					data-placement="left"
				></em>
				<strong
					>Pour effectuer votre première connexion, cliquez sur la case :
				</strong>
				<input
					id="changeFormulaire"
					type="checkbox"
					name="changeFormulaire"
					@change="changeFields"
				/>
			</label>
		</b-form-group>
	</div>
</template>

<script>
export default {
	name: "SaisieDigitalLigne",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
		connexion: {
			type: Boolean,
			dafault: false,
		},
	},
	data() {
		return {
			connexionBoolean: this.connexion,
		};
	},
	methods: {
		changeFields() {
			this.connexionBoolean = !this.connexionBoolean;
		},
	},
};
</script>

<style scoped></style>
