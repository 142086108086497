var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
			_vm.typeRemboursement &&
			_vm.typeRemboursement.iban &&
			_vm.typeRemboursement.bonVoyage
		)?_c('div',[_c('b-card',{staticClass:"row",attrs:{"header":"Choisissez un mode de remboursement :","border-variant":"info","header-bg-variant":"info","header-text-variant":"white"}},[_c('b-form-group',[_c('b-form-radio-group',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"radio-group-2","stacked":"","state":_vm.validateState('radio-sub-component'),"name":"radio-sub-component"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('b-form-radio',{attrs:{"value":"BonVoyage"}},[_vm._v("Bon Voyage (envoyé à votre adresse postale)\n\t\t\t\t\t")]),_vm._v(" "),_c('b-form-radio',{attrs:{"value":"iban"}},[_vm._v("Virement bancaire")])],1)],1),_vm._v(" "),(_vm.selected === 'iban')?_c('div',[_c('gg-form-iban',{staticClass:"m-2",attrs:{"iban":_vm.iban,"validate-state":_vm.validateState}})],1):_vm._e()],1)],1):_c('div',[(
				_vm.typeRemboursement && _vm.typeRemboursement.bonVoyage && _vm.formulairePapier
			)?_c('b-card',{staticClass:"row",attrs:{"header":"Mode de dédommagement :","border-variant":"info","header-bg-variant":"info","header-text-variant":"white"}},[_vm._v("\n\t\t\tVous serez indemnisé par "),_c('strong',[_vm._v("Bon d’Achat Numérique")])]):(_vm.typeRemboursement && _vm.typeRemboursement.bonVoyage)?_c('b-card',{staticClass:"row",attrs:{"header":"Mode de remboursement :","border-variant":"info","header-bg-variant":"info","header-text-variant":"white"}},[_vm._v("\n\t\t\tVous serez remboursé par "),_c('strong',[_vm._v("Bon Voyage")])]):(_vm.typeRemboursement && _vm.typeRemboursement.iban)?_c('gg-form-iban',{attrs:{"iban":_vm.iban,"validate-state":_vm.validateState}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }