import Vue from "vue";
import saisieDigitalLigne from "./saisie-digital-ligne.vue";
import connexionMpd from "./connexion-mpd.vue";
import connexion from "./connexion.vue";

Vue.component("SaisieDigitalLigne", saisieDigitalLigne);

Vue.component("ConnexionMpd", connexionMpd);

Vue.component("Connexion", connexion);
