<template>
	<div class="pt-2">
		<div class="mt-2">
			<b-card
				class="d-inline-flex w-100 mb-4"
				title-tag="title"
				footer-tag="footer"
			>
				<template #header>
					<h6 class="mb-0"><b>Suivi de votre demande : </b></h6>
				</template>
				<div v-for="demande in timelineDemandes" :key="demande.idDemande">
					<h6 class="marginLeft-20">
						<u>Demande du {{ demande.date }} : </u>
					</h6>
					<timeline
						class="d-flex justify-content-center"
						:items="demande.value"
					></timeline>
				</div>
				<div slot="footer" class="text-center">
					<b-button @click="redirectToHome()">Retour à l'accueil</b-button>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
import get from "lodash.get";
import has from "lodash.has";
import set from "lodash.set";
import constants from "../../constants";
export default {
	name: "GgSuiviDemande",
	props: {
		/**
		 * Région dans le params
		 */
		region: {
			type: String,
			default: "",
		},
		/**
		 * L'abonné avec ses demandes. L'objet est stringifié pour le hbs
		 */
		abonne: {
			type: String,
			default: "",
		},
	},
	computed: {
		/**
		 * Permet de mettre en forme les demandes pour les afficher avec le composant timeline
		 * @returns [{}]
		 */
		timelineDemandes() {
			const abonne = JSON.parse(this.abonne);
			return abonne.demandes
				.map((demande) => {
					// Récupération de la date du lendemain de la demande
					const nextDay = moment(
						demande.date_demande,
						constants.DATE_FORMAT_SLASH
					)
						.add(1, "d")
						.format("YYYY-MM-DD");
					// Vérification que la date du lendemain de la demande est la même ou supérieur à celle d'aujourd'hui
					const isNextDay = moment(moment().format("YYYY-MM-DD")).isSameOrAfter(
						nextDay
					);
					let timeline;
					if (has(demande, "type_remboursement.iban")) {
						timeline = this.timelineIban(demande.date_demande);
					} else {
						const processingRequest = {
							actif: false,
							last: true,
						};
						if (
							get(demande, "traitementDemande.valideParCRC") === true &&
							get(demande, "traitementDemande.dateEnvoiONICS")
						) {
							set(
								processingRequest,
								"date",
								moment(get(demande, "traitementDemande.dateEnvoiONICS")).format(
									constants.DATE_FORMAT_SLASH
								)
							);
							set(processingRequest, "actif", true);
							set(processingRequest, "message", "Bon voyage émis.");
						}
						if (
							get(demande, "traitementDemande.valideParCRC") === false &&
							get(demande, "traitementDemande.dateOfSendingMail")
						) {
							set(
								processingRequest,
								"date",
								moment(
									get(demande, "traitementDemande.dateOfSendingMail")
								).format(constants.DATE_FORMAT_SLASH)
							);
							set(processingRequest, "actif", true);
							set(processingRequest, "message", "Demande refusée.");
							set(
								processingRequest,
								"secondMessage",
								get(demande, "traitementDemande.motifRefus")
							);
						}
						timeline = this.timelineBonVoyage(
							demande.date_demande,
							nextDay,
							isNextDay,
							processingRequest
						);
					}
					return {
						idDemande: demande.id,
						date: demande.date_demande,
						value: timeline,
					};
				})
				.sort((a, b) =>
					moment(b.date, constants.DATE_FORMAT_SLASH).diff(
						moment(a.date, constants.DATE_FORMAT_SLASH)
					)
				);
		},
	},
	methods: {
		redirectToHome() {
			window.location.href = `/mon-remboursement-ter/${this.region}`;
		},
		timelineIban(date) {
			return [
				{
					date: date,
					message: "Création de la demande.",
					actif: true,
				},
				{
					date: date,
					message: this.$t("garantieGreve.suiviDemande.traitementIban"),
					actif: true,
					last: true,
				},
			];
		},
		timelineBonVoyage(date, nextDay, isNextDay, processingRequest) {
			return [
				{
					date: date,
					message: "Création de la demande.",
					actif: isNextDay,
				},
				{
					date: isNextDay
						? moment(nextDay).format(constants.DATE_FORMAT_SLASH)
						: null,
					message: isNextDay ? "En cours de traitement." : null,
					actif: processingRequest.actif,
				},
				processingRequest,
			];
		},
	},
};
</script>

<style scoped>
.marginLeft-20 {
	margin-left: 20%;
}
</style>
