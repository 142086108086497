<template>
	<!--	eslint-disable vue/no-mutating-props-->
	<div>
		<b-card
			header="Vos coordonnées bancaires :"
			border-variant="info"
			header-bg-variant="info"
			header-text-variant="white"
			class="row"
		>
			<b-form-group label="Iban* : " label-for="iban">
				<b-input-group>
					<b-form-input
						id="iban"
						v-model="iban.value"
						v-validate="{
							required: true,
							regex: /^[A-Z0-9]*$/,
							max: 27,
							iban: true,
						}"
						name="iban"
						:state="validateState('iban')"
						aria-describedby="iban-live-feedback"
						:disabled="isEmpty('value')"
					></b-form-input>
					<b-form-invalid-feedback id="iban-live-feedback">
						{{ veeErrors.first("iban") }}
					</b-form-invalid-feedback>
				</b-input-group>
			</b-form-group>
			<b-form-group label="Bic* : " label-for="bic">
				<b-input-group>
					<b-form-input
						id="bic"
						v-model="iban.bic"
						v-validate="'required|bic'"
						name="bic"
						:state="validateState('bic')"
						aria-describedby="bic-live-feedback"
						:disabled="isEmpty('bic')"
					></b-form-input>
					<b-form-invalid-feedback id="bic-live-feedback">
						{{ veeErrors.first("bic") }}
					</b-form-invalid-feedback>
				</b-input-group>
			</b-form-group>
		</b-card>
	</div>
</template>

<script>
import IBAN from "iban";
import BIC from "bic";
import { Validator } from "vee-validate";
import isEmpty from "lodash.isempty";
import get from "lodash.get";

export default {
	name: "GgformIban",
	inject: ["$validator"],
	props: {
		iban: {
			type: Object,
			default: () => {
				return {
					iban: "",
					bic: "",
				};
			},
		},
		validateState: {
			type: Function,
			default: () => {},
		},
		disabledInput: {
			type: Function,
			default: () => {},
		},
	},
	watch: {
		"iban.value": function (val) {
			// eslint-disable-next-line vue/no-mutating-props
			this.iban.value = val.toUpperCase();
		},
		"iban.bic": function (val) {
			// eslint-disable-next-line vue/no-mutating-props
			this.iban.bic = val.toUpperCase();
		},
	},
	mounted() {
		Validator.extend("iban", this.isIban);
		Validator.extend("bic", this.isBic);
	},
	methods: {
		isIban(iban) {
			return IBAN.isValid(iban);
		},
		isBic(bic) {
			return BIC.isValid(bic);
		},
		isEmpty(path) {
			return !isEmpty(get(this.disabledInput, path));
		},
	},
};
</script>
<style scoped></style>
