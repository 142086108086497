<template>
	<!-- Sélection des jours de la prochaine recherche/sélection -->
	<div class="row mb-3">
		<!-- Sélection unique -->
		<div v-if="mode === 'exception'" class="container jumbotron py-3 mb-2">
			<h3 class="text-center">
				{{ $t("selectionHoraire.selectionException") }}
			</h3>
			<div class="d-flex text-center m-auto">
				<div
					v-for="(day, index) in availableWeekDays"
					:key="index"
					class="m-auto"
				>
					<input
						:id="day"
						v-model="selectedDays[0]"
						type="radio"
						:value="day"
						class="text-center border mt-4"
					/>
					<label :for="day" class="text-capitalize">{{ day }}</label>
				</div>
			</div>
		</div>
		<!-- Sélection multiple -->
		<div v-else-if="mode === 'many'" class="container jumbotron py-3 mb-2">
			<h3 class="text-center">{{ $t("selectionHoraire.selectionJours") }}</h3>
			<p v-if="maxi < 7" class="text-center">
				{{ $t("selectionHoraire.nombreMaxJours") }} {{ maxi }}
			</p>
			<div class="d-flex flex-wrap text-center m-auto">
				<div
					v-for="(day, index) in availableWeekDays"
					:key="index"
					class="m-auto"
				>
					<input
						:id="day"
						v-model="selectedDays"
						type="checkbox"
						:value="day"
						:disabled="disableDay(day)"
						class="text-center border mt-4 mr-2"
					/>
					<label :for="day" class="text-capitalize">{{ day }}</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Définition de la propriété
export default {
	name: "SelectDays",
	props: {
		mode: {
			type: String,
			default: "",
		},
		availableWeekDays: {
			type: Array,
			default: () => {
				return [];
			},
		},
		maxi: {
			type: Number,
			default: null,
		},
		alreadySelectedDays: {
			type: Array,
			default: () => {
				return [];
			},
		},
		reachMaxSelectedDays: {
			type: Boolean,
		},
		exceedMaxSelectedDays: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			selectedDays: [],
		};
	},
	watch: {
		selectedDays: function (newValue) {
			this.$emit("updateselecteddays", newValue);
		},
		/**
		 * La modification de cette variable correspond à une nouvelle sélection de jours
		 * (clic sur "Compléter ma souscription"). Il faut alors réinitialiser le tableau de jours sélectionnés.
		 */
		alreadySelectedDays: function () {
			this.selectedDays = [];
		},
	},
	methods: {
		disableDay: function (day) {
			return (
				this.alreadySelectedDays.includes(day) ||
				(!this.selectedDays.includes(day) &&
					(this.reachMaxSelectedDays || this.exceedMaxSelectedDays))
			);
		},
	},
};
</script>

<style scoped></style>
