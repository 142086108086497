<template>
	<div v-if="abonnement.civilite !== ''" class="jumbotron py-2">
		<div class="d-flex justify-content-between flex-wrap">
			<div>
				<h5><b>Civilité : </b>{{ abonnement.civilite }}</h5>
				<h5><b>Nom : </b>{{ abonnement.lastName }}</h5>
				<h5><b>Prénom : </b>{{ abonnement.firstName }}</h5>
				<h5><b>Numéro client : </b>{{ abonnement.clientNumber }}</h5>
			</div>
			<div>
				<h5>
					<b>Origine - Destination : </b>{{ abonnement.origine }} -
					{{ abonnement.destination }}
				</h5>
				<h5><b>Nom de l'abonnement : </b>{{ abonnement.produit }}</h5>
			</div>
		</div>
	</div>
</template>

<script>
import { constants as abonnementConst } from "../../abonnement";

export default {
	name: "InfoAbonnement",
	data: function () {
		return {
			abonnement: {
				firstName: "",
				lastName: "",
				clientNumber: "",
				civilite: "",
				produit: "",
				origine: {
					libelle: "",
				},
				destination: {
					libelle: "",
				},
				type: "",
			},
			selectedData: {
				data: "",
			},
		};
	},
	mounted() {
		this.$store
			.dispatch(abonnementConst.FETCH_ABONNEMENT)
			.then((abonnements) => {
				const abonnement = abonnements[0];
				this.abonnement.firstName = abonnement.abonne.identite.prenom;
				this.abonnement.lastName = abonnement.abonne.identite.nom;
				this.abonnement.clientNumber = abonnement.referenceAbonnement;
				this.abonnement.civilite = abonnement.abonne.identite.civilite;
				this.abonnement.origine = abonnement.origine.libelle;
				this.abonnement.destination = abonnement.destination.libelle;
				this.abonnement.dateDebutContrat = abonnement.dateDebut;
				this.abonnement.dateFinContrat = abonnement.dateFin;
				this.abonnement.produit = abonnement.produit;
				this.abonnement.type = abonnement.type;
			});
	},
};
</script>

<style scoped></style>
