<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="my-5 pb-5">
		<div class="container text-center pt-4">
			<div v-if="message">
				<b-alert
					v-if="messagetype"
					:variant="messagetype"
					class="text-center"
					show
					v-html="message"
				></b-alert>
				<b-alert
					v-else
					variant="warning"
					class="text-center"
					show
					v-html="message"
				></b-alert>
			</div>
			<button v-if="urlredirection" class="btn btn-info" @click="redirect()">
				Accueil
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "GgError",
	props: {
		message: {
			type: String,
			default: "",
		},
		messagetype: {
			type: String,
			default: "info",
		},
		urlredirection: {
			type: String,
			default: "",
		},
	},
	methods: {
		redirect() {
			window.location.href = this.urlredirection;
		},
	},
};
</script>

<style scoped></style>
