import regionRulesConst from "./regionRules.constants";

export default {
	[regionRulesConst.FETCH_REGION_RULES]({ commit }) {
		return $.get("/api/regionRules")
			.then((regionRules) => {
				commit(regionRulesConst.FETCH_REGION_RULES, regionRules);
				return regionRules;
			})
			.catch((err) => {
				toastr.error(
					"Erreur lors de la récupération de la configuration des régions."
				);
				return Promise.reject(err);
			});
	},
	[regionRulesConst.FETCH_MAX_PRICES]({ commit }, params) {
		return $.get(`/api/regionRules/maxPrice/${params.region}`)
			.then((maxPrices) => {
				commit(regionRulesConst.FETCH_MAX_PRICES, maxPrices);
				return maxPrices;
			})
			.catch((err) => {
				toastr.error(
					"Erreur lors de la récupération des prix maximums."
				);
				return Promise.reject(err);
			});
	},
};
