<template>
	<div class="pt-2">
		<div class="mt-2 d-flex justify-content-center">
			<b-card class="d-inline-flex" title-tag="title" footer-tag="footer">
				<div>
					<b-card-text>{{
						$t("garantieGreve.validationDemande.confirmation")
					}}</b-card-text>
					<span>{{ $t("garantieGreve.validationDemande.courriel") }}</span>
				</div>
				<div slot="footer" class="text-center">
					<b-button @click="redirectToHome()">Retour à l'accueil</b-button>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
export default {
	name: "ValidationDemande",
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	methods: {
		redirectToHome() {
			window.location.href = `/mon-remboursement-ter/${this.region}`;
		},
	},
};
</script>

<style scoped></style>
