import Vue from "vue";

import modalValidation from "./modal-validation/modal-validation.vue";
import displayTrip from "./modal-validation/display-trip/display-trip.vue";
import displayTripLine from "./modal-validation/display-trip-line/display-trip-line.vue";
import tripFormatter from "./modal-validation/trip-formatter/trip-formatter.vue";

Vue.component("ModalValidation", modalValidation);
Vue.component("DisplayTrip", displayTrip);
Vue.component("DisplayTripLine", displayTripLine);
Vue.component("TripFormatter", tripFormatter);
