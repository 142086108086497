<template>
	<div v-if="trips">
		<div class="center">
			<h5>
				<b>{{ title }}</b>
			</h5>
		</div>
		<!-- TODO Renommer needSelect qui permet en fait de passer le composant en mode lecture ou édition -->
		<div v-if="needSelect" class="col-12 center">
			<p v-if="multipleTrainSelection">
				Sélection de vos trains {{ title }} ({{ trainSelectionnableMaximum }}
				maximum) :
			</p>
			<p v-else>Sélection du train {{ title }} :</p>
		</div>
		<div class="table-responsive">
			<table class="table center table-striped">
				<thead>
					<tr v-if="!containsAtLeastOneDisplayableTrip">
						<th class="center">{{ displayNoDisplayableTripMessage }}</th>
					</tr>
					<tr v-else>
						<th scope="col">Origine</th>
						<th scope="col"></th>
						<th scope="col">Destination</th>
						<th scope="col">Train</th>
						<th v-if="displayValidityDays" scope="col">Jours de validité</th>
						<th v-if="displayValidityPeriod" scope="col">Période</th>
						<th v-if="needSelect" scope="col"></th>
					</tr>
				</thead>
				<tbody v-if="containsAtLeastOneDisplayableTrip">
					<!-- eslint-disable vue/no-use-v-if-with-v-for -->
					<template
						v-for="(trip, index) in trips"
						v-if="isDisplayableTrip(trip)"
					>
						<tr :key="trip.id">
							<td>{{ trip.departureTime }}<br />{{ trip.from.label }}</td>
							<td><i class="fa fa-caret-right fa-2x"></i></td>
							<td>{{ trip.arrivalTime }}<br />{{ trip.to.label }}</td>
							<td>
								<div v-if="!trip.correspondance">
									<i class="fa fa-train"></i><br />{{ trip.numberTrain }}
								</div>
								<div
									v-else-if="toggleCorrespondance !== index"
									class="font-italic"
									style="font-size: 80%; color: grey"
								>
									Afficher les correspondances
								</div>
								<div
									v-else
									class="font-italic"
									style="font-size: 80%; color: grey"
								>
									Masquer les correspondances
								</div>
							</td>
							<td v-if="displayValidityDays">
								<span v-if="trip.validity && trip.validity.weekdays">
									<span
										v-for="(weekday, indexWeekday) in trip.validity.weekdays"
										:key="indexWeekday"
										>{{ weekday }}
									</span>
								</span>
								<span v-else>Tous</span>
							</td>
							<td v-if="displayValidityPeriod">
								<span v-if="trip.validity && trip.validity.period">
									Du {{ displayPeriod(trip.validity.period.from) }} <br />
									au {{ displayPeriod(trip.validity.period.to) }}
								</span>
							</td>
							<td
								v-if="needSelect"
								:style="trip.correspondance ? 'cursor:pointer' : 'cursor:auto'"
							>
								<!-- eslint-disable vue/no-mutating-props -->
								<div v-if="trip.correspondance" class="pt-2 d-print-inline">
									<div v-if="selectAllInCorrespondance">
										<div v-if="multipleTrainSelection">
											<!-- eslint-disable vue/no-mutating-props -->
											<b-form-checkbox
												:id="trip.numberTrain"
												type="checkbox"
												:value="trip"
												:disabled="
													disableSelectionRadioCorrespondance(
														trip.departureTime
													)
												"
												@change="
													updateSelectedChoicesWithAllCorrespondanceSegment(
														$event,
														trip.correspondance
													)
												"
												>Sélectionner</b-form-checkbox
											>
										</div>
										<div v-else>
											<input
												:id="trip.numberTrain"
												v-model="choix.data"
												type="radio"
												:value="
													trip.correspondance | selectionableCorrespondances
												"
											/>
											<label :for="trip.numberTrain">Sélectionner</label>
										</div>
									</div>
									<div @click="displayConnectionDetailsIfNeeded(trip, index)">
										<button
											class="noBorderAndBackground"
											style="cursor: pointer"
										>
											<i
												class="fa fa-2x red"
												:class="
													toggleCorrespondance === index
														? 'fa-chevron-up'
														: 'fa-chevron-down'
												"
											>
											</i>
										</button>
									</div>
								</div>
								<div v-else-if="multipleTrainSelection" class="pt-2">
									<!-- eslint-disable vue/no-mutating-props -->
									<b-form-checkbox
										:id="trip.numberTrain"
										type="checkbox"
										:value="trip"
										:disabled="
											disableSelectionRadioCorrespondance(trip.departureTime)
										"
										@change="updateSelectedChoices($event, trip.numberTrain)"
										>Sélectionner</b-form-checkbox
									>
								</div>
								<div v-else class="pt-2 d-print-inline">
									<input
										:id="trip.numberTrain"
										v-model="choix.data"
										type="radio"
										:value="[trip]"
									/>
									<label :for="trip.numberTrain">Sélectionner</label>
								</div>
							</td>
						</tr>
						<tr
							v-for="correspondance in trip.correspondance"
							v-show="toggleCorrespondance === index"
							:key="correspondance.id"
							style="background-color: #e5e5e5"
						>
							<td>
								{{ correspondance.departureTime }}<br />{{
									correspondance.from.label
								}}
							</td>
							<td><i class="fa fa-caret-right fa-2x"></i></td>
							<td>
								{{ correspondance.arrivalTime }}<br />{{
									correspondance.to.label
								}}
							</td>
							<td>
								<i class="fa fa-train"> </i><br />{{
									correspondance.numberTrain
								}}
							</td>
							<td>
								<div v-if="needSelect && correspondance.selectable">
									<div
										v-if="multipleTrainSelection && !selectAllInCorrespondance"
										class="pt-2"
									>
										<!-- eslint-disable vue/no-mutating-props -->
										<b-form-checkbox
											:id="correspondance.numberTrain"
											type="checkbox"
											:value="correspondance"
											:disabled="
												disableSelectionRadio(correspondance.numberTrain)
											"
											@change="
												updateSelectedChoices(
													$event,
													correspondance.numberTrain
												)
											"
											>Sélectionner</b-form-checkbox
										>
									</div>
									<div
										v-else-if="!selectAllInCorrespondance"
										class="pt-2 d-print-inline"
									>
										<input
											:id="correspondance.numberTrain"
											v-model="choix.data"
											type="radio"
											:value="[correspondance]"
										/>
										<label :for="trip.numberTrain">Sélectionner</label>
									</div>
								</div>
								<div v-else>
									<i
										v-b-tooltip
										class="fa fa-info-circle fa-2x mt-2 pl-2"
										:title="$t('selectionHoraire.trainNonSelectionnable')"
										data-placement="top"
									/>
								</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="col-12 center">
			<b-spinner v-if="displaySpinner"> </b-spinner>
			<b-link v-else-if="displayAddMoreButton" @click="addNewTrip()">
				Horaires suivants <em class="fa fa-caret-down fa-x"></em
			></b-link>
		</div>
	</div>
</template>

<script>
import { constants as regionRulesConst } from "../../regionRules";
import tripActions from "../trip.constants";

export default {
	name: "TableauTrajet",
	filters: {
		selectionableCorrespondances: function (value) {
			return value.filter((item) => {
				return item.selectable;
			});
		},
	},
	props: {
		multipleTrainSelection: {
			type: Boolean,
			default: false,
		},
		additionnalTime: {
			type: Number,
			default: 8,
		},
		departure: {
			type: Object,
			default: () => {},
		},
		arrival: {
			type: Object,
			default: () => {},
		},
		title: {
			type: String,
			default: "",
		},
		moment: {
			type: String,
			default: "",
		},
		trips: {
			type: Array,
			default: function () {
				return [];
			},
		},
		message: {
			type: String,
			default: "",
		},
		choix: {
			type: Object,
			default: function () {
				return { data: [] };
			},
		},
		needSelect: {
			type: Boolean,
			default: true,
		},
		weekdays: {
			type: Array,
			default: () => [],
		},
		region: {
			type: String,
			default: "pays-de-la-loire",
		},
		selectAllInCorrespondance: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			toggleCorrespondance: false,
			displayNotTerConnections: true,
			trainSelectionnableMaximum: 1,
			selectedChoices: [],
			numberOfselectedChoices: 0,
			isLoading: false,
		};
	},
	computed: {
		displaySpinner() {
			return this.needSelect && this.isLoading;
		},
		maximumTrainsSelected() {
			return this.numberOfselectedChoices === this.trainSelectionnableMaximum;
		},
		noMoreTrips() {
			return this.$store.getters.getTrip.noMoreTrips[
				this.title === "Aller" ? "departure" : "arrival"
			];
		},
		displayAddMoreButton: function () {
			return !this.noMoreTrips && this.needSelect;
		},
		displayValidityPeriod: function () {
			return this.trips.some((trip) => trip.validity && trip.validity.period);
		},
		displayValidityDays: function () {
			return this.trips.some((trip) => trip.validity && trip.validity.weekdays);
		},
		displayNoDisplayableTripMessage: function () {
			return this.message ? this.message : "Aucun train sélectionnable";
		},
		/**
		 * retourne vrai si au moins un trajet est affichable, faux sinon
		 */
		containsAtLeastOneDisplayableTrip: function () {
			if (this.trips.length === 0) {
				return false;
			}
			const conditionShowOnlyTerConnections = this.trips.some(
				(trip) =>
					!trip.correspondance ||
					trip.correspondance.every(
						(connection) => connection.type === "TRAIN_TER"
					)
			);
			return this.displayNotTerConnections || conditionShowOnlyTerConnections;
		},
	},
	mounted: function () {
		this.$store
			.dispatch(regionRulesConst.FETCH_REGION_RULES)
			.then((regionRules) => {
				this.displayNotTerConnections =
					regionRules.selection.afficherCorrespondancesNonTer;
				if (this.multipleTrainSelection) {
					this.trainSelectionnableMaximum =
						regionRules.selection.trainSelectionnableMaximum;
				}
			});
	},
	methods: {
		disableSelectionRadio(numberTrain) {
			return (
				this.maximumTrainsSelected &&
				!this.selectedChoices.find((item) => {
					return item.numberTrain === numberTrain;
				})
			);
		},
		disableSelectionRadioCorrespondance(departureTime) {
			/*
			 * Dans le cas d'une correspondance, il faut filtrer sur un autre champ car
			 * le numero de train peu etre inexploitable (notemment lors de correspondance avec des segment non ter)
			 * */
			return (
				this.maximumTrainsSelected &&
				!this.selectedChoices.find((item) => {
					return item.departureTime === departureTime;
				})
			);
		},
		updateSelectedChoices(event, numberTrain) {
			if (event === false) {
				this.selectedChoices = this.selectedChoices.filter((item) => {
					return item.numberTrain !== numberTrain;
				});
				this.numberOfselectedChoices = this.numberOfselectedChoices - 1;
			} else {
				// On vérifie que le nombre de trains sélectionné ne dépasse pas la limite
				if (!this.maximumTrainsSelected) {
					this.selectedChoices.push(event);
					this.numberOfselectedChoices = this.numberOfselectedChoices + 1;
				}
			}
			this.$emit("select-trip", this.selectedChoices, this.title);
		},
		updateSelectedChoicesWithAllCorrespondanceSegment(event, correspondances) {
			let numberOfSelectedChoicesIncrement = 0;
			correspondances.forEach((correspondance) => {
				if (correspondance.selectable) {
					if (event === false) {
						this.selectedChoices = this.selectedChoices.filter((item) => {
							return item.numberTrain !== correspondance.numberTrain;
						});
						numberOfSelectedChoicesIncrement = -1;
					} else {
						if (!this.maximumTrainsSelected) {
							this.selectedChoices.push(correspondance);
							numberOfSelectedChoicesIncrement = 1;
						}
					}
				}
			});
			this.numberOfselectedChoices =
				this.numberOfselectedChoices + numberOfSelectedChoicesIncrement;
			this.$emit("select-trip", this.selectedChoices, this.title);
		},
		addNewTrip: function () {
			this.isLoading = true;
			toastr.info(
				"Lancement d'une nouvelle recherche",
				"Recherche d'itinéraires"
			);
			this.$store
				.dispatch(tripActions.PUSH_TRIP, {
					sens: this.title,
					time: this.additionnalTime,
					departure: this.departure,
					arrival: this.arrival,
					weekday: this.weekdays[0],
				})
				.then(() => {
					toastr.remove();
					if (this.noMoreTrips) {
						toastr.warning("Aucun trajet supplémentaire trouvé");
					} else {
						toastr.success("Recherche terminée", "Recherche d'itinéraires");
					}
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		displayConnectionDetailsIfNeeded: function (trip, index) {
			if (trip.correspondance) {
				this.toggleCorrespondance =
					this.toggleCorrespondance !== index ? index : false;
			}
		},
		displayConnection: function (trip) {
			// tous les types de correspondances sont acceptés
			if (this.displayNotTerConnections) {
				return true;
			}
			// toutes les correspondances doivent être des TER
			return trip.correspondance.every(
				(connection) => connection.type === "TRAIN_TER"
			);
		},
		isDisplayableTrip: function (trip) {
			return (
				!trip.correspondance ||
				(trip.correspondance && this.displayConnection(trip))
			);
		},
		displayPeriod: function (period) {
			if (period) {
				return moment(period).format("DD/MM/YYYY");
			}
			return "NC";
		},
	},
};
</script>

<style scoped>
.center {
	text-align: center;
	margin: 0 auto;
}

.noBorderAndBackground {
	border: none;
	background: none;
}

.red {
	color: #cb0044;
}
</style>
