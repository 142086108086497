<template>
	<b-card
		header="Votre identité : "
		border-variant="info"
		header-bg-variant="info"
		header-text-variant="white"
		class="row"
	>
		<b-form-group label="Civilité* : ">
			<b-form-radio-group
				id="civilite"
				v-model="civilite"
				v-validate="'required'"
				name="civilite"
				:options="optionsCivilite"
				:state="validateState('civilite')"
				data-vv-as="civilité"
				:disabled="isEmpty('civilite')"
				@input="setIdentite($event, 'civilite')"
			>
				<b-form-invalid-feedback :state="validateState('civilite')">
					{{ veeErrors.first("civilite") }}
				</b-form-invalid-feedback>
			</b-form-radio-group>
		</b-form-group>

		<b-form-group label="Nom* : " label-for="nom">
			<b-form-input
				id="nom"
				v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$|max:60'"
				name="nom"
				:state="validateState('nom')"
				aria-describedby="nom-live-feedback"
				:disabled="isEmpty('nom')"
				:value="identite.nom"
				@input="setIdentite($event, 'nom')"
			></b-form-input>
			<b-form-invalid-feedback id="nom-live-feedback">
				{{ veeErrors.first("nom") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Prénom* : " label-for="prenom">
			<b-form-input
				id="prenom"
				v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$|max:30'"
				name="prenom"
				:state="validateState('prenom')"
				aria-describedby="prenom-live-feedback"
				data-vv-as="prénom"
				:disabled="isEmpty('prenom')"
				:value="identite.prenom"
				@input="setIdentite($event, 'prenom')"
			></b-form-input>
			<b-form-invalid-feedback id="prenom-live-feedback">
				{{ veeErrors.first("prenom") }}
			</b-form-invalid-feedback>
		</b-form-group>

		<b-form-group label="Date de naissance* : " label-for="dateNaissance">
			<b-form-input
				id="dateNaissance"
				v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
				name="dateNaissance"
				:state="validateState('dateNaissance')"
				aria-describedby="dateNaissance-live-feedback"
				placeholder="JJ/MM/AAAA"
				data-vv-as="date de naissance"
				:disabled="isEmpty('dateNaissance')"
				:value="identite.dateNaissance"
				@input="setIdentite($event, 'dateNaissance')"
			></b-form-input>
			<b-form-invalid-feedback id="dateNaissance-live-feedback">
				{{ veeErrors.first("dateNaissance") }}
			</b-form-invalid-feedback>
		</b-form-group>

		<b-form-group label="Courriel* : " label-for="email">
			<b-input-group prepend="@">
				<b-form-input
					id="email"
					v-validate="'required|email|max:75'"
					name="email"
					:value="adresse_courriel"
					type="text"
					:state="validateState('email')"
					data-vv-as="courriel"
					aria-describedby="email-live-feedback"
					@input="$emit('updateAdresseCourriel', $event)"
				></b-form-input>
				<b-form-invalid-feedback id="email-live-feedback">
					{{ veeErrors.first("email") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>
		<b-form-group
			label="Confirmation du courriel* : "
			label-for="emailConfirmation"
		>
			<b-input-group prepend="@">
				<b-form-input
					id="emailConfirmation"
					v-validate="{
						required: true,
						email: true,
						max: 75,
						confirmed: adresse_courriel,
					}"
					name="emailConfirmation"
					:value="adresseCourrielConfirmation"
					type="text"
					:state="validateState('emailConfirmation')"
					data-vv-as="confirmation du courriel"
					aria-describedby="email-confirmation-live-feedback"
					@input="$emit('updateAdresseCourrielConfirmation', $event)"
				></b-form-input>
				<b-form-invalid-feedback id="email-confirmation-live-feedback">
					{{ veeErrors.first("emailConfirmation") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>

		<b-form-group label="Téléphone (hors préfixe) : " label-for="telephone">
			<b-input-group>
				<b-form-input
					id="telephone"
					v-validate="'max:10|regex:^[0][0-9]{9}$'"
					name="telephone"
					type="text"
					:state="validateState('telephone')"
					aria-describedby="telephone-live-feedback"
					:value="identite.telephone"
					@input="setIdentite($event, 'telephone')"
				></b-form-input>
				<b-form-invalid-feedback id="telephone-live-feedback">
					{{ veeErrors.first("telephone") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>
	</b-card>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

export default {
	inject: ["$validator"],
	props: {
		identite: { type: Object, default: () => {} },
		// eslint-disable-next-line vue/prop-name-casing
		adresse_courriel: { type: String, default: "" },
		adresseCourrielConfirmation: { type: String, default: "" },
		optionsCivilite: { type: Array, default: () => [] },
		disabledInput: { type: Object, default: () => {} },
		validateState: { type: Function, default: () => {} },
	},
	computed: {
		civilite: {
			// getter
			get: function () {
				return this.identite.civilite;
			},
			// setter
			set: function (newValue) {
				this.$emit("update:civilite", newValue);
			},
		},
	},
	methods: {
		isEmpty(path) {
			return !isEmpty(get(this.disabledInput, path));
		},
		setIdentite(value, property) {
			const identite = cloneDeep(this.identite);
			_set(identite, `${property}`, value);
			this.$emit("update-identite", identite);
		},
	},
};
</script>

<style scoped></style>
