import constants from "./abonnement.constants";
import set from "lodash.set";

export default {
	[constants.FETCH_ABONNEMENT](state, result) {
		if (!Array.isArray(result)) {
			result = [result];
		}
		result = result.map((abonnement) => {
			if (abonnement.resultat) {
				const mapTrain = {};
				abonnement.selectedTrains.forEach((train) => {
					set(mapTrain, `${train.numberTrain}`, {
						from: train.from.label,
						to: train.to.label,
						departureTime: train.departureTime,
					});
				});
				abonnement.resultat = abonnement.resultat.map((event) => {
					if (event.eligible) {
						event.customClass = "evenementIndem";
					} else {
						event.customClass = "evenementNonIndem";
					}
					const { from, to, departureTime } =
						mapTrain[event.train_touche];
					return Object.assign({}, event, {
						date: moment(event.date_donnees).format("YYYY/MM/DD"),
						title: event.statut,
						origine: from,
						destination: to,
						horaire_depart: departureTime,
					});
				});
			}
			return abonnement;
		});

		state.abonnements = result;
	},
};
