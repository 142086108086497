<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="mt-5 pt-2">
		<div
			v-if="subscriber.civilite !== ''"
			class="mt-2 d-flex justify-content-center"
		>
			<b-card class="d-inline-flex" title-tag="title" footer-tag="footer">
				<div v-if="modification">
					<b-card-text
						>{{ subscriber.civilite }} {{ subscriber.name }},
						{{
							$t("validation_souscription.modification.confirmation")
						}}</b-card-text
					>
					<em
						><span
							v-html="$t('validation_souscription.modification.courriel')"
						></span
					></em>
				</div>
				<div v-else>
					<h2 slot="title" class="font-weight-bold">
						{{ subscriber.civilite }} {{ subscriber.name }},
					</h2>
					<b-card-text>{{
						$t("validation_souscription.default.confirmation")
					}}</b-card-text>
					<b-card-text v-if="!regionRules.selection.souscriptionAuMois">
						{{ $t("validation_souscription.default.periode") }}
						<b>{{ eligibilityStartDate }}</b
						>.
					</b-card-text>

					<b-card-text v-if="subscriber.region === 'pays-de-la-loire'">
						{{ $t("validation_souscription.default.souscriptionProchainJour") }}
					</b-card-text>
					<em
						><span
							v-html="$t('validation_souscription.default.courriel')"
						></span
					></em>
				</div>
				<div slot="footer" class="text-center">
					<b-button @click="redirectToHome()">Retour à l'Accueil</b-button>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
import { constants as subscriberConst } from "../../subscriber";
import { constants as regionRulesConst } from "../../regionRules";

export default {
	name: "ValidationSouscription",
	props: {
		modification: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			subscriber: {
				name: "",
				civilite: "",
				date: "",
				region: "",
			},
		};
	},
	computed: {
		eligibilityStartDate() {
			if (
				this.regionRules &&
				this.regionRules.selection &&
				this.regionRules.selection.souscriptionEnCoursDeMois
			) {
				return moment().add(1, "day").format("DD-MM-YYYY");
			}
			return moment().add(1, "M").startOf("M").format("DD-MM-YYYY");
		},
		regionRules() {
			return this.$store.state.regionRules;
		},
	},
	mounted() {
		this.$store
			.dispatch(subscriberConst.FETCH_SUBSCRIBER)
			.then((subscribers) => {
				const subscriber = subscribers[0];
				this.subscriber.name = subscriber.nom;
				this.subscriber.civilite = subscriber.civilite;
				this.subscriber.region = subscriber.region;
			});
		this.$store.dispatch(regionRulesConst.FETCH_REGION_RULES);
	},
	methods: {
		redirectToHome() {
			window.location.href = "accueil-inscrit";
		},
	},
};
</script>

<style scoped></style>
