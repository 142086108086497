import { actions as actionTrip } from "../trip";
import { actions as actionSubscription } from "../subscription";
import { actions as actionSubscriber } from "../subscriber";
import { actions as actionRegionRules } from "../regionRules";
import { actions as actionLigneGf } from "../ligne";
import { actions as actionOccasionnelGf } from "../occasionnel";
import { actions as actionGgRegionRules } from "../ggRegionRules";
import { actions as actionsGgAbonne } from "../ggAbonne";
import { actions as actionsAbonnement } from "../abonnement";

/**
 * Importer ici les actions vuex des différentes fonctionnalités
 */
export default Object.assign(
	{},
	actionTrip,
	actionSubscription,
	actionSubscriber,
	actionRegionRules,
	actionLigneGf,
	actionOccasionnelGf,
	actionGgRegionRules,
	actionsGgAbonne,
	actionsAbonnement
);
