import ligneConst from "./ligne.constants";

export default {
	[ligneConst.FETCH_LINES](state, lignes) {
		state.ligne = lignes;
	},
	[ligneConst.FETCH_TAUX_REGULARITE](state, tauxRegularite) {
		state.tauxRegularite = tauxRegularite;
	},
	[ligneConst.CLEAR_TAUX_REGULARITE](state) {
		state.tauxRegularite = {};
	},
};
