<template>
	<div>
		<br /><br />
		<div class="mt-2 right">
			<b-button v-if="glissant" variant="primary" @click="changeMonth"
				>Afficher le calendrier {{ displayFrenchMonth(date) }}</b-button
			>
		</div>
		<vue-event-calendar :events="eventFilter">
			<template slot-scope="props">
				<div
					v-for="(event, index) in props.showEvents"
					:key="index"
					class="event-item"
					:class="event.customClass"
				>
					<table>
						<tr>
							<td><strong>N° TER :</strong> {{ event.train_touche }}</td>
							<td class="decalage">
								<strong>Date :</strong> {{ displayFrenchDate(event.date) }}
							</td>
						</tr>
						<tr class="col-12">
							<td>
								<strong>O/D :</strong> {{ event.origine }} /
								{{ event.destination }}
							</td>
						</tr>
						<tr>
							<td>
								<strong>Horaire Départ :</strong> {{ event.horaire_depart }}
							</td>
							<td class="decalage">
								<strong>Statut :</strong> {{ displayStatus(event.statut) }}
							</td>
						</tr>
						<tr>
							<td v-if="event.ecart === '0'"><strong>Ecart :</strong> -</td>
							<td v-else><strong>Ecart :</strong> {{ event.ecart }} min</td>
							<td class="decalage">
								<strong>Eligible :</strong>
								<span v-if="!event.eligible">Non</span><span v-else>Oui</span>
							</td>
						</tr>
						<tr>
							<td>
								<strong>Motif :</strong>
								{{ event.motif.labelExt || "Non Disponible" }}
							</td>
						</tr>
						<tr v-if="event.prisEnCompte !== undefined">
							<td colspan="2">
								<strong>Prise en compte dans le calcul d'éligibilité :</strong>
								<span v-if="!event.prisEnCompte">Non</span
								><span v-else>Oui</span>
							</td>
						</tr>
					</table>
				</div>
			</template>
		</vue-event-calendar>
	</div>
</template>

<script>
export default {
	name: "PeriodeSurveillee",
	props: {
		date: {
			type: Object,
			default: () => {},
		},
		eventFilter: {
			type: Array,
			default: () => [],
		},
		glissant: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			monthChanged: false,
		};
	},
	computed: {},
	watch: {
		date: function () {
			this.$EventCalendar.toDate(this.date.format("YYYY/MM/DD"));
		},
	},
	created: function () {
		this.$EventCalendar.toDate(this.date.format("YYYY/MM"));
	},
	methods: {
		displayFrenchMonth: function (date) {
			const tab_mois = [
				"de Janvier",
				"de Février",
				"de Mars",
				"d'Avril",
				"de Mai",
				"de Juin",
				"de Juillet",
				"d'Août",
				"de Septembre",
				"d'Octobre",
				"de Novembre",
				"de Décembre",
			];
			let monthToDisplay = new Date(date).getMonth();
			if (!this.monthChanged) {
				monthToDisplay += 1;
			}

			return tab_mois[monthToDisplay];
		},
		changeMonth: function () {
			if (this.monthChanged) {
				this.$EventCalendar.preMonth();
				this.monthChanged = false;
			} else {
				this.$EventCalendar.nextMonth();
				this.monthChanged = true;
			}
		},
		displayFrenchDate: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
		displayStatus: function (status) {
			if (status === "RTP" || status === "RTO") {
				return "Retard";
			} else if (status === "SUP" || status === "SUT") {
				return "Suppression";
			}
			return status;
		},
	},
};
</script>

<style>
.decalage {
	padding-left: 10px;
}

/* l'ordre des classes evenementNonIndem et evenementIndem est important pour
	 que evenementIndem soit pris en compte si un evenement est indemnisable dans une journee */
.evenementNonIndem > span {
	background-color: #ff9800 !important;
	border-color: #ff9800 !important;
	color: white;
}

.evenementIndem > span {
	background-color: #d9534f !important;
	border-color: #d9534f !important;
	color: white;
}

.event-item.evenementNonPrisEnCompte {
	background-color: lightgray !important;
}

p.date-num {
	color: black !important;
}

.retardEtSuppIndemnisable {
	font-size: 18px;
	color: white;
}

.retardEtSuppNonIndemnisable {
	font-size: 18px;
	color: white;
}

.icon {
	display: none;
}

.l,
.r {
	pointer-events: none;
}
</style>
