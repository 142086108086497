<template>
	<div>
		<div v-for="trip in trips" :key="trip.numberTrain">
			<p>
				{{ trip.from.label }} {{ trip.departureTime }}
				<i class="fa fa-arrow-right"></i> {{ trip.to.label }}
				{{ trip.arrivalTime }} TER n°{{ trip.numberTrain }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "DisplayTrip",
	props: {
		trips: {
			type: Array,
			default: () => [],
		},
	},
};
</script>
