<template>
	<!-- eslint-disable vue/no-v-html -->
	<div>
		<div v-if="!tauxExist" class="text-center">
			<tableau-trajet-ligne
				:trips-line="trips"
				:need-select="false"
			></tableau-trajet-ligne>
			<h3 class="my-3">
				<b-badge pill variant="info"
					>{{
						$t("accueilInscrit.ligne.infoSurveillanceCRCNoDoneTauxRegularite")
					}}
				</b-badge>
			</h3>
		</div>
		<b-card v-else :header="headerCardTauxRegularite" class="text-center">
			<b-table
				:items="buildDataForEligibility.bTableItems"
				:fields="fieldsTableRegularite"
			></b-table>
			<b-alert
				v-for="(
					number, month
				) in buildDataForEligibility.numberLinesEligibleByMonth"
				:key="month"
				show
			>
				<strong>{{ number }}</strong
				>{{ $t("accueilInscrit.ligne.tauxRegularite")
				}}<u>{{ getDefaultTaux }}</u> en
				<strong>{{ getMonthInLetter(month) }}.</strong>
			</b-alert>
			<b-alert v-if="!enougthDaysConsumed" show>
				<span>{{
					$t("accueilInscrit.ligne.nonEligiblePasAssezDeJoursConsommes")
				}}</span>
			</b-alert>
			<b-alert v-if="enougthDaysConsumed" show>
				<span>{{
					$t("accueilInscrit.ligne.eligibleNombreDeJoursConsommesSuffisant")
				}}</span>
			</b-alert>
			<b-alert
				v-if="isEligible"
				show
				v-html="$t('accueilInscrit.ligne.eligible')"
			>
			</b-alert>
			<b-alert
				v-if="!isEligible"
				show
				v-html="$t('accueilInscrit.ligne.nonEligible')"
			>
			</b-alert>
		</b-card>
	</div>
</template>

<script>
import _isUndefined from "lodash.isundefined";
export default {
	name: "CardTauxRegularite",
	props: {
		currentMonthSubscription: {
			type: Object,
			default: () => ({}),
		},
		subscriptions: {
			type: Array,
			default: () => [],
		},
		lines: {
			type: Array,
			default: () => [],
		},
		regionRules: {
			type: Object,
			default: function () {
				return {};
			},
		},
		currentDate: {
			type: Object,
			default: () => ({}),
		},
		trips: {
			type: Array,
			default: () => [],
		},
		needSelect: {
			type: Boolean,
		},
		abonnements: {
			type: Array,
			default: () => [],
		},
		moisImport: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			regularite: {},
			enougthDaysConsumed: false,
		};
	},
	computed: {
		getPreviousMonthSubscription() {
			return this.subscriptions.find(
				(item) =>
					item.endDate ===
					this.getIsoStringDate(this.previousMonth, this.previousMonthYear)
			);
		},
		tauxExist() {
			return !_isUndefined(this.currentMonthSubscription.eligibilite);
		},
		getMomentCurrentDate() {
			return moment(this.currentDate);
		},
		previousMonth() {
			const month = this.getMomentCurrentDate.month();
			if (month === 0) {
				return 12;
			} else {
				return month;
			}
		},
		currentMonth() {
			return this.getMomentCurrentDate.month() + 1;
		},
		currentYear() {
			return this.getMomentCurrentDate.year();
		},
		previousMonthYear() {
			return this.currentMonth === 1 ? this.currentYear - 1 : this.currentYear;
		},
		/**
		 * @returns {{bTableItems: {}[], numberLinesEligibleByMonth: {}}}
		 */
		buildDataForEligibility() {
			const defaultTauxRegularite =
				this.regionRules &&
				this.regionRules.ligne_indemnisation &&
				this.regionRules.ligne_indemnisation.taux;
			const result = {
				bTableItems: [], // Objet fourni à b-table
				numberLinesEligibleByMonth: {
					// Nombre de lignes de la souscription éligibles par mois
					[this.currentMonth]: 0,
					[this.previousMonth]: 0,
				},
			};
			// Recuperation des souscriptions
			const currentSubscription = this.currentMonthSubscription;
			const previousSubscription = this.getPreviousMonthSubscription;

			const detailsLines = currentSubscription.eligibilite.details_lignes;
			const joursConsommesSuffisant = this.hasEnoughDaysConsumed(
				currentSubscription,
				previousSubscription
			);

			detailsLines.taux_lignes.forEach((line) => {
				const infosOfTauxToPush = {
					number_line: line.number_line,
					label_line: line.label_line,
					_cellVariants: {}, // Paramètre interprété par le b-table pour rendre la couleur de la cellule rouge si nécessaire
					joursConsommes: joursConsommesSuffisant,
				};

				// Recuperation des informations pour le mois courant
				const monthInLetter = this.getMonthInLetter(this.currentMonth);
				infosOfTauxToPush[monthInLetter] = line.taux_ligne;
				if (line.taux_ligne < defaultTauxRegularite) {
					infosOfTauxToPush._cellVariants[monthInLetter] = "danger";
					result.numberLinesEligibleByMonth[this.currentMonth]++;
				}

				// Recuperation des informations pour le mois precedent
				if (
					!_isUndefined(previousSubscription) &&
					!_isUndefined(previousSubscription.eligibilite)
				) {
					const previousMonthLine =
						previousSubscription.eligibilite.details_lignes.taux_lignes.find(
							(item) => item.number_line === line.number_line
						);
					if (!_isUndefined(previousMonthLine)) {
						const previousMonthInLetter = this.getMonthInLetter(
							this.previousMonth
						);
						infosOfTauxToPush[previousMonthInLetter] =
							previousMonthLine.taux_ligne;
						if (previousMonthLine.taux_ligne < defaultTauxRegularite) {
							infosOfTauxToPush._cellVariants[previousMonthInLetter] = "danger";
							result.numberLinesEligibleByMonth[this.previousMonth]++;
						}
					}
				}
				result.bTableItems.push(infosOfTauxToPush);
			});
			return result;
		},
		// Indique si la souscription en cours est éligible
		isEligible() {
			return this.currentMonthSubscription.eligibilite.eligible;
		},
		getDefaultTaux() {
			return `${this.regionRules.ligne_indemnisation.taux} %`;
		},
		// Permet de créer l'en-tête de la card du composant card taux regularité
		headerCardTauxRegularite() {
			return `${this.getMonthInLetter(this.previousMonth)} ${
				this.previousMonthYear
			} - ${this.getMonthInLetter(this.currentMonth)} ${this.currentYear}`;
		},
		// Crée le header du tableau pour le composant card taux régularité
		fieldsTableRegularite() {
			return [
				{ key: "label_line", label: "Nom de la ligne" },
				this.getMonthInLetter(this.previousMonth),
				this.getMonthInLetter(this.currentMonth),
			];
		},
	},
	methods: {
		// Retourne le mois pour une endDate donnée
		hasEnoughDaysConsumed(subscription, previousSubscription) {
			const hasEnoughDays =
				subscription.eligibilite.details_lignes.nbJourConsommeSuffisant &&
				!_isUndefined(previousSubscription) &&
				!_isUndefined(previousSubscription.eligibilite) &&
				previousSubscription.eligibilite.details_lignes.nbJourConsommeSuffisant;
			this.enougthDaysConsumed = hasEnoughDays;
			return hasEnoughDays;
		},
		/* Permet de récuperer au format iso string la fin d'un mois en fonction du mois et de l'année
		 * afin de faire la convertion ISODate (mongodb) => string
		 *  */
		getIsoStringDate(month, year) {
			const a = `${year}-${month.toString().padStart(2, "0")}`;
			return new Date(moment(a).endOf("M").toJSON()).toISOString();
		},
		// Permet de transfomer le numéro du mois par le nom du mois
		getMonthInLetter(month) {
			return moment(month, "M").format("MMMM");
		},
	},
};
</script>

<style scoped></style>
