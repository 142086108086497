<template>
	<div class="my-5 pb-5">
		<div class="container text-center pt-4">
			<img
				class="img-fluid mx-3 mb-1"
				:src="'/images/' + region + '/logo_TER.png'"
				alt="Image de la région"
			/>
			<img
				class="img-fluid"
				:src="'/images/' + region + '/bandeau.png'"
				alt="Bandeau de la région"
			/>
		</div>
		<br />
		<h3 class="text-center">
			{{ getSubObject(accueilRules, "texte") }}
		</h3>
		<br />
		<div
			v-for="(item, index) in getSubObject(accueilRules, 'texteSupplementaire')"
			:key="index"
		>
			{{ item }}
			<br />
		</div>
		<br />
		<b-tabs content-class="mt-3" fill>
			<b-tab
				v-if="getSubObject(accueilRules, 'showOngletBillettique', false)"
				title="Carte billettique à puce"
			>
				<gg-accueil-billettique :region="region"></gg-accueil-billettique>
			</b-tab>
			<b-tab
				v-if="getSubObject(accueilRules, 'showOngletDigital', false)"
				title="Format digital (site TER / appli SNCF)"
			>
				<gg-accueil-digital :region="region"></gg-accueil-digital>
			</b-tab>
			<b-tab
				v-if="getSubObject(accueilRules, 'showOngletPapier', false)"
				title="Format papier"
			>
				<div class="text-center my-5 col-12">
					<b-button
						show
						variant="outline-info"
						:href="
							'/mon-remboursement-ter/' + region + '/formulaire-indemnisation'
						"
						>Accéder au formulaire
					</b-button>
				</div>
			</b-tab>
			<b-tab
				v-if="getSubObject(accueilRules, 'showOngletOccasionnel', false)"
				title="Clients occasionnels"
			>
				<div class="text-center my-5 col-12">
					<b-button
						show
						variant="outline-info"
						:href="
							'/mon-remboursement-ter/' +
							region +
							'/formulaire-indemnisation-occasionnel'
						"
						>Accéder au formulaire
					</b-button>
				</div>
			</b-tab>
			<b-tab
				v-if="getSubObject(accueilRules, 'showOngletSuiviDemande', false)"
				title="Suivre ma demande"
			>
				<gg-accueil-suivi-demande :region="region"></gg-accueil-suivi-demande>
			</b-tab>
		</b-tabs>
		<div>
			Vérifiez que votre navigateur Internet est à jour avant de faire votre
			demande
			<img src="/images/logo_chrome.png" alt="Logo Chrome" />
			<img src="/images/logo_firefox.png" alt="Logo Firefox" />
		</div>
		<br />
		<h6>
			<em>
				{{ $t("garantieGreve.RGPDText1") }}
				<a :href="$t('garantieGreve.RGPDLink')">{{
					$t("garantieGreve.RGPDLinkAlias")
				}}</a>
				{{ $t("garantieGreve.RGPDText2") }}
			</em>
		</h6>
	</div>
</template>

<script>
import get from "lodash.get";
export default {
	name: "GgAccueil",
	props: {
		authenticated: {
			type: Boolean,
			default: false,
		},
		region: {
			type: String,
			default: "pays-de-la-loire",
		},
		accueilrules: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			accueilRules: {},
		};
	},
	mounted() {
		this.accueilRules = JSON.parse(this.accueilrules);
	},
	methods: {
		getSubObject(object, path, defaultValue) {
			return get(object, path, defaultValue);
		},
	},
};
</script>

<style scoped></style>
