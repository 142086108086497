<template>
	<nav
		class="navbar navbar-toggleable-md navbar-light fixed-top justify-content-between bg-light"
	>
		<a class="navbar-brand">{{ $t("garantieGreve.service") }}</a>
	</nav>
</template>

<script>
export default {
	name: "HeaderPDLL",
	props: {
		authenticated: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped></style>
