<template>
	<div>
		<div
			v-if="
				typeRemboursement &&
				typeRemboursement.iban &&
				typeRemboursement.bonVoyage
			"
		>
			<b-card
				header="Choisissez un mode de remboursement :"
				border-variant="info"
				header-bg-variant="info"
				header-text-variant="white"
				class="row"
			>
				<b-form-group>
					<b-form-radio-group
						id="radio-group-2"
						v-model="selected"
						v-validate="'required'"
						stacked
						:state="validateState('radio-sub-component')"
						name="radio-sub-component"
					>
						<b-form-radio value="BonVoyage"
							>Bon Voyage (envoyé à votre adresse postale)
						</b-form-radio>
						<b-form-radio value="iban">Virement bancaire</b-form-radio>
					</b-form-radio-group>
				</b-form-group>
				<div v-if="selected === 'iban'">
					<gg-form-iban
						class="m-2"
						:iban="iban"
						:validate-state="validateState"
					></gg-form-iban>
				</div>
			</b-card>
		</div>
		<div v-else>
			<b-card
				v-if="
					typeRemboursement && typeRemboursement.bonVoyage && formulairePapier
				"
				header="Mode de dédommagement :"
				border-variant="info"
				header-bg-variant="info"
				header-text-variant="white"
				class="row"
			>
				Vous serez indemnisé par <strong>Bon d’Achat Numérique</strong>
			</b-card>
			<b-card
				v-else-if="typeRemboursement && typeRemboursement.bonVoyage"
				header="Mode de remboursement :"
				border-variant="info"
				header-bg-variant="info"
				header-text-variant="white"
				class="row"
			>
				Vous serez remboursé par <strong>Bon Voyage</strong>
			</b-card>
			<gg-form-iban
				v-else-if="typeRemboursement && typeRemboursement.iban"
				:iban="iban"
				:validate-state="validateState"
			></gg-form-iban>
		</div>
	</div>
</template>

<script>
export default {
	name: "GgChoixIndemnisation",
	inject: ["$validator"],
	props: {
		iban: {
			type: Object,
			default: () => {
				return {
					value: "",
					bic: "",
				};
			},
		},
		formulairePapier: {
			type: Boolean,
			default: false,
		},
		typeRemboursement: {
			type: Object,
			default: () => {},
		},
		validateState: {
			type: Function,
			default: () => {},
		},
	},
	data: function () {
		return {
			selected: "", // Must be an array reference!
		};
	},
	watch: {
		selected: function (val) {
			if (val === "BonVoyage") {
				// eslint-disable-next-line vue/no-mutating-props
				this.iban.value = "";
				// eslint-disable-next-line vue/no-mutating-props
				this.iban.bic = "";
			}
		},
	},
};
</script>

<style scoped></style>
