<template>
	<div class="text-center my-5 col-12">
		<div class="divDepot">
			<h3 class="h3">{{ $t("saisiePapier.titreDepotDemande") }}</h3>
			<b-button
				class="buttonDepot"
				type="submit"
				variant="success"
				@click="redirectionFormulaire()"
				>{{ $t("saisiePapier.boutonFormulaire") }}</b-button
			>
		</div>
		<h2>OU</h2>
		<div class="divPerturbation">
			<h3 class="h3">{{ $t("saisiePapier.titreConsultationPerturbation") }}</h3>
			<br />
			<b-form
				:action="`/${region}/saisie-abonnement`"
				method="post"
				class="form-group"
				@submit="checkForm"
			>
				<b-form-group>
					<label>
						<strong>{{ $t("saisiePapier.inputReferenceAbonnement") }}</strong>
						<em
							v-b-tooltip.hover
							class="fa fa-question-circle fa-2x"
							:title="$t('saisiePapier.infoBulleReferenceAbonnement')"
							data-placement="right"
						></em>
					</label>
					<b-form-input
						id="referenceAbonnement"
						v-model="form.referenceAbonnement"
						v-validate="'required|length:10'"
						type="text"
						class="m-auto col-sm-4"
						name="referenceAbonnement"
						:state="validateState('referenceAbonnement')"
						aria-describedby="referenceAbonnement-live-feedback"
						data-vv-as="numéro de commande"
						placeholder="0123456789"
					></b-form-input>
					<b-button
						v-b-modal.modal-recuperation
						style="margin-top: 1em"
						variant="link"
						>J'ai oublié ma référence</b-button
					>
					<b-form-invalid-feedback id="referenceAbonnement-live-feedback">
						{{ veeErrors.first("referenceAbonnement") }}
					</b-form-invalid-feedback>
				</b-form-group>
				<b-form-group>
					<label>
						<strong>{{ $t("saisiePapier.inputAdresseMail") }}</strong>
						<em
							v-b-tooltip.hover
							class="fa fa-question-circle fa-2x"
							:title="$t('saisiePapier.infoBulleAdresseMail')"
							data-placement="right"
						></em>
					</label>
					<b-form-input
						id="adresseMail"
						v-model.trim="form.adresseMail"
						v-validate="'required|email'"
						type="text"
						class="m-auto col-sm-4"
						name="adresseMail"
						:state="validateState('adresseMail')"
						aria-describedby="adresseMail-live-feedback"
						data-vv-as="adresse mail"
						placeholder="exemple@exemple.fr"
					></b-form-input>
					<b-form-invalid-feedback id="adresseMail-live-feedback">
						{{ veeErrors.first("adresseMail") }}
					</b-form-invalid-feedback>
				</b-form-group>
				<check-box-rgpd
					id="checkboxValidationRgpd-papier"
					:accord-rgpd="accordRgpd"
					:validate-state="validateState"
					@updateCheckRgpd="updateCheckRgpd"
				></check-box-rgpd>
				<b-button type="submit" class="mt-2 mr-2" variant="success"
					>Connectez-vous</b-button
				>
			</b-form>
		</div>
		<b-modal
			id="modal-recuperation"
			ref="modal"
			title="Récupérer votre référence de demande"
			ok-title="Valider"
			cancel-title="Annuler"
			@show="resetModal"
			@hidden="resetModal"
			@ok="confirmRecuperation"
		>
			<form>
				<b-form-group
					label="Votre adresse mail :"
					label-for="mail-input"
					invalid-feedback="L'adresse mail est requise"
				>
					<b-form-input
						id="adresseMailRecuperation"
						v-model.trim="recuperation.adresseMail"
						v-validate="'required|email'"
						:state="validateState('adresseMailRecuperation')"
						name="adresseMailRecuperation"
						placeholder="exemple@exemple.fr"
						type="text"
						aria-describedby="adresseMailRecuperation-live-feedback"
						data-vv-as="adresse mail recuperation"
					></b-form-input>
				</b-form-group>
			</form>
		</b-modal>
	</div>
</template>

<script>
import superagent from "superagent";
export default {
	name: "SaisiePapier",
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				referenceAbonnement: "",
				adresseMail: "",
				token: "",
			},
			recuperation: {
				adresseMail: "",
			},
			accordRgpd: false,
			captachaToken: "",
		};
	},
	watch: {
		"form.nom": function (val) {
			this.form.nom = val.toUpperCase();
		},
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updateCheckRgpd(value) {
			this.accordRgpd = value;
		},
		redirectionFormulaire() {
			window.location.href = `/${this.region}/formulaire-papier`;
		},
		resetModal() {
			this.recuperation.adresseMail = "";
		},
		confirmRecuperation(event) {
			event.preventDefault();
			return this.$validator
				.validate("adresseMailRecuperation", this.recuperation.adresseMail)
				.then(async (result) => {
					if (!result) {
						return false;
					}

					const query = {
						adresse_courriel: this.recuperation.adresseMail,
					};

					try {
						await superagent
							.post(`/api/abonnement/retrieveReferences`)
							.query(query);
						toastr.success(
							"Un mail contenant vos références a été envoyé à l'adresse mail renseignée"
						);
						event.target.click();
					} catch (err) {
						toastr.error(err.response.body.message);
					}
				});
		},
	},
};
</script>

<style scoped>
.h3 {
	margin-bottom: 1rem;
}

.divDepot {
	margin-bottom: 3rem;
}

.divPerturbation {
	margin-top: 3rem;
}

.buttonDepot {
	margin-top: 1rem;
}
</style>
