<template>
	<b-card
		header="Votre adresse : "
		border-variant="info"
		header-bg-variant="info"
		header-text-variant="white"
		class="row"
	>
		<b-form-group label="Numéro* : " label-for="numero">
			<b-form-input
				id="numero"
				v-validate="'required|max:10'"
				name="numero"
				:state="validateState('numero')"
				aria-describedby="numero-live-feedback"
				data-vv-as="numéro"
				:disabled="isEmpty('numero')"
				:value="postale.numero"
				@input="setPostale($event, 'numero')"
			></b-form-input>
			<b-form-invalid-feedback id="numero-live-feedback">
				{{ veeErrors.first("numero") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Voie* : " label-for="voie">
			<b-form-input
				id="voie"
				v-validate="'required|max:80'"
				name="voie"
				:state="validateState('voie')"
				aria-describedby="voie-live-feedback"
				:disabled="isEmpty('voie')"
				:value="postale.voie"
				@input="setPostale($event, 'voie')"
			></b-form-input>
			<b-form-invalid-feedback id="voie-live-feedback">
				{{ veeErrors.first("voie") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Complément d'adresse : " label-for="complementAdresse">
			<b-form-input
				id="complementAdresse"
				v-validate="'max:80'"
				name="complementAdresse"
				:state="validateState('complementAdresse')"
				:disabled="isEmpty('complementAdresse')"
				data-vv-as="complément d'adresse"
				aria-describedby="complement-live-feedback"
				:value="postale.complementAdresse"
				@input="setPostale($event, 'complementAdresse')"
			></b-form-input>
			<b-form-invalid-feedback id="complement-live-feedback">
				{{ veeErrors.first("complementAdresse") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Code Postal* : " label-for="CodePostal">
			<b-form-input
				id="CodePostal"
				v-validate="{ required: true, min: 4, max: 5 }"
				name="CodePostal"
				type="number"
				:state="validateState('CodePostal')"
				aria-describedby="CodePostal-live-feedback"
				data-vv-as="code postal"
				:disabled="isEmpty('codePostal')"
				:value="postale.codePostal"
				@input="setPostale($event, 'codePostal')"
			></b-form-input>
			<b-form-invalid-feedback id="CodePostal-live-feedback">
				{{ veeErrors.first("CodePostal") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Ville* : " label-for="ville">
			<b-form-input
				id="ville"
				v-validate="'required|max:50'"
				name="ville"
				:state="validateState('ville')"
				aria-describedby="ville-live-feedback"
				:disabled="isEmpty('ville')"
				:value="postale.ville"
				@input="setPostale($event, 'ville')"
			></b-form-input>
			<b-form-invalid-feedback id="ville-live-feedback">
				{{ veeErrors.first("ville") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Pays* : " label-for="pays">
			<b-form-input
				id="pays"
				v-validate="'required|max:10'"
				name="pays"
				:state="validateState('pays')"
				aria-describedby="pays-live-feedback"
				:disabled="isEmpty('pays')"
				:value="postale.pays"
				@input="setPostale($event, 'pays')"
			></b-form-input>
			<b-form-invalid-feedback id="pays-live-feedback">
				{{ veeErrors.first("pays") }}
			</b-form-invalid-feedback>
		</b-form-group>
	</b-card>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

export default {
	inject: ["$validator"],
	props: {
		postale: {
			type: Object,
			default: () => {},
		},
		validateState: {
			type: Function,
			default: () => {},
		},
		disabledInput: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		isEmpty(path) {
			return !isEmpty(get(this.disabledInput, path));
		},
		setPostale(value, property) {
			const postale = cloneDeep(this.postale);
			_set(postale, `${property}`, value);
			this.$emit("update-postale", postale);
		},
	},
};
</script>

<style scoped></style>
