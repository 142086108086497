<template>
	<div class="my-5 py-2">
		<b-form
			method="post"
			@submit.stop.prevent="onSubmit"
			@reset.stop.prevent="onReset"
		>
			<!--				Coordonnées de l'utilisateur				-->
			<form-identite
				:options-civilite="optionsCivilite"
				:validate-state="validateState"
				:disabled-input="disabledInput"
				:identite="form.identite"
				@update-identite="updateIdentite"
			></form-identite>
			<br />

			<!-- Abonnement -->
			<gg-form-abonnement
				:options-type-abonnement="optionsTypeAbonnement"
				:abonnement="form.abonnement"
				:validate-state="validateState"
				:disabled-input="disabledInput"
				:max-price="maxPrice"
				:formulaire-papier="true"
				@update-abonnement="updateAbonnement"
			></gg-form-abonnement>
			<br />
			<!--			Adresse de l'utilisateur			-->
			<form-postale
				:validate-state="validateState"
				:postale="form.postale"
				@update-postale="updatePostale"
			></form-postale>
			<br />

			<br />
			<gg-choix-indemnisation
				:formulaire-papier="true"
				:type-remboursement="form.typeRemboursement"
				:iban="form.iban"
				:validate-state="validateState"
			>
			</gg-choix-indemnisation>
			<br />
			<!--				Pièce justificative				-->
			<form-justificatif
				v-model="form.justificatif.image"
				:bloc-name="$t('formulaireIndemnisation.blocs.justificatif.blocName')"
				:validate-state="validateState"
				:modification="modification"
			>
			</form-justificatif>
			<br />
			<div class="d-flex justify-content-md-end">
				<b-button type="reset" class="mt-2 mr-2" variant="danger"
					>Effacer les informations saisies
				</b-button>
				<b-button type="submit" class="mt-2 ml-2" variant="primary"
					>Valider la demande
				</b-button>
			</div>
		</b-form>
		<b-modal
			id="modal-confirmation"
			:title="
				$t('formulaireIndemnisation.titreModalConfirmationFormulairePapier')
			"
			cancel-title="Annuler"
			cancel-variant="danger"
			ok-title="Valider"
			ok-variant="success"
			@ok="sauvegardeFormulaireIndemnisation"
		>
			<p>
				<strong>{{
					$t("formulaireIndemnisation.modalConfirmationFormulairePapier")
				}}</strong>
			</p>
			<p>
				<em class="small"
					>En validant ce formulaire, j’accepte que mes informations soient
					utilisées exclusivement pour le traitement de ma demande</em
				>
			</p>
		</b-modal>
		<b-modal
			id="modal-vider"
			:title="$t('formulaireIndemnisation.titreModalConfirmationViderChamp')"
			cancel-title="Annuler"
			cancel-variant="danger"
			ok-title="Valider"
			ok-variant="success"
			@ok="effacerDonneeInput"
		>
			<p class="my-4">
				{{ $t("formulaireIndemnisation.modalConfirmationViderChamps") }}
			</p>
		</b-modal>
	</div>
</template>

<script>
const ID_MODAL_VIDER = "modal-vider";
import { constants as abonnementConst } from "../../abonnement";
import { constants as regionRulesConst } from "../../regionRules";
import moment from "moment";
import toString from "lodash.tostring";

export default {
	name: "FormulairePapier",
	props: {
		region: {
			type: String,
			default: "",
		},
		modification: {
			type: Boolean,
		},
	},
	data() {
		return {
			form: {
				abonnement: {
					ctcr: "",
					type_abonnement: "",
					nom_abonnement: "",
					periode_abonnement: {
						debut: "",
						fin: "",
					},
					origine: {
						libelle: "",
						uic: null,
					},
					destination: {
						libelle: "",
						uic: null,
					},
					prix_prestation: null,
				},
				identite: {
					civilite: "",
					nom: "",
					prenom: "",
					dateNaissance: "",
					email: "",
				},
				postale: {
					numero: null,
					voie: "",
					complementAdresse: "",
					codePostal: "",
					ville: "",
					pays: "",
				},
				iban: {
					value: "",
					bic: "",
				},
				justificatif: {
					libelle: "",
					image: null,
				},
				typeRemboursement: { bonVoyage: true },
			},
			idAbonnement: "",
			referenceAbonnement: "",
			disabledInput: {},
			optionsCivilite: [
				{ text: "Monsieur", value: "M" },
				{ text: "Madame", value: "Mme" },
			],
			optionsTypeAbonnement: [
				{ text: "Abonnement hebdomadaire", value: "hebdomadaire" },
				{ text: "Abonnement mensuel", value: "mensuel" },
			],
			maxPrices: {},
		};
	},
	computed: {
		maxPrice() {
			const currentType = this.form.abonnement.type_abonnement;
			if (currentType !== "") {
				return this.maxPrices[currentType];
			} else {
				return this.maxPrices.mensuel;
			}
		},
	},
	mounted() {
		this.$store
			.dispatch(regionRulesConst.FETCH_MAX_PRICES, {
				region: this.region,
			})
			.then(() => {
				this.maxPrices = this.$store.getters.getMaxPrice;
			});
		// Utiliser quand un abonné souhaite apporter une modification à ses informations
		if (this.modification) {
			this.$store
				.dispatch(abonnementConst.FETCH_ABONNEMENT)
				.then((abonnements) => {
					const abonnement = abonnements[0];
					// Utiliser dans d'autres composants lors d'une modification
					this.idAbonnement = abonnement.id;
					this.referenceAbonnement = abonnement.referenceAbonnement;
					// Partie identité
					this.form.identite.nom = abonnement.abonne.identite.nom;
					this.form.identite.civilite = abonnement.abonne.identite.civilite;
					this.form.identite.prenom = abonnement.abonne.identite.prenom;
					this.form.identite.dateNaissance =
						abonnement.abonne.identite.dateNaissance.replaceAll("-", "/");
					this.form.identite.email =
						abonnement.abonne.identite.adresse_courriel;
					// Partie abonnement
					this.form.abonnement.type_abonnement = abonnement.type;
					this.form.abonnement.nom_abonnement = abonnement.produit;
					this.form.abonnement.periode_abonnement.debut =
						this.formatDateModification(abonnement.dateDebut);
					this.form.abonnement.periode_abonnement.fin =
						this.formatDateModification(abonnement.dateFin);
					this.form.abonnement.origine.libelle = abonnement.origine.libelle;
					this.form.abonnement.origine.uic = toString(abonnement.origine.uic);
					this.form.abonnement.destination.libelle =
						abonnement.destination.libelle;
					this.form.abonnement.destination.uic = toString(
						abonnement.destination.uic
					);
					this.form.abonnement.prix_prestation = abonnement.prix;
					this.form.abonnement.ctcr = abonnement.ctcr;
					// Partie adresse postale
					this.form.postale.numero =
						abonnement.abonne.identite.adresse_postale.numero_rue;
					this.form.postale.pays =
						abonnement.abonne.identite.adresse_postale.pays;
					this.form.postale.ville =
						abonnement.abonne.identite.adresse_postale.ville;
					this.form.postale.codePostal =
						abonnement.abonne.identite.adresse_postale.code_postal;
					this.form.postale.voie =
						abonnement.abonne.identite.adresse_postale.nom_rue;
				});
		}
	},

	methods: {
		disabledFields(form, disabled = {}) {
			Object.keys(form).forEach((key) => {
				// Une erreur survient lors de la vérification de la key image qui est considérée comme un objet alors que la valeur est normalement nulle (à revoir lors de la refacto)
				if (key !== "image" && typeof form[key] === "object") {
					this.disabledFields(form[key], disabled);
				} else {
					disabled[key] = false;
				}
			});
			this.disabledInput = disabled;
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		onSubmit() {
			this.$validator.validateAll().then(async (result) => {
				if (!result) {
					return;
				}
				this.$bvModal.show("modal-confirmation");
			});
		},
		onReset() {
			this.$bvModal.show(ID_MODAL_VIDER);
		},
		updateIban(iban) {
			this.form.iban = iban;
		},
		updateIdentite(identite) {
			this.form.identite = identite;
		},
		updatePostale(postale) {
			this.form.postale = postale;
		},
		updateAbonnement(abonnement) {
			this.form.abonnement = abonnement;
		},
		async sauvegardeFormulaireIndemnisation() {
			// Les données fournis par le formulaire b-form sont au format string même si le type est normal
			// https://bootstrap-vue.js.org/docs/components/form-input/#range-type-input
			this.form.justificatif.libelle = `${this.form.identite.nom}_${this.form.identite.prenom}`;
			try {
				let demande;
				if (this.modification) {
					demande = {
						form: this.form,
						region: this.region,
						modification: this.modification,
						idAbonnement: this.idAbonnement,
						referenceAbonnement: this.referenceAbonnement,
					};
				} else {
					demande = {
						form: this.form,
						region: this.region,
					};
				}
				await this.$store.dispatch(
					abonnementConst.SAVE_ABONNEMENT_PAPIER,
					demande
				);
			} catch (e) {
				if (e.status === 409) {
					this.$bvModal.show("modal-demande-existante");
				}
			}
		},
		effacerDonneeInput() {
			// Remise à vide du formulaireform: {

			this.form.abonnement.type_abonnement = "";
			this.form.abonnement.ctcr = "";
			this.form.abonnement.nom_abonnement = "";
			this.form.abonnement.periode_abonnement.debut = null;
			this.form.abonnement.periode_abonnement.fin = null;
			this.form.abonnement.origine.libelle = "";
			this.form.abonnement.origine.uic = null;
			this.form.abonnement.prix_prestation = null;
			this.form.abonnement.destination.libelle = "";
			this.form.abonnement.destination.uic = null;
			this.form.postale.codePostal = "";
			this.form.postale.complementAdresse = "";
			this.form.postale.ville = "";
			this.form.postale.pays = "";
			this.form.postale.voie = "";
			this.form.postale.numero = null;
			this.form.identite.civilite = "";
			this.form.identite.prenom = "";
			this.form.identite.nom = "";
			this.form.identite.email = "";
			this.form.identite.dateNaissance = "";
			this.form.postale.codePostal = "";
			this.form.postale.complementAdresse = "";

			this.$bvModal.hide(ID_MODAL_VIDER);
		},
		formatDateModification(date) {
			return moment(date.split("T")[0].replaceAll("-", "/"))
				.add(1, "days")
				.format("DD/MM/YYYY");
		},
	},
};
</script>
