var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5 pt-3"},[_c('div',[(_vm.digital)?_c('info-abonnement'):_c('info-abonne'),_vm._v(" "),_c('div',{staticClass:"col text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("selectionHoraire.titre")))])]),_vm._v(" "),(_vm.interface.subscriber.name !== '')?_c('div',{staticClass:"mt-3"},[_c('p',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.ligne1"))+"\n\t\t\t\t"+_vm._s(_vm.interface.subscriber.civilite)+" "+_vm._s(_vm.interface.subscriber.name)+",\n\t\t\t\t"),_c('br'),_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.ligne2"))+" "+_vm._s(_vm.interface.departure.name)+" <>\n\t\t\t\t"+_vm._s(_vm.interface.arrival.name)+"\n\t\t\t")]),_vm._v(" "),_c('p',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.ligne3"))),_c('br'),_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.ligne4"))+"\n\t\t\t")]),_vm._v(" "),_c('ul',_vm._l((_vm.$t('selectionHoraire.puces', {
						returnObjects: true,
					})),function(puce,name){return _c('li',{key:name,domProps:{"innerHTML":_vm._s(puce)}})}),0),_vm._v(" "),(_vm.interface.regionRules.selection.selectionTrainMultiple)?_c('em',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.rappelTrainMultiple"))+"\n\t\t\t")]):_vm._e(),_vm._v(" "),(!_vm.isEmpty(_vm.$t('selectionHoraire.ligne5')))?_c('p',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.ligne5"))+"\n\t\t\t")]):_vm._e()]):_vm._e(),_vm._v(" "),(this.interface.subscriber.source === 'papier')?_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.redirectionFormulaireModification}},[_vm._v("Modifier mes informations")])],1):_vm._e()],1),_vm._v(" "),_c('hr'),_vm._v(" "),(_vm.selectedTrains.length > 0)?_c('div',{staticClass:"jumbotron py-2"},[_c('trip-recap',{attrs:{"selected-trains":_vm.selectedTrains}})],1):_vm._e(),_vm._v(" "),_c('div',[(!_vm.interface.regionRules.parcoursAbonneParLignes)?_c('h4',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(_vm.$t("selectionHoraire.selection")))])]):_vm._e(),_vm._v(" "),(
				_vm.interface.regionRules.selection.mode === 'exception' && !_vm.exception
			)?_c('h5',{staticClass:"text-center"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("selectionHoraire.exception"))+"\n\t\t")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-center my-3"},[_vm._v("\n\t\t\tVoici votre Origine / Destination :\n\t\t\t"),_c('strong',[_vm._v(_vm._s(_vm.interface.departure.name)+" / "+_vm._s(_vm.interface.arrival.name))])])]),_vm._v(" "),(!_vm.interface.regionRules.parcoursAbonneParLignes)?_c('div',[(_vm.displayWeekDaySelection)?_c('select-days',{attrs:{"mode":_vm.interface.regionRules.selection.mode,"available-week-days":_vm.interface.regionRules.selection.availableWeekDays,"maxi":_vm.interface.regionRules.selection.maxi,"already-selected-days":_vm.alreadySelectedDays,"reach-max-selected-days":_vm.reachMaxSelectedDays,"exceed-max-selected-days":_vm.exceedMaxSelectedDays},on:{"updateselecteddays":_vm.updateSelectedDays}}):_vm._e(),_vm._v(" "),(_vm.displayTripForm)?_c('formulaire-recherche-itineraire',{attrs:{"departure":_vm.interface.departure,"arrival":_vm.interface.arrival,"weekdays":_vm.selectedDays,"mode":_vm.interface.regionRules.selection.mode}}):_vm._e()],1):_vm._e(),_vm._v(" "),(
			(_vm.trajets.Aller.length > 0 || _vm.trajets.messageAller) &&
			(_vm.trajets.Retour.length > 0 || _vm.trajets.messageRetour)
		)?_c('div',[_c('div',{staticClass:"row justify-content-md-center mb-4"},[_c('div',{staticClass:"col-md-6 border"},[_c('tableau-trajet',{attrs:{"title":'Aller',"trips":_vm.trajets.Aller,"message":_vm.trajets.messageAller,"choix":_vm.trajets.selectAller,"departure":_vm.interface.departure,"arrival":_vm.interface.arrival,"additionnal-time":_vm.departureAdditionnalTime,"weekdays":_vm.selectedDays,"need-select":true,"multiple-train-selection":_vm.isMultipleTrainEnabled,"region":_vm.region,"select-all-in-correspondance":_vm.interface.regionRules.selection
							.priseEnCompteDeToutesLesCorrespondancesTer},on:{"select-trip":_vm.handleSelectedTripChange}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-6 border"},[_c('tableau-trajet',{attrs:{"title":'Retour',"trips":_vm.trajets.Retour,"message":_vm.trajets.messageRetour,"choix":_vm.trajets.selectRetour,"departure":_vm.interface.arrival,"arrival":_vm.interface.departure,"additionnal-time":_vm.arrivalAdditionnalTime,"weekdays":_vm.selectedDays,"need-select":true,"region":_vm.region,"multiple-train-selection":_vm.isMultipleTrainEnabled,"select-all-in-correspondance":_vm.interface.regionRules.selection
							.priseEnCompteDeToutesLesCorrespondancesTer},on:{"select-trip":_vm.handleSelectedTripChange}})],1)])]):_vm._e(),_vm._v(" "),(_vm.interface.regionRules.parcoursAbonneParLignes)?_c('div',{staticClass:"text-center"},[(_vm.tripsLine.length === 0)?_c('b-spinner',{attrs:{"type":"grow","label":"load"}}):_c('tableau-trajet-ligne',{attrs:{"trips-line":!Array.isArray(_vm.tripsLine) ? [] : _vm.tripsLine,"need-select":true,"choices":_vm.trajets.selectLigne}})],1):_vm._e(),_vm._v(" "),(_vm.showValidationButton)?_c('div',{staticClass:"mt-4 d-flex justify-content-around flex-wrap"},[(_vm.showAddExceptionButton)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn btn-warning mb-2",attrs:{"type":"button","data-placement":"top","title":_vm.$t('selectionHoraire.ajoutException')},on:{"click":_vm.launchExceptionScenario}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("selectionHoraire.boutonException"))+"\n\t\t")]):_vm._e(),_vm._v(" "),(_vm.showAddManyButton)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn btn-warning mb-2",attrs:{"type":"button","data-placement":"top","title":_vm.$t('selectionHoraire.ajoutJours')},on:{"click":_vm.validateAndResetTripsForMany}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("selectionHoraire.boutonProchaineSelection"))+"\n\t\t")]):_vm._e(),_vm._v(" "),(
				_vm.interface.regionRules.selection.mode === 'exception' && !_vm.exception
			)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"tooltips",attrs:{"title":_vm.$t('selectionHoraire.validerTrains'),"data-placement":"right"}},[_c('button',{staticClass:"research btn btn-success mb-2",attrs:{"type":"button","data-toggle":"modal"},on:{"click":_vm.openModal}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("selectionHoraire.boutonValiderTrains"))+"\n\t\t\t")])]):_c('button',{staticClass:"research btn btn-success mb-2",attrs:{"type":"button","data-toggle":"modal"},on:{"click":_vm.checkDayIsSelected}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("selectionHoraire.boutonValiderTrains"))+"\n\t\t")])]):_vm._e(),_vm._v(" "),(
			_vm.interface.regionRules.parcoursAbonneParLignes &&
			_vm.lineExist(_vm.trajets.selectLigne.data)
		)?_c('div',{staticClass:"mt-4 d-flex justify-content-around flex-wrap"},[(this.interface.subscriber.source === 'MPD')?_c('button',{staticClass:"research btn btn-primary mb-2",attrs:{"type":"button","data-toggle":"modal"},on:{"click":_vm.openModalAbonnement}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("selectionHoraire.boutonAjoutAbonnement"))+"\n\t\t")]):_vm._e(),_vm._v(" "),(this.interface.subscriber.source === 'MPD')?_c('modal-abonnement',{attrs:{"subscriber-name":_vm.interface.subscriber.name}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"research btn btn-success mb-2",attrs:{"type":"button","data-toggle":"modal"},on:{"click":_vm.openModal}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("selectionHoraire.boutonValiderTrains"))+"\n\t\t")])],1):_vm._e(),_vm._v(" "),(_vm.interface && _vm.interface.subscriber)?_c('div',{staticClass:"row d-flex justify-content-center mb-2"},[(_vm.digital)?_c('modal-validation',{attrs:{"departure":_vm.interface.departure,"arrival":_vm.interface.arrival,"selected-mode":_vm.inValidationSelectedMode,"region-rules":_vm.interface.regionRules},on:{"validate":_vm.confirmTripsAbonnement,"undo":_vm.resetTrip}}):_c('modal-validation',{attrs:{"departure":_vm.interface.departure,"arrival":_vm.interface.arrival,"selected-mode":_vm.inValidationSelectedMode,"region-rules":_vm.interface.regionRules},on:{"validate":_vm.confirmTrips,"undo":_vm.resetTrip}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }