<template>
	<div class="my-5 py-2">
		<b-form
			:action="computedRouteAction"
			method="post"
			@submit="onSubmit"
			@reset="onReset"
		>
			<b-card header="Informations supplémentaires: " class="row">
				<b-form-group label="Civilité : ">
					<b-form-radio-group
						id="civilite"
						v-model="form.civilite"
						v-validate="'required'"
						name="civilite"
						:options="optionsCivilite"
						:state="validateState('civilite')"
						aria-describedby="civilite-live-feedback"
						data-vv-as="civilité"
					></b-form-radio-group>
					<b-form-invalid-feedback id="civilite-live-feedback">
						{{ veeErrors.first("civilite") }}
					</b-form-invalid-feedback>
				</b-form-group>
				<b-form-group label="Votre email : " label-for="email">
					<b-form-input
						id="email"
						ref="email"
						v-model="form.email"
						v-validate="'required|email'"
						readonly="readonly"
						name="email"
						type="text"
						:state="validateState('email')"
						aria-describedby="email-live-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="email-live-feedback">
						{{ veeErrors.first("email") }}
					</b-form-invalid-feedback>
				</b-form-group>
				<b-form-group
					label="Confirmation de votre email : "
					label-for="confirmeMail"
				>
					<b-form-input
						id="confirmeMail"
						v-model.trim="form.confirmeMail"
						v-validate="'required|email|confirmed:email'"
						name="confirmeMail"
						type="text"
						:state="validateState('confirmeMail')"
						aria-describedby="confirmeMail-live-feedback"
						data-vv-as="confirmation email"
					></b-form-input>
					<b-form-invalid-feedback id="confirmeMail-live-feedback">
						{{ veeErrors.first("confirmeMail") }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-card>
			<div class="d-flex justify-content-md-end">
				<b-button type="reset" class="m-2" variant="danger"
					>Vider les champs
				</b-button>
				<b-button type="submit" class="m-2" variant="primary"
					>Valider les informations
				</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
export default {
	name: "InformationsPageLigne",
	props: {
		region: {
			type: String,
			default: "",
		},
		inputtedMail: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				civilite: "",
				email: this.inputtedMail,
				confirmeMail: "",
			},
			optionsCivilite: [
				{ text: "Monsieur", value: "M" },
				{ text: "Madame", value: "Mme" },
			],
		};
	},
	computed: {
		computedRouteAction() {
			return `/${this.region}/complement-info-ligne`;
		},
	},
	watch: {
		"form.confirmeMail": function (val) {
			this.form.confirmeMail = val.toLowerCase();
		},
	},
	methods: {
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},

		onSubmit(e) {
			this.$validator.validateAll().then(async (result) => {
				if (!result) {
					e.preventDefault();
				}
				return true;
			});
		},

		onReset(evt) {
			evt.preventDefault();
			// Reset our form values
			this.form.email = this.inputtedMail;
			this.form.civilite = "";
			this.form.confirmeMail = "";
		},
	},
};
</script>

<style scoped></style>
