<template>
	<nav class="navbar navbar-toggleable-md navbar-light fixed-bottom bg-light">
		<a class="navbar-brand mb-0 h1" target="_blank" :href="urlcgu"
			>Aide et conditions d’utilisation</a
		>
		<a
			class="ml-auto"
			target="_blank"
			:href="$t('garantieGreve.pied_de_page.lien_logo_SNCF')"
		>
			<img class="img-responsive img" src="/images/sncf.jpg" />
		</a>
	</nav>
</template>

<script>
export default {
	name: "GgFooter",
	props: {
		urlcgu: {
			type: String,
			default: "",
		},
	},
};
</script>

<style scoped>
.img {
	height: 40px;
}
</style>
