<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="my-5 py-2">
		<h4 class="text-center mt-4">
			<b>{{ $t("formulaireIndemnisation.titre") }}</b>
		</h4>
		<p v-if="!isEmpty($t('formulaireIndemnisation.ligne1'))">
			<b>{{ $t("formulaireIndemnisation.ligne1") }}</b>
		</p>
		<p
			v-if="!isEmpty($t('formulaireIndemnisation.reglesIndemnisation'))"
			v-html="$t('formulaireIndemnisation.reglesIndemnisation')"
		></p>
		<b-form
			v-if="showForm"
			method="post"
			@submit.stop.prevent="onSubmit"
			@reset.stop.prevent="onReset"
		>
			<!-- 			Dossier voyage				-->
			<b-card
				:header="
					source === 'MPD'
						? $t('formulaireIndemnisation.blocs.dossierVoyage.blocNameDigital')
						: $t('formulaireIndemnisation.blocs.dossierVoyage.blocName')
				"
				border-variant="info"
				header-bg-variant="info"
				header-text-variant="white"
				class="row"
			>
				<b-form-group
					:label="
						source === 'MPD'
							? $t(
									'formulaireIndemnisation.blocs.dossierVoyage.dossierVoyageDigital'
							  )
							: $t('formulaireIndemnisation.blocs.dossierVoyage.dossierVoyage')
					"
					label-for="dossierVoyage"
				>
					<b-form-input
						id="dossierVoyage"
						v-model="form.dossierVoyage"
						v-validate="'required|alpha_num'"
						name="dossierVoyage"
						:state="validateState('dossierVoyage')"
						aria-describedby="dossierVoyage-live-feedback"
						data-vv-as="dossier voyage"
						disabled
					></b-form-input>
					<b-form-invalid-feedback id="dossierVoyage-live-feedback">
						{{ veeErrors.first("dossierVoyage") }}
					</b-form-invalid-feedback>
				</b-form-group>
				<span v-if="form.nombreVoyageur">
					Nombre total de personnes concernées par le voyage :
					{{ form.nombreVoyageur }}
				</span>
				<b-alert
					v-if="region === 'normandie'"
					variant="warning"
					v-html="$t('formulaireIndemnisation.messageNombreDeVoyageurMax')"
				></b-alert>
			</b-card>
			<br />

			<!--				Coordonnées de l'utilisateur				-->
			<form-identite
				:validate-state="validateState"
				:disabled-input="disabledInput"
				:identite="form.identite"
				@update-identite="updateIdentite"
			></form-identite>
			<br />

			<!--				information concernant le voyage retardé				-->
			<form-voyage
				:voyage="form.voyage"
				:region-rules="regionRules"
				:validate-state="validateState"
				:disabled-input="disabledInput"
				:tripchoices="tripchoices"
				:seuil-retard-minimum="seuilRetardMinimum"
				:selected-impact.sync="selectedImpact"
				:passagers-selected="passagersSelected"
				:delay-time="delayTime"
				:trains-number="trainsNumber"
				@update-voyage="updateVoyage"
			></form-voyage>
			<br />

			<!--			Adresse de l'utilisateur			-->
			<form-postale
				:validate-state="validateState"
				:postale="form.postale"
				@update-postale="updatePostale"
			></form-postale>
			<br />

			<!--				information iban				-->
			<form-iban-occasionnel
				v-if="
					getSubObject(regionRules, 'occasionnels_indemnisation.refundByIBAN')
				"
				:iban="form.iban"
				:validate-state="validateState"
				@update-iban="updateIban"
			></form-iban-occasionnel>
			<br />
			<!--				Pièce justificative				-->
			<form-justificatif
				v-if="
					(!tripchoices || isEmpty(tripchoices.eligibilite)) &&
					showjustificatifoccasionnel
				"
				v-model="form.justificatif.image"
				:bloc-name="$t('formulaireIndemnisation.blocs.justificatif.blocName')"
				:validate-state="validateState"
			>
			</form-justificatif>
			<div class="d-flex justify-content-md-end">
				<b-button type="reset" class="mt-2 mr-2" variant="danger"
					>Effacer les informations saisies
				</b-button>
				<b-button type="submit" class="mt-2 ml-2" variant="primary"
					>Valider la demande
				</b-button>
			</div>
		</b-form>
		<b-modal
			id="modal-confirmation"
			:title="$t('formulaireIndemnisation.titreModalConfirmationFormulaire')"
			cancel-title="Annuler"
			cancel-variant="danger"
			ok-title="Valider"
			ok-variant="success"
			@ok="sauvegardeFormulaireIndemnisation"
		>
			<p>
				<b>{{ $t("formulaireIndemnisation.modalConfirmationFormulaire") }}</b>
			</p>
			<p>
				<i class="small"
					>En validant ce formulaire, j’accepte que mes informations soient
					utilisées exclusivement pour le traitement de ma demande</i
				>
			</p>
		</b-modal>
		<b-modal
			id="modal-vider"
			:title="$t('formulaireIndemnisation.titreModalConfirmationViderChamp')"
			cancel-title="Annuler"
			cancel-variant="danger"
			ok-title="Valider"
			ok-variant="success"
			@ok="effacerDonneeInput"
		>
			<p class="my-4">
				{{ $t("formulaireIndemnisation.modalConfirmationViderChamps") }}
			</p>
		</b-modal>
		<b-modal
			id="modal-demande-existante"
			:title="$t('formulaireIndemnisation.titreModalDemandeExistante')"
			header-bg-variant="warning"
			ok-only
			@ok="onFocus"
		>
			<div v-if="passagersSelected && passagersSelected.length > 0">
				<p class="my-4">
					{{
						passagersSelected.length === 1
							? "Le passager sélectionné possède "
							: "Un ou plusieurs passagers sélectionnés possèdent"
					}}
					déjà une demande pour le Dossier Voyage
					<b>{{ form.dossierVoyage }}</b> et le N° de train
					<b>{{ form.voyage.numTrain }}</b
					>.
				</p>
			</div>
			<div v-else>
				<p class="my-4">
					{{ $t("formulaireIndemnisation.modalDemandeExistanteLigne1")
					}}<b>{{ form.dossierVoyage }}</b>
					{{ $t("formulaireIndemnisation.modalDemandeExistanteLigne2")
					}}<b>{{ form.voyage.numTrain }}</b>
				</p>
				<p
					v-if="
						!isEmpty(
							$t('formulaireIndemnisation.modalDemandeExistanteRecommandetion')
						)
					"
					class="my-4"
				>
					{{
						$t("formulaireIndemnisation.modalDemandeExistanteRecommandetion")
					}}
				</p>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { constants as occasionnelConst } from "../../occasionnel";
import { constants as regionRulesConst } from "../../regionRules";
import get from "lodash.get";
import set from "lodash.set";
import has from "lodash.has";
import minBy from "lodash.minby";
import isEmpty from "lodash.isempty";
const MODALVIDER = "modal-vider";
export default {
	name: "FormulaireIndemnisation",
	props: {
		region: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		foldertrip: {
			type: String,
			default: "",
		},
		tripchoices: {
			type: Object,
			default: () => {},
		},
		showjustificatifoccasionnel: {
			type: Boolean,
		},
		passagersSelected: {
			type: Array,
			default: () => [],
		},
		source: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				dossierVoyage: "",
				nombreVoyageur: null,
				identite: {
					civilite: "",
					nom: "",
					prenom: "",
					dateNaissance: "",
					email: "",
				},
				postale: {
					numero: null,
					voie: "",
					complementAdresse: "",
					codePostal: "",
					ville: "",
					pays: "",
				},
				voyage: {
					dateVoyage: "",
					numTrain: null,
					origine: {
						label: "",
					},
					destination: {
						label: "",
					},
					retard: "",
					prix: null,
					estSupprime: false,
				},
				iban: {
					value: "",
					bic: "",
				},
				justificatif: {
					libelle: "",
					image: null,
				},
			},
			delayTime: null,
			tempJustificatif: null,
			trainsNumber: [],
			optionsCivilite: [
				{ text: "Monsieur", value: "monsieur" },
				{ text: "Madame", value: "madame" },
			],
			showForm: true,
			today: moment().startOf("day").format("DD/MM/YYYY"),
			regionRules: null,
			disabledInput: {},
			selectedImpact: "",
			optionsImpact: [
				{ text: "un retard", value: "retard" },
				{ text: "une suppression", value: "suppression" },
			],
			seuilRetardMinimum: 0,
		};
	},

	watch: {
		tripchoices: function () {
			this.form = this.tripchoices;
			this.selectedImpact = "";
			this.estSupprime = false;
			if (this.showjustificatifoccasionnel && !has(this.form, "justificatif")) {
				this.form.justificatif = {
					libelle: "",
					image: null,
				};
			}
			this.form.iban = {
				value: "",
				bic: "",
			};
			if (get(this, "passagersSelected", []).length > 0) {
				this.form.voyage.prix = this.calculPrice();
				this.disabledFields(this.form);
			}
			this.disabledFields(this.form);
		},
		passagersSelected: function () {
			if (this.passagersSelected) {
				this.form.voyage.prix = this.calculPrice();
				this.disabledFields(this.form);
			}
		},
		"form.dossierVoyage": function (val) {
			this.form.dossierVoyage = val.toUpperCase();
		},
	},
	mounted() {
		if (this.foldertrip) {
			this.form.dossierVoyage = this.foldertrip;
		}
		if (this.name) {
			this.form.identite.nom = this.name.toUpperCase();
		}
		if (this.tripchoices && Object.keys(this.tripchoices).length > 0) {
			this.form = this.tripchoices;
			if (this.showjustificatifoccasionnel && !has(this.form, "justificatif")) {
				this.form.justificatif = {
					libelle: "",
					image: null,
				};
			}
			this.form.iban = {
				value: "",
				bic: "",
			};
			this.disabledFields(this.tripchoices);
		}
		this.$store
			.dispatch(regionRulesConst.FETCH_REGION_RULES)
			.then((regionRules) => {
				this.regionRules = regionRules;
				this.seuilRetardMinimum = minBy(
					get(regionRules, "occasionnels_indemnisation.paliers"),
					"seuilMinimun"
				).seuilMinimun;
				this.trainsNumber = this.regionRules.liste_num_Train;
				this.delayTime = moment()
					.subtract(
						regionRules.occasionnels_indemnisation.validityDelay,
						"days"
					)
					.format("DD/MM/YYYY");
			});
		if (get(this, "passagersSelected", []).length > 0) {
			this.form.voyage.prix = this.calculPrice();
			this.disabledFields(this.form);
		}
	},
	methods: {
		/**
		 * Récupération des valeurs dans un objet avec la méthode get de lodash
		 * @param {object} object
		 * @param {string} path
		 * @param {*} defaultValue
		 * @returns {*}
		 */
		getSubObject(object, path, defaultValue) {
			return get(object, path, defaultValue);
		},
		isEmpty(value) {
			return isEmpty(value);
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		async sauvegardeFormulaireIndemnisation() {
			// Les données fournis par le formulaire b-form sont au format string même si le type est normal
			// https://bootstrap-vue.js.org/docs/components/form-input/#range-type-input
			this.form.nombreVoyageur = Number(this.form.nombreVoyageur);
			if (this.source === "MPD") {
				delete this.form.nombreVoyageur;
			}
			this.form.region = this.region;

			if (get(this, "form.justificatif.image")) {
				this.form.justificatif.libelle = `${this.form.identite.nom}_${this.form.identite.prenom}`;
			}
			try {
				const demande = { form: this.form };
				if (!this.form.perturbationWasFound) {
					if (this.selectedImpact === "suppression") {
						set(demande, "form.voyage.estSupprime", true);
					} else if (
						!get(this, "regionRules.occasionnels_indemnisation.deletionDetails")
					) {
						delete this.form.voyage.estSupprime;
					}
				}
				if (this.passagersSelected) {
					demande.passagersSelected = this.passagersSelected.map(
						(passager) => passager["@id"]
					);
				}
				await this.$store.dispatch(
					occasionnelConst.SAVE_DEMANDE_INDEMNISATION,
					demande
				);
				window.location.href = `/${this.region}/logout`;
			} catch (e) {
				if (e.status === 409) {
					this.$bvModal.show("modal-demande-existante");
				}
			}
		},
		onSubmit() {
			this.$validator.validateAll().then(async (result) => {
				if (!result) {
					return;
				}
				this.$bvModal.show("modal-confirmation");
			});
		},
		onReset() {
			this.$bvModal.show(MODALVIDER);
		},
		onFocus() {
			if (!this.tripchoices) {
				this.form.voyage.numTrain = "";
			}
		},
		effacerDonneeInput() {
			if (this.tripchoices) {
				// Remise à vide du formulaire
				this.form.identite.civilite = "";
				this.form.identite.email = "";
				this.form.postale.codePostal = "";
				this.form.postale.complementAdresse = "";
				this.form.postale.ville = "";
				this.form.postale.pays = "";
				this.form.postale.voie = "";
				this.form.postale.numero = "";
				this.$bvModal.hide(MODALVIDER);
			} else {
				// Remise à vide du formulaire
				this.form.nombreVoyageur = 1;
				this.form.identite.civilite = "";
				this.form.identite.prenom = "";
				this.form.identite.email = "";
				this.form.identite.dateNaissance = "";
				this.form.postale.codePostal = "";
				this.form.postale.complementAdresse = "";
				this.form.postale.ville = "";
				this.form.postale.pays = "";
				this.form.postale.voie = "";
				this.form.postale.numero = "";
				this.form.voyage.dateVoyage = "";
				this.form.voyage.numTrain = null;
				this.form.voyage.origine.label = "";
				this.form.voyage.destination.label = "";
				this.form.voyage.prix = "";
				this.form.voyage.retard = "";
				this.$bvModal.hide(MODALVIDER);
			}
		},
		clearFiles() {
			this.$refs["file-input"].reset();
		},
		disabledFields(form, disabled = {}) {
			Object.keys(form).forEach((key) => {
				// Une erreur survient lors de la vérification de la key image qui est considérée comme un objet alors que la valeur est normalement nulle (à revoir lors de la refacto)
				if (key !== "image" && typeof form[key] === "object") {
					this.disabledFields(form[key], disabled);
				} else {
					disabled[key] = !!form[key];
				}
			});
			this.disabledInput = disabled;
		},
		calculPrice() {
			return this.passagersSelected
				.reduce(
					(accumulator, currentValue) =>
						accumulator +
						currentValue.pricesByTrain.find(
							(train) => train.numTrain === this.form.voyage.numTrain
						).price,
					0
				)
				.toString();
		},
		updateIban(iban) {
			this.form.iban = iban;
		},
		updateIdentite(identite) {
			this.form.identite = identite;
		},
		updatePostale(postale) {
			this.form.postale = postale;
		},
		updateVoyage(voyage) {
			this.form.voyage = voyage;
		},
	},
};
</script>
<style scoped></style>
