import constants from "./subscriber.constants";

const url = "/api/abonne";

export default {
	[constants.FETCH_SUBSCRIBER]({ commit }) {
		return $.ajax({
			url: url,
		}).then(function (result) {
			if (!Array.isArray(result)) {
				result = [result];
			}
			commit(constants.FETCH_SUBSCRIBER, result);
			return result;
		});
	},
};
