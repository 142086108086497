<template>
	<b-card
		header="Votre abonnement : "
		border-variant="info"
		header-bg-variant="info"
		header-text-variant="white"
		class="row"
	>
		<b-form-group label="Type d’abonnement* : " label-for="typeAbonnement">
			<b-form-radio-group
				id="typeAbonnement"
				v-model="type_abonnement"
				v-validate="'required'"
				name="typeAbonnement"
				:options="optionsTypeAbonnement"
				:state="validateState('typeAbonnement')"
				data-vv-as="type d'abonnement"
				@input="setAbonnement($event, 'type_abonnement')"
			>
				<b-form-invalid-feedback :state="validateState('typeAbonnement')">
					{{ veeErrors.first("typeAbonnement") }}
				</b-form-invalid-feedback>
			</b-form-radio-group>
		</b-form-group>

		<b-form-group label="Nom de l’abonnement* : " label-for="nomAbonnement">
			<b-form-input
				id="nomAbonnement"
				v-validate="'required|max:75'"
				name="nomAbonnement"
				:state="validateState('nomAbonnement')"
				aria-describedby="nomAbonnement-live-feedback"
				:disabled="isEmpty('nom_abonnement')"
				data-vv-as="nom de l'abonnement"
				:value="abonnement.nom_abonnement"
				@input="setAbonnement($event, 'nom_abonnement')"
			></b-form-input>
			<b-form-invalid-feedback id="nomAbonnement-live-feedback">
				{{ veeErrors.first("nomAbonnement") }}
			</b-form-invalid-feedback>
		</b-form-group>

		<b-form-group label="Date de début* : " label-for="debut">
			<b-form-input
				id="debut"
				v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
				name="debut"
				:state="validateState('debut')"
				aria-describedby="debut-live-feedback"
				placeholder="JJ/MM/AAAA"
				data-vv-as="date de début"
				:disabled="isEmpty('periode_abonnement.debut')"
				:value="abonnement.periode_abonnement.debut"
				@input="setAbonnement($event, 'periode_abonnement.debut')"
			></b-form-input>
			<b-form-invalid-feedback id="debut-live-feedback">
				{{ veeErrors.first("debut") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Date de fin* : " label-for="fin">
			<b-form-input
				id="fin"
				v-validate="{
					required: true,
					date_format: 'dd/MM/yyyy',
					endDate: formulairePapier,
				}"
				name="fin"
				:state="validateState('fin')"
				aria-describedby="fin-live-feedback"
				placeholder="JJ/MM/AAAA"
				data-vv-as="date de fin"
				:disabled="isEmpty('periode_abonnement.fin')"
				:value="abonnement.periode_abonnement.fin"
				@input="setAbonnement($event, 'periode_abonnement.fin')"
			></b-form-input>
			<b-form-invalid-feedback id="fin-live-feedback">
				{{ veeErrors.first("fin") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<div>
			<div v-if="formulairePapier">
				<!-- Gare d'origine -->
				<b-form-group label="Gare de départ*" label-for="origineAutocomplete">
					<b-form-input
						id="origineAutocomplete"
						v-validate="'required|max:50'"
						autocomplete="off"
						data-vv-as="gare de départ"
						name="origineAutocomplete"
						:state="validateState('origineAutocomplete')"
						aria-describedby="origine-live-feedback"
						list="list-gare-origine"
						:value="abonnement.origine.libelle"
						@input="onLibelleChange($event, 'origine')"
						@focusout="onFocusOut($event, 'origine')"
					></b-form-input>
					<b-form-invalid-feedback id="origine-live-feedback">
						{{ veeErrors.first("origineAutocomplete") }}
					</b-form-invalid-feedback>
					<b-form-datalist id="list-gare-origine">
						<option
							v-for="(gare, index) in optionsAutocomplete.origine"
							:key="index"
						>
							{{ gare.libelle }}
						</option>
					</b-form-datalist>
				</b-form-group>
				<!-- Gare de destination -->
				<b-form-group
					label="Gare d'arrivée*"
					label-for="destinationAutocomplete"
				>
					<b-form-input
						id="destinationAutocomplete"
						v-validate="'required|max:50'"
						autocomplete="off"
						data-vv-as="gare de départ"
						name="destinationAutocomplete"
						:state="validateState('destinationAutocomplete')"
						aria-describedby="destination-live-feedback"
						list="list-gare-destination"
						:value="abonnement.destination.libelle"
						@input="onLibelleChange($event, 'destination')"
						@focusout="onFocusOut($event, 'destination')"
					></b-form-input>
					<b-form-invalid-feedback id="destination-live-feedback">
						{{ veeErrors.first("destinationAutocomplete") }}
					</b-form-invalid-feedback>
					<b-form-datalist id="list-gare-destination">
						<option
							v-for="(gare, index) in optionsAutocomplete.destination"
							:key="index"
						>
							{{ gare.libelle }}
						</option>
					</b-form-datalist>
				</b-form-group>
				<!-- Champ de récupération du ctcr-->
				<b-form-group>
					<label>
						Code CTCR* :
						<em
							v-b-tooltip
							class="fa fa-question-circle fa-1x"
							:title="$t('formulairePapier.infoBulleCtcr')"
							data-placement="right"
						></em>
					</label>
					<b-form-input
						id="ctcr"
						v-validate="{ required: true, regex: /^[A-Z]{2}\d{2}$/ }"
						name="ctcr"
						:state="validateState('ctcr')"
						aria-describedby="ctcr-live-feedback"
						placeholder="AA11"
						:value="abonnement.ctcr"
						@input="setAbonnement($event, 'ctcr')"
					>
					</b-form-input>
					<b-button v-b-modal="'modal-info-billets'" size="sm" variant="link"
						>Où trouver mon code CTCR?</b-button
					>
					<b-modal
						id="modal-info-billets"
						title="Où trouver mon code CTCR?"
						ok-only
						size="xl"
						scrollable
					>
						<b-container fluid>
							<b-row>
								<b-col>
									<b-img
										center
										:src="'/images/parcours-papier/billet_grand_format.jpg'"
										alt="billet"
									/>
								</b-col>
								<b-col>
									<b-img
										center
										:src="'/images/parcours-papier/billet_petit_format.jpg'"
										alt=""
									/>
								</b-col>
							</b-row>
						</b-container>
					</b-modal>
					<b-form-invalid-feedback id="ctcr-live-feedback">
						{{ veeErrors.first("ctcr") }}
					</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<div v-else>
				<b-form-group label="Gare de départ* : " label-for="gareDepart">
					<b-form-input
						id="gareDepart"
						v-validate="'max:50'"
						name="origine"
						:state="validateState('origine')"
						aria-describedby="origine-live-feedback"
						:disabled="isEmpty('origine.libelle')"
						list="list-gare-depart"
						:value="abonnement.origine.libelle"
						@input="setAbonnement($event, 'origine.libelle')"
					></b-form-input>
					<b-form-invalid-feedback id="origine-live-feedback">
						{{ veeErrors.first("origine") }}
					</b-form-invalid-feedback>
					<b-form-datalist id="list-gare-depart">
						<option v-for="(gare, index) in optionsGareDepart" :key="index">
							{{ gare.libelle }}
						</option>
					</b-form-datalist>
				</b-form-group>
				<b-form-group label="Gare d'arrivée* : " label-for="gareArrivee">
					<b-form-input
						id="gareArrivee"
						v-validate="'max:50'"
						name="destination"
						:state="validateState('destination')"
						aria-describedby="destination-live-feedback"
						:disabled="isEmpty('destination.libelle')"
						list="list-gare-arrivee"
						:value="abonnement.destination.libelle"
						@input="setAbonnement($event, 'destination.libelle')"
					></b-form-input>
					<b-form-invalid-feedback id="destination-live-feedback">
						{{ veeErrors.first("destination") }}
					</b-form-invalid-feedback>
					<b-form-datalist id="list-gare-arrivee">
						<option v-for="(gare, index) in optionsGareArrivee" :key="index">
							{{ gare.libelle }}
						</option>
					</b-form-datalist>
				</b-form-group>
			</div>
		</div>
		<b-form-group label="Prix de l'abonnement* : " label-for="prixAbonnement">
			<b-input-group prepend="€">
				<b-form-input
					id="prixAbonnement"
					v-validate="`required|max_value:${maxPrice}|min_value:0|max:6`"
					type="number"
					step="0.01"
					min="0"
					name="prix"
					:state="validateState('prix')"
					aria-describedby="prix-live-feedback"
					:disabled="isEmpty('prix_prestation')"
					:value="abonnement.prix_prestation"
					@input="setAbonnement($event, 'prix_prestation')"
				></b-form-input>
				<b-form-invalid-feedback id="prix-live-feedback">
					{{ veeErrors.first("prix") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>
	</b-card>
</template>

<script>
import isEmpty from "lodash.isempty";
import { Validator } from "vee-validate";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";
import superagent from "superagent";
import moment from "moment";

export default {
	inject: ["$validator"],
	props: {
		formulairePapier: {
			type: Boolean,
			default: false,
		},
		abonnement: {
			type: Object,
			default: () => {},
		},
		validateState: {
			type: Function,
			default: () => {},
		},
		disabledInput: {
			type: Object,
			default: () => {},
		},
		maxPrice: {
			type: Number,
			default: null,
		},
		optionsTypeAbonnement: {
			type: Array,
			default: () => [
				{ text: "Abonnement hebdomadaire", value: "hebdomadaire" },
				{ text: "Abonnement mensuel", value: "mensuel" },
				{ text: "Abonnement annuel", value: "annuel" },
				{ text: "Autre abonnement", value: "autre" },
			],
		},
	},
	data() {
		return {
			optionsGareDepart: [],
			optionsGareArrivee: [],
			uicGares: {
				origine: null,
				destination: null,
			},
			optionsAutocomplete: {
				origine: [],
				destination: [],
			},
			debounce: null,
		};
	},
	computed: {
		type_abonnement: {
			// getter
			get: function () {
				return this.abonnement.type_abonnement;
			},
			// setter
			set: function (newValue) {
				this.$emit("update:type_abonnement", newValue);
			},
		},
	},
	mounted() {
		Validator.extend("uicDepart", this.isUicDepart);
		Validator.extend("uicArrivee", this.isUicArrivee);
		Validator.extend("endDate", {
			validate: () => {
				const today = moment().startOf("day");
				const endDate = moment(
					this.abonnement.periode_abonnement.fin,
					"DD/MM/YYYY",
					true
				);
				return endDate.isSameOrAfter(today);
			},
			getMessage: () => {
				return "Vous ne pouvez pas accéder au service car votre abonnement n'est plus valide.";
			},
		});
	},
	methods: {
		foundUic(direction, value) {
			const res = this.optionsAutocomplete[direction].find(
				(item) => item.libelle === value
			);
			if (res) {
				return res.uic;
			}
			return undefined;
		},
		// Permet d'effacer l'input si le libelle entré ne correspond pas a un des resultat de l autocompletion et que l utilisateur quitte l'input
		onFocusOut(event, direction) {
			if (!this.foundUic(direction, this.abonnement[direction].libelle)) {
				this.setAbonnement({ libelle: "", uic: null }, direction);
				this.optionsAutocomplete[direction] = [];
			}
		},
		async onLibelleChange(value, direction) {
			// mise en place du debounce
			clearTimeout(this.debounce);
			this.debounce = setTimeout(async () => {
				// Cas ou l utilisateur a selectionné sa gare via l autocompletion (= cas ou on a l uic)
				const foundUic = this.foundUic(direction, value);
				if (foundUic) {
					this.setAbonnement(foundUic, `${direction}.uic`);
				} else {
					// Cas ou l utilisateur n a pas selectionné sa gare via l'autompletion (= il est encore en train d'écrire)
					this.setAbonnement(null, `${direction}.uic`);
					// On ne fait pas d'appel avant d'avoir au moins 3 caracteres
					if (value.length >= 3) {
						try {
							const { body } = await superagent
								.get(`/api/gares/autocompletion`)
								.query({ libelle: value });
							this.optionsAutocomplete[direction] = body;
						} catch (e) {
							toastr.error(
								"Erreur lors de la récupération de la liste des gares, Merci de réessayer plus tard"
							);
						}
					}
				}
			}, 200);
			this.setAbonnement(value, `${direction}.libelle`);
		},
		isUicDepart() {
			return !!this.abonnement.origine.uic;
		},
		isUicArrivee() {
			return !!this.abonnement.destination.uic;
		},
		isEmpty(path) {
			const value = get(this.disabledInput, path);
			if (typeof value === "number") {
				return value >= 0;
			}
			return !isEmpty(value);
		},
		setAbonnement(value, property) {
			const abonnement = cloneDeep(this.abonnement);
			_set(abonnement, `${property}`, value);
			this.$emit("update-abonnement", abonnement);
		},
	},
};
</script>

<style scoped></style>
