<template>
	<div class="my-5 py-5">
		<selection-voyage-occasionnel
			v-if="showSelectionVoyage"
			:trajets="respao.journeys"
			:region="region"
			@voyageselectionne="voyageSelectionne"
		>
		</selection-voyage-occasionnel>

		<div v-if="isMultiPassagers">
			<selection-passager-occasionnel
				v-if="voyageIsSelected"
				:passagers-eligible="passagersEligible"
				:region="region"
				@passagerSelectionne="passagerSelectionne"
			>
			</selection-passager-occasionnel>
			<p v-else>
				Veuillez sélectionner un voyage afin de pouvoir sélectionner les
				passagers.
			</p>
		</div>

		<formulaire-indemnisation
			v-if="voyageIsSelected && (passagersAreSelected || !isMultiPassagers)"
			:region="region"
			:foldertrip="foldertrip"
			:name="name"
			:tripchoices="voyage"
			:showjustificatifoccasionnel="showjustificatifoccasionnel"
			:passagers-selected="passagersSelected"
			:source="voyage.source"
		>
		</formulaire-indemnisation>
	</div>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import has from "lodash.has";
import cloneDeep from "lodash.clonedeep";

export default {
	name: "IndemnisationOccasionnel",
	props: {
		region: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		foldertrip: {
			type: String,
			default: "",
		},
		respao: {
			type: Object,
			default: () => {},
		},
		showjustificatifoccasionnel: {
			type: Boolean,
		},
	},
	data() {
		return {
			voyage: {},
			passagersEligible: [],
			passagersSelected: [],
			voyageIsSelected: false,
			passagersAreSelected: false,
			showSelectionVoyage: false,
			isMultiPassagers: false,
		};
	},
	mounted() {
		if (this.respao.journeys.length > 1) {
			this.showSelectionVoyage = true;
		} else if (this.respao.journeys.length === 1) {
			this.showSelectionVoyage = false;
			this.voyageSelectionne(this.respao.journeys[0]);
		} else {
			this.showSelectionVoyage = false;
		}
		if (!isEmpty(this.respao.passengers)) {
			const passengers = get(this.respao, "passengers");
			if (passengers.length > 1) {
				this.isMultiPassagers = true;
			} else if (passengers.length === 1) {
				this.passagersSelected.push(passengers[0]);
			}
		}
	},
	methods: {
		voyageSelectionne(newValue) {
			if (newValue) {
				this.voyage = newValue;
				this.voyageIsSelected = true;
				if (this.voyage.source === "MPD") {
					return;
				}
				this.passagersEligible = cloneDeep(this.respao.passengers);
				this.passagersEligible = this.passagersEligible
					.map((passager) => {
						if (has(passager, "pricesByTrain")) {
							passager.pricesByTrain = passager.pricesByTrain.filter(
								(onePrice) =>
									onePrice.numTrain === newValue.voyage.numTrain &&
									onePrice.eligible &&
									!onePrice.saved
							);
							return passager;
						}
						return {};
					})
					.filter((passager) => !isEmpty(passager.pricesByTrain));
			} else {
				this.voyage = {};
				this.voyageIsSelected = false;
				this.passagersEligible = [];
			}
		},
		passagerSelectionne(newValue) {
			if (newValue && newValue.length > 0) {
				this.passagersSelected = newValue;
				this.passagersAreSelected = true;
			} else {
				this.passagersSelected = {};
				this.passagersAreSelected = false;
			}
		},
	},
};
</script>

<style scoped></style>
