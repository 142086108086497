export default {
	getSubscription(state) {
		if (!state.subscription) {
			return null;
		}
		return state.subscription;
	},

	getSubscriptions(state) {
		if (state.subscriptions.length === 0) {
			return null;
		}
		return state.subscriptions;
	},
};
