<template>
	<div>
		<p>
			{{ displayConnectionDetailsIfNeeded(trip) }}
		</p>
		<i class="small"
			>{{ $t("modal.confirmationCGU") }}
			<a class="mt-5" target="_blank" :href="$t('modal.lienCGU_PDF')">
				Conditions Générales</a
			></i
		>
	</div>
</template>

<script>
export default {
	name: "DisplayTripLine",
	props: {
		departure: {
			type: Object,
			default: () => {},
		},
		arrival: {
			type: Object,
			default: () => {},
		},
		trip: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		displayConnectionDetailsIfNeeded: function (trip) {
			const via = [];
			let display = "";
			trip.forEach((cor) => {
				if (this.arrival.uic !== parseInt(cor.to.uic)) {
					via.push(cor.to.label);
				}
				if (this.departure.uic !== parseInt(cor.from.uic)) {
					via.push(cor.from.label);
				}
			});
			if (via.length > 0) {
				display = "Via : " + [...new Set(via)].join(" et ");
			} else {
				display = "En direct";
			}
			return display;
		},
	},
};
</script>

<style scoped>
div {
	text-align: center;
	vertical-align: middle;
}
</style>
