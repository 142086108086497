<template>
	<b-form
		:action="`/${region}/saisie-digital-ligne`"
		method="post"
		class="form-group"
		@submit="checkForm"
	>
		<b-form-group>
			<label>
				<strong>Réference (REF)* :</strong>
				<em
					v-b-tooltip.hover
					class="fa fa-question-circle fa-2x"
					:title="$t('saisieMensuel.infoBulleNumeroCommande')"
					data-placement="right"
				></em>
			</label>
			<b-form-input
				id="numeroCommande"
				v-model="form.numeroCommande"
				v-validate="'required|length:8'"
				type="text"
				class="m-auto col-sm-4"
				name="numeroCommande"
				:state="validateState('numeroCommande')"
				aria-describedby="numeroCommande-live-feedback"
				data-vv-as="numéro de commande"
				placeholder="AA123456"
			></b-form-input>
			<b-form-invalid-feedback id="numeroCommande-live-feedback">
				{{ veeErrors.first("numeroCommande") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group>
			<label>
				<strong>Nom* :</strong>
				<em
					v-b-tooltip.hover
					class="fa fa-question-circle fa-2x"
					:title="$t('saisieMensuel.infoBulleNom')"
					data-placement="right"
				></em>
			</label>
			<b-form-input
				id="nom"
				v-model.trim="form.nom"
				v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
				class="m-auto col-sm-4"
				name="nom"
				:state="validateState('nom')"
				aria-describedby="nom-live-feedback"
				placeholder="DUPONT"
			></b-form-input>
			<b-form-invalid-feedback id="nom-live-feedback">
				{{ veeErrors.first("nom") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group>
			<label>
				<strong>Adresse mail* :</strong>
				<em
					v-b-tooltip.hover
					class="fa fa-question-circle fa-2x"
					:title="$t('saisieMensuel.infoBulleAdresseMailPremiereConnexion')"
					data-placement="right"
				></em>
			</label>
			<b-form-input
				id="adresseMail"
				v-model.trim="form.adresseMail"
				v-validate="'required|email'"
				type="text"
				class="m-auto col-sm-4"
				name="adresseMail"
				:state="validateState('adresseMail')"
				aria-describedby="adresseMail-live-feedback"
				data-vv-as="adresse mail"
				placeholder="exemple@exemple.fr"
			></b-form-input>
			<b-form-invalid-feedback id="adresseMail-live-feedback">
				{{ veeErrors.first("adresseMail") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<check-box-rgpd
			id="checkboxValidationRgpd-digital"
			:accord-rgpd="accordRgpd"
			:validate-state="validateState"
			@updateCheckRgpd="updateCheckRgpd"
		></check-box-rgpd>
		<b-button type="submit" class="mt-2 mr-2" variant="success"
			>Connectez-vous</b-button
		>
	</b-form>
</template>

<script>
export default {
	name: "ConnexionMpd",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				numeroCommande: "",
				nom: "",
				adresseMail: "",
				token: "",
			},
			accordRgpd: false,
			captachaToken: "",
		};
	},
	watch: {
		"form.nom": function (val) {
			this.form.nom = val.toUpperCase();
		},
		"form.adresseMail": function (val) {
			this.form.adresseMail = val.toLowerCase();
		},
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updateCheckRgpd(value) {
			this.accordRgpd = value;
		},
	},
};
</script>

<style scoped></style>
