<template>
	<div class="mt-5">
		<div class="container text-center py-4">
			<img
				v-if="region !== 'normandie'"
				class="img-fluid mx-3 mb-1"
				:src="'/images/' + region + '/logo_TER.png'"
				alt="Image de la région"
			/>
			<img
				class="img-fluid"
				:src="'/images/' + region + '/bandeau.png'"
				alt="Bandeau de la région"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "BandeauRegion",
	props: {
		region: {
			type: String,
			default: "",
		},
	},
};
</script>

<style scoped></style>
