import ligneConst from "./ligne.constants";

const msgErr =
	"Une erreur est survenue lors du traitement de votre demande. Merci de réessayer plus tard ou de contacter le CRC";

export default {
	[ligneConst.FETCH_LINES]({ commit }, params) {
		return $.get("/api/ligne", params)
			.then((lignes) => {
				commit(ligneConst.FETCH_LINES, lignes);
				return lignes;
			})
			.catch((err) => {
				toastr.error(msgErr);
				return Promise.reject(err);
			});
	},
	[ligneConst.FETCH_TAUX_REGULARITE]({ commit, state }, payload) {
		const params = {
			region: state.regionRules.region,
			number_line: { $in: payload },
		};
		return $.get("/api/ligne", params)
			.then((lines) => {
				if (!Array.isArray(lines)) {
					lines = [lines];
				}
				commit(ligneConst.FETCH_TAUX_REGULARITE, lines);
				return lines;
			})
			.catch((err) => {
				toastr.error(msgErr);
				return Promise.reject(err);
			});
	},
	[ligneConst.CLEAR_TAUX_REGULARITE]({ commit }) {
		commit(ligneConst.CLEAR_TAUX_REGULARITE, {});
		return {};
	},
};
