<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="mt-5 pt-2">
		<div
			v-if="abonnement.abonne.civilite !== ''"
			class="mt-2 d-flex justify-content-center"
		>
			<b-card class="d-inline-flex" title-tag="title" footer-tag="footer">
				<div>
					<h2 slot="title" class="font-weight-bold">
						{{ abonnement.abonne.civilite }} {{ abonnement.abonne.name }},
					</h2>
					<b-card-text>{{
						$t("validation_abonnement.default.confirmation")
					}}</b-card-text>
					<em
						><span v-html="$t('validation_abonnement.default.courriel')"></span
					></em>
				</div>
				<div slot="footer" class="text-center">
					<b-button @click="redirectToHome()">Retour à l'Accueil</b-button>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
import { constants as regionRulesConst } from "../../regionRules";
import { constants as abonnementConst } from "../../abonnement";

export default {
	name: "ValidationAbonnement",
	props: {
		modification: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			abonnement: {
				abonne: {
					name: "",
					civilite: "",
				},
				date: "",
				region: "",
			},
		};
	},
	computed: {
		regionRules() {
			return this.$store.state.regionRules;
		},
	},
	mounted() {
		this.$store
			.dispatch(abonnementConst.FETCH_ABONNEMENT)
			.then((abonnements) => {
				const abonnement = abonnements[0];
				this.abonnement.abonne.name = abonnement.abonne.identite.nom;
				this.abonnement.abonne.civilite = abonnement.abonne.identite.civilite;
				this.abonnement.region = abonnement.region;
			});
		this.$store.dispatch(regionRulesConst.FETCH_REGION_RULES);
	},
	methods: {
		redirectToHome() {
			window.location.href = "accueil-inscrit-digital";
		},
	},
};
</script>

<style scoped></style>
