<template>
	<b-form
		:action="`/${region}/saisie-digital-ligne`"
		method="post"
		class="form-group"
		@submit="checkForm"
	>
		<b-form-group>
			<label>
				<strong>Numéro Client* :</strong>
				<em
					v-b-tooltip.hover
					class="fa fa-question-circle fa-2x"
					:title="$t('saisieMensuel.infoBulleReferenceClient')"
					data-placement="right"
				></em>
			</label>
			<b-form-input
				id="referenceClient"
				v-model="form.referenceClient"
				v-validate="'required|length:10'"
				type="text"
				class="m-auto col-sm-4"
				name="referenceClient"
				:state="validateState('referenceClient')"
				aria-describedby="referenceClient-live-feedback"
				data-vv-as="référence client"
				placeholder="0123456789"
			></b-form-input>
			<b-form-invalid-feedback id="referenceClient-live-feedback">
				{{ veeErrors.first("referenceClient") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group>
			<label>
				<strong>Adresse mail* :</strong>
				<em
					v-b-tooltip.hover
					class="fa fa-question-circle fa-2x"
					:title="$t('saisieMensuel.infoBulleAdresseMail')"
					data-placement="right"
				></em>
			</label>
			<b-form-input
				id="adresseMail"
				v-model.trim="form.adresseMail"
				v-validate="'required|email'"
				type="text"
				class="m-auto col-sm-4"
				name="adresseMail"
				:state="validateState('adresseMail')"
				aria-describedby="adresseMail-live-feedback"
				data-vv-as="adresse mail"
				placeholder="exemple@exemple.fr"
			></b-form-input>
			<b-form-invalid-feedback id="adresseMail-live-feedback">
				{{ veeErrors.first("adresseMail") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<check-box-rgpd
			id="checkboxValidationRgpd-digital"
			:accord-rgpd="accordRgpd"
			:validate-state="validateState"
			@updateCheckRgpd="updateCheckRgpd"
		></check-box-rgpd>
		<b-button type="submit" class="mt-2 mr-2" variant="success"
			>Connectez-vous</b-button
		>
	</b-form>
</template>

<script>
export default {
	name: "Connexion",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				referenceClient: "",
				adresseMail: "",
				token: "",
			},
			accordRgpd: false,
			captachaToken: "",
		};
	},
	watch: {
		"form.adresseMail": function (val) {
			this.form.adresseMail = val.toLowerCase();
		},
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updateCheckRgpd(value) {
			this.accordRgpd = value;
		},
	},
};
</script>

<style scoped></style>
