<template>
	<b-card
		header="Votre identité : "
		border-variant="info"
		header-bg-variant="info"
		header-text-variant="white"
		class="row"
	>
		<b-form-group label="Civilité* : ">
			<b-form-radio-group
				id="civilite"
				v-model="civilite"
				v-validate="'required'"
				name="civilite"
				:options="optionsCivilite"
				:state="validateState('civilite')"
				aria-describedby="civilite-live-feedback"
				data-vv-as="civilité"
				@input="setIdentite($event, 'civilite')"
			></b-form-radio-group>
			<b-form-invalid-feedback id="civilite-live-feedback">
				{{ veeErrors.first("civilite") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Nom* : " label-for="nom">
			<b-form-input
				id="nom"
				v-validate="'required'"
				name="nom"
				:state="validateState('nom')"
				aria-describedby="nom-live-feedback"
				:disabled="disabledInput.nom"
				:value="identite.nom"
				@input="setIdentite($event, 'nom')"
			></b-form-input>
			<b-form-invalid-feedback id="nom-live-feedback">
				{{ veeErrors.first("nom") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Prénom* : " label-for="prenom">
			<b-form-input
				id="prenom"
				v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
				name="prenom"
				:state="validateState('prenom')"
				aria-describedby="prenom-live-feedback"
				data-vv-as="prénom"
				:disabled="disabledInput.prenom"
				:value="identite.prenom"
				@input="setIdentite($event, 'prenom')"
			></b-form-input>
			<b-form-invalid-feedback id="prenom-live-feedback">
				{{ veeErrors.first("prenom") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group label="Date de naissance* : " label-for="dateNaissance">
			<b-form-input
				id="dateNaissance"
				v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
				name="dateNaissance"
				type="text"
				placeholder="JJ/MM/AAAA"
				:state="validateState('dateNaissance')"
				aria-describedby="dateNaissance-live-feedback"
				data-vv-as="date de naissance"
				:disabled="disabledInput.dateNaissance"
				:value="identite.dateNaissance"
				@input="setIdentite($event, 'dateNaissance')"
			></b-form-input>
			<b-form-invalid-feedback id="dateNaissance-live-feedback">
				{{ veeErrors.first("dateNaissance") }}
			</b-form-invalid-feedback>
		</b-form-group>

		<b-form-group label="Courriel* : " label-for="email">
			<b-input-group>
				<span class="input-group-text"><i class="fa fa-at fa-lg"></i></span>
				<b-form-input
					id="email"
					v-validate="'required|email'"
					name="email"
					type="text"
					:state="validateState('email')"
					aria-describedby="email-live-feedback"
					:value="identite.email"
					@input="setIdentite($event, 'email')"
				></b-form-input>
				<b-form-invalid-feedback id="email-live-feedback">
					{{ veeErrors.first("email") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>
	</b-card>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

export default {
	name: "FormIdentite",
	inject: ["$validator"],
	props: {
		/**
		 * Contient les données de l'identité de l'utilisateur
		 * @model
		 */
		identite: {
			type: Object,
			default: () => {
				return {
					civilite: "",
					nom: "",
					prenom: "",
					dateNaissance: "",
					email: "",
				};
			},
		},
		/**
		 * Permet de faire un controle sur les inputs
		 */
		validateState: {
			type: Function,
			default: () => {},
		},
		disabledInput: {
			type: Object,
			default: () => {},
		},
		optionsCivilite: {
			type: Array,
			default: () => [
				{ text: "Monsieur", value: "monsieur" },
				{ text: "Madame", value: "madame" },
			],
		},
	},
	computed: {
		civilite: {
			// getter
			get: function () {
				return this.identite.civilite;
			},
			// setter
			set: function (newValue) {
				this.$emit("update:civilite", newValue);
			},
		},
	},
	methods: {
		isEmpty(path) {
			return !isEmpty(get(this.disabledInput, path));
		},
		setIdentite(value, property) {
			const identite = cloneDeep(this.identite);
			_set(identite, `${property}`, value);
			this.$emit("update-identite", identite);
		},
	},
};
</script>

<style scoped></style>
