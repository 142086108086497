import subscriptionConst from "./subscription.constants";

import set from "lodash.set";
import _isUndefined from "lodash.isundefined";

export default {
	[subscriptionConst.FETCH_SUBSCRIPTIONS](state, subscriptions) {
		if (!Array.isArray(subscriptions)) {
			subscriptions = [subscriptions];
		}
		subscriptions = subscriptions.map((subscription) => {
			if (subscription.resultat) {
				const mapTrain = {};
				subscription.selectedTrains.forEach((train) => {
					set(mapTrain, `${train.numberTrain}`, {
						from: train.from.label,
						to: train.to.label,
						departureTime: train.departureTime,
					});
				});
				subscription.resultat = subscription.resultat.map((event) => {
					if (event.eligible) {
						event.customClass = "evenementIndem";
					} else {
						event.customClass = "evenementNonIndem";
					}
					if (
						!_isUndefined(event.prisEnCompte) &&
						!event.prisEnCompte
					) {
						event.customClass = "evenementNonPrisEnCompte";
					}
					const { from, to, departureTime } =
						mapTrain[event.train_touche];
					return Object.assign({}, event, {
						date: moment(event.date_donnees).format("YYYY/MM/DD"),
						title: event.statut,
						origine: from,
						destination: to,
						horaire_depart: departureTime,
					});
				});
			}
			return subscription;
		});
		state.subscriptions = subscriptions;
	},

	[subscriptionConst.SAVE_SUBSCRIPTION](state, result) {
		state.response = {
			status: result.status,
			message: result.message,
			data: result.data,
		};
		return state.response;
	},
};
