<template>
	<div class="my-5 pb-5">
		<div class="container text-center pt-4">
			<img
				v-if="region !== 'normandie'"
				class="img-fluid mx-3 mb-1"
				:src="'/images/' + region + '/logo_TER.png'"
				alt="Image de la région"
			/>
			<img
				class="img-fluid"
				:src="$t('commun.lienBandeau')"
				alt="Bandeau de la région"
			/>
		</div>
		<div v-if="region === 'hauts-de-france'"></div>
		<div v-else class="container text-center mt-4 mb-3 title">
			<h2>
				{{ $t("commun.cherAbonne")
				}}<img
					class="img-fluid mt-4"
					:src="'/images/' + region + '/logo_forfait.png'"
					alt=""
				/>
			</h2>
		</div>
		<br />
		<div v-if="region === 'pays-de-la-loire'">
			<h2 class="text-center">
				{{ $t("commun.titre") }}
			</h2>
		</div>
		<div v-else>
			<h3 class="text-center">
				{{ $t("commun.titre") }}
			</h3>
		</div>
		<ul v-if="region === 'centre'">
			<li style="font-size: 1rem; font-weight: bold">
				{{ $t("commun.sousTitre") }}
			</li>
			<li style="font-size: 1rem; font-weight: bold">
				{{ $t("commun.sousTitre2") }}
			</li>
		</ul>
		<div v-else-if="region === 'hauts-de-france'">
			<p>
				<strong>{{ $t("commun.sousTitre") }}</strong>
			</p>
			<p>{{ $t("commun.sousTitre2") }}</p>
			<ul>
				<li>
					{{ $t("commun.sousTitre3")
					}}<strong
						><a
							style="text-decoration: underline"
							:href="$t('commun.lienSousTitre3')"
							>ce formulaire</a
						></strong
					>
					{{ $t("commun.sousTitre3,5") }}
				</li>
				<li>{{ $t("commun.sousTitre4") }}</li>
			</ul>
			<p>
				<strong>{{ $t("commun.sousTitre5") }} </strong>
				{{ $t("commun.sousTitre6") }}
			</p>
		</div>
		<h4 v-else class="text-center">
			{{ $t("commun.sousTitre") }}
		</h4>
		<br />
		<b-tabs
			v-if="saisieabosuraccueil"
			:value="findTabIndex"
			content-class="mt-3"
			fill
		>
			<b-tab v-if="parcoursannuel" :title="$t('saisieAbonne.titleTab')">
				<saisie-abonne :region="region"></saisie-abonne>
			</b-tab>
			<b-tab v-if="parcoursdigital" :title="$t('saisieMensuel.titleTab')">
				<saisie-digital
					v-if="!parcoursabonneparlignes"
					:region="region"
				></saisie-digital>
				<saisie-digital-ligne v-else :region="region"></saisie-digital-ligne>
			</b-tab>
			<b-tab v-if="parcourspapier" :title="$t('saisiePapier.titleTab')">
				<saisie-papier :region="region"></saisie-papier>
			</b-tab>
			<b-tab
				v-if="parcoursoccasionnel"
				:title="$t('saisieOccasionnel.titleTab')"
			>
				<saisie-occasionnel :region="region"></saisie-occasionnel>
			</b-tab>
			<b-tab
				v-if="parcoursoccasionnelmpd"
				:title="$t('saisieOccasionnelMPD.titleTab')"
			>
				<saisie-occasionnel-mpd :region="region"></saisie-occasionnel-mpd>
			</b-tab>
		</b-tabs>
		<div
			v-else
			class="mt-5 d-flex justify-content-center mb-5"
			role="group"
			aria-label="group 2"
		>
			<b-button class="btn btn-secondary mx-2 text-center" :href="'/' + region">
				Inscrivez-vous
			</b-button>
		</div>
		<div class>
			Vérifiez que votre navigateur Internet est à jour avant de faire votre
			demande
			<img src="/images/logo_chrome.png" alt="Logo Chrome" />
			<img src="/images/logo_firefox.png" alt="Logo Firefox" />
		</div>
		<div class="text-center">
			<!--Le alt est volontairement vide car le logo de Service n'est pas présent pour toutes les régions,
			 il faut trouver un comportment plus adéquate, car affichage d'image vide sur FireFox-->
			<img
				class="img-fluid w-25 mx-3"
				:src="'/images/' + region + '/logo_service.png'"
				alt=""
			/>
		</div>

		<h6>
			<em>
				{{ $t("commun.RGPDText1") }}
				<a :href="$t('commun.RGPDLink')">{{ $t("commun.RGPDLinkAlias") }}</a>
				{{ $t("commun.RGPDText2") }}
			</em>
		</h6>
	</div>
</template>

<script>
export default {
	name: "Accueil",
	props: {
		authenticated: {
			type: Boolean,
			default: false,
		},
		region: {
			type: String,
			default: "pays-de-la-loire",
		},
		focus: {
			type: String,
			default: "annuel",
		},
		saisieabosuraccueil: {
			type: Boolean,
		},
		parcoursoccasionnel: {
			type: Boolean,
		},
		parcoursdigital: {
			type: Boolean,
		},
		parcoursoccasionnelmpd: {
			type: Boolean,
		},
		parcoursannuel: {
			type: Boolean,
		},
		parcoursabonneparlignes: {
			type: Boolean,
		},
		parcourspapier: {
			type: Boolean,
		},
	},
	computed: {
		// Fonction qui renvoi un entier correspondant au numéro d'onglet à afficher en fonction du query param
		findTabIndex() {
			let numeroOnglet = 0;
			const parcours = [
				{ estActive: this.parcoursannuel, parcours: "import" },
				{ estActive: this.parcoursdigital, parcours: "numerique" },
				{ estActive: this.parcoursoccasionnel, parcours: "occasionnel" },
			];
			const ongletsActifs = parcours
				.filter((parcours) => parcours.estActive)
				.map((element) => {
					return element.parcours;
				});
			//Vérification de la valeur de this.focus pour renvoyer le premier onglet si le query param "focus" n'est pas utilisé dans l'url
			if (this.focus !== "") {
				numeroOnglet = ongletsActifs.indexOf(this.focus);
			}
			return numeroOnglet === -1 ? 0 : numeroOnglet;
		},
	},
};
</script>

<style scoped></style>
