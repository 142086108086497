<template>
	<div class="my-5 pb-5">
		<div class="align-self-center">
			<br />
			<info-abonne></info-abonne>
			<div v-if="displayInformationsIbanBtn() && subscriber.iban.oneYear">
				<b-button
					v-b-modal.modal-iban-show-informations
					class="pull-right"
					variant="primary"
				>
					Mes informations bancaires
				</b-button>

				<b-modal
					id="modal-iban-show-informations"
					title="Informations bancaires"
					ok-title="Supprimer mes coordonnées bancaires"
					ok-variant="danger"
					cancel-title="Annuler"
					cancel-variant="primary"
					@ok="askForIbanRemoval()"
				>
					<p class="my-4">Vos informations bancaires enregistrées :</p>
					<h5>IBAN : {{ subscriber.iban.value }}</h5>
					<h5>BIC : {{ subscriber.iban.bic }}</h5>
				</b-modal>
			</div>
			<br />
			<br />
			<br />
			<div class="d-inline-block d-flex justify-content-center">
				<div class="text-center">
					<div class="d-flex d-inline-block mb-4">
						<button class="btn btn-info" @click="previousMonth()">
							<i class="fa fa-angle-left fa-2x pr-1" />
						</button>
						<div class="my-auto mx-3">
							<h2>
								<strong>
									{{ displayDate(date) }}
								</strong>
							</h2>
						</div>
						<button class="btn btn-info" @click="nextMonth()">
							<i class="fa fa-angle-right fa-2x pl-1" />
						</button>
					</div>
				</div>
			</div>
			<div
				v-if="
					!getSubscriptionFromCurrentMonth ||
					getSubscriptionFromCurrentMonth.isInactive
				"
				class="text-center"
			>
				{{ $t("accueilInscrit.message") }}
			</div>
			<div
				v-if="getSubObject(getSubscriptionFromCurrentMonth, 'selectedLines')"
			>
				<!-- Composant pour connaître l'éligibilité pour les lignes-->
				<card-taux-regularite
					:current-date="date"
					:region-rules="regionRules"
					:lines="lines"
					:current-month-subscription="getSubscriptionFromCurrentMonth"
					:trips="trips"
					:need-select="false"
					:abonnements="subscriber.abonnements"
					:subscriptions="getSubscriptions"
					:mois_import="subscriber.mois_import"
					@eligible-line="isCompensableLine"
				></card-taux-regularite>
			</div>
			<!--			Affichage pour les trajets avec trains			-->
			<div
				v-if="displayTrainMultiple"
				class="row justify-content-md-center mb-4"
			>
				<div class="col-md-6 border">
					<tableau-trajet
						:title="'Aller'"
						:trips="filterTrainsByDirection('Aller')"
						:need-select="false"
					></tableau-trajet>
				</div>
				<div class="col-md-6 border">
					<tableau-trajet
						:title="'Retour'"
						:trips="filterTrainsByDirection('Retour')"
						:need-select="false"
					></tableau-trajet>
				</div>
			</div>
			<tableau-trajet
				v-else-if="displayTrain"
				:trips="trips"
				:need-select="false"
			></tableau-trajet>
			<choixIndemnisation
				v-if="
					(displayModeRemboursement && onTimeToChooseCompensableMode) ||
					ibanIsElevenMonthOld
				"
				class="mb-5 pb-5"
				:subscription-current-month="getSubscriptionFromCurrentMonth"
				:type-remboursement="regionRules.indemnisation.typeRemboursement"
				:iban="subscriber.iban"
			></choixIndemnisation>
			<div
				v-else-if="displayModeRemboursement && !onTimeToChooseCompensableMode"
				class="text-center"
			>
				<b-badge pill variant="info"
					><h6>
						Vous êtes en dehors des délais pour renseigner votre moyen de
						remboursement.
					</h6></b-badge
				>
			</div>
			<div
				v-if="
					date.isBefore(currentDate, 'month') &&
					getSubObject(getSubscriptionFromCurrentMonth, 'selectedTrains')
				"
			>
				<div v-if="getSubscriptionFromCurrentMonth.region === 'normandie'">
					<h2 class="text-center mb-4">
						<strong>{{ $t("accueilInscrit.rappelIndemnisation") }}</strong>
					</h2>
					<ul>
						<li>{{ $t("accueilInscrit.puce_1") }}</li>
						<li>{{ $t("accueilInscrit.puce_2") }}</li>
					</ul>
				</div>
				<div class="d-flex justify-content-center">
					<h3
						v-if="
							!getSubObject(
								getSubscriptionFromCurrentMonth,
								'eligibilite.eligible'
							) &&
							getSubObject(
								getSubscriptionFromCurrentMonth,
								'eligibilite.nombre_train_eligible'
							) === 0 &&
							!getSubscriptionFromCurrentMonth.isInactive
						"
					>
						<b-badge pill variant="success">{{
							$t("accueilInscrit.infoSurveillanceCRCDone")
						}}</b-badge>
					</h3>
					<h3
						v-if="
							!getSubObject(getSubscriptionFromCurrentMonth, 'eligibilite') &&
							!getSubscriptionFromCurrentMonth.isInactive
						"
					>
						<b-badge pill variant="info">{{
							$t("accueilInscrit.infoSurveillanceCRCNoDone")
						}}</b-badge>
					</h3>
				</div>
				<div
					v-if="
						getSubObject(getSubscriptionFromCurrentMonth, 'eligibilite') &&
						getSubObject(
							getSubscriptionFromCurrentMonth,
							'eligibilite.nombre_train_eligible'
						) > 0 &&
						!getSubscriptionFromCurrentMonth.isInactive
					"
					class="mt-4"
				>
					<div class="container">
						<section class="row">
							<div class="col-6 text-center bg-danger">
								<input id="indemnisable" v-model="checkIndem" type="checkbox" />
								<label class="retardEtSuppIndemnisable" for="indemnisable"
									>Retard/Suppression éligible</label
								>
							</div>
							<div class="col-6 text-center bg-warning">
								<input
									id="notIndemnisable"
									v-model="checkNonIndem"
									type="checkbox"
								/>
								<label class="retardEtSuppNonIndemnisable" for="notIndemnisable"
									>Retard non éligible</label
								>
							</div>
						</section>
					</div>
					<div class="jumbotron h4 pt-1 pb-1">
						Total des retards/suppressions éligibles sur ce mois :
						<strong>{{
							getSubObject(
								getSubscriptionFromCurrentMonth,
								"eligibilite.nombre_train_eligible"
							)
						}}</strong
						><br />
						<div v-if="getSubscriptionFromCurrentMonth.eligibilite">
							<span
								v-if="
									getSubObject(
										getSubscriptionFromCurrentMonth,
										'eligibilite.eligible'
									)
								"
								>{{ $t("accueilInscrit.messageIndem") }}</span
							>
							<span v-else>{{ $t("accueilInscrit.messageNonIndem") }}</span>
						</div>
					</div>
					<periode-surveillee
						:date="date"
						:event-filter="filterEvents"
					></periode-surveillee>
				</div>
				<br /><br /><br />
				<em v-if="displayTrainMultipleMesssage"
					>{{ $t("accueilInscrit.rappelTrainMultiple") }}
				</em>
			</div>
			<div
				v-if="displayAJoutAbonnementBtn() && getSubscriptionFromCurrentMonth"
				class="text-center mb-1 pb-1"
			>
				<button
					type="button"
					class="research btn btn-primary mb-2"
					data-toggle="modal"
					@click="openModalAbonnement"
				>
					{{ $t("selectionHoraire.boutonAjoutAbonnement") }}
				</button>
				<modal-abonnement :subscriber-name="subscriber.lastName">
				</modal-abonnement>
			</div>
			<div v-if="displayModificationBtn()" class="text-center">
				<a
					class="btn btn-outline-info"
					href="selection-horaires?modification=true"
					role="button"
					>Modifier ma souscription</a
				>
			</div>
			<div
				v-if="
					date.isSame(currentDate, 'month') &&
					getSubscriptionFromCurrentMonth &&
					!getSubscriptionFromCurrentMonth.doNotRenew &&
					!getSubscriptionFromCurrentMonth.isInactive
				"
				class="text-center mb-5 pb-5"
			>
				<div>
					<b-button
						v-b-modal.modal-center
						class="btn btn-warning mt-4"
						:disabled="unsubscriptionInProgress"
					>
						Me désinscrire
					</b-button>
					<!-- Modal Component -->
					<b-modal
						id="modal-center"
						centered
						title="Désinscription"
						ok-title="Confirmer"
						ok-variant="success"
						cancel-title="Annuler"
						cancel-variant="danger"
						@ok="desinscription()"
					>
						<p class="my-4">
							{{ $t("accueilInscrit.confirmationDesinscription") }}
						</p>
					</b-modal>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { constants as subscriptionConst } from "../../subscription";
import { constants as subscriberConst } from "../../subscriber";
import { constants as regionRulesConst } from "../../regionRules";
import { constants as ligneConst } from "../../ligne";
import has from "lodash.has";
import get from "lodash.get";
import superagent from "superagent";
import moment from "moment";

export default {
	name: "AccueilInscrit",
	data: function () {
		return {
			subscriber: {
				firstName: "",
				lastName: "",
				clientNumber: "",
				civilite: "",
				origine: {
					libelle: "",
				},
				destination: {
					libelle: "",
				},
				type: "",
				modeDePaiement: "",
				abonnements: [],
				mois_import: [],
				iban: {
					value: "",
					bic: "",
					oneYear: false,
				},
				source: "",
			},
			selectedData: {
				data: "",
			},
			date: moment(),
			currentDate: moment(),
			checkIndem: true,
			checkNonIndem: true,
			//mapUicLabels: new Map(),
			regionRules: null,
			unsubscriptionInProgress: false,
			displayLoader: false,
			eligibleLine: false,
			lines: [],
		};
	},
	computed: {
		/**
		 * Détermine si la date du jour est comprise entre la date de calul de l'éligibilité et le nombre de jour déterminé dans règle région
		 * @returns {boolean}
		 */
		onTimeToChooseCompensableMode() {
			const numberDayToChooseCompensableMode = get(
				this,
				"regionRules.indemnisation.timeToChooseCompensableMode"
			);
			const dateOfCalculationEligibility = get(
				this,
				"regionRules.parcoursAbonneParLignes"
			)
				? moment(get(this, "getSubscriptionFromCurrentMonth.endDate"))
				: moment(
						get(
							this,
							"getSubscriptionFromCurrentMonth.eligibilite.date_calcul"
						),
						"YYYY-MM-DD"
				  );
			const lastDayToChooseCompensableMode = moment(
				dateOfCalculationEligibility,
				"YYYY-MM-DD"
			).add(numberDayToChooseCompensableMode, "days");
			return moment().isBetween(
				dateOfCalculationEligibility,
				lastDayToChooseCompensableMode
			);
		},
		displayModeRemboursement() {
			if (this.regionRules && this.regionRules.parcoursAbonneParLignes) {
				return (
					this.isMonthlyOrComptantSubscriber &&
					this.getSubscriptionFromCurrentMonth &&
					this.eligibleLine
				);
			}
			return (
				this.isMonthlyOrComptantSubscriber &&
				get(this, "getSubscriptionFromCurrentMonth.eligibilite.eligible")
			);
		},
		//Fonction vérifiant si la date de soumission de l'iban date d'il y a 11 mois et si l'abonné à choisi de conserver ces informations bancaire pendant 1 an
		ibanIsElevenMonthOld() {
			const ibanSubmissionDate = moment(this.subscriber.iban.submissionDate);
			const elevenMonthAgo = moment().subtract(11, "months");
			return (
				this.subscriber.iban.oneYear === true &&
				ibanSubmissionDate.isBefore(elevenMonthAgo)
			);
		},
		isMonthlyOrComptantSubscriber() {
			if (
				get(this, "regionRules.indemnisation.ibanForSubscriberComptant") &&
				this.subscriber.type === "annuel"
			) {
				return this.subscriber.modeDePaiement.toLowerCase() === "comptant";
			}
			return this.subscriber.type === "mensuel";
		},
		getSubscriptions: function () {
			return this.$store.getters.getSubscriptions;
		},
		getSubscriptionFromCurrentMonth: function () {
			return this.$store.getters.getSubscriptions.find((event) => {
				const eventStartDate = moment(event.startDate);
				return this.date.isSame(eventStartDate, "months");
			});
		},
		displayTrainMultipleMesssage() {
			return (
				this.getSubObject(
					this.regionRules,
					"selection.selectionTrainMultiple"
				) &&
				this.getSubObject(
					this.getSubscriptionFromCurrentMonth,
					"eligibilite"
				) &&
				this.getSubObject(this.getSubscriptionFromCurrentMonth, "resultat")
			);
		},
		trips() {
			if (this.getSubscriptionFromCurrentMonth !== undefined) {
				return (
					this.getSubscriptionFromCurrentMonth.selectedTrains ||
					this.getSubscriptionFromCurrentMonth.selectedLines
				);
			}
			return [];
		},
		selectionMultiple() {
			return this.getSubObject(
				this.regionRules,
				"selection.selectionTrainMultiple"
			);
		},
		// On vérifie que le mode train multiple est bien activé, car l'affichage du récapitulatif des trains est
		// différent dans ce mode
		displayTrainMultiple() {
			return (
				this.selectionMultiple &&
				this.getSubObject(
					this.getSubscriptionFromCurrentMonth,
					"selectedTrains"
				) &&
				!this.getSubscriptionFromCurrentMonth.isInactive
			);
		},
		// Ici on vérifie que le mode train multiple est au contraire désactivé afin d'utiliser l'affichage
		// du récapitulatif des trains "classique"
		displayTrain() {
			return (
				!this.selectionMultiple &&
				this.getSubObject(
					this.getSubscriptionFromCurrentMonth,
					"selectedTrains"
				) &&
				!this.getSubscriptionFromCurrentMonth.isInactive
			);
		},
		filterEvents: function () {
			const events = this.getSubscriptionFromCurrentMonth.resultat;
			return events.filter(
				(event) =>
					(this.checkIndem && event.eligible === true) ||
					(this.checkNonIndem && event.eligible === false)
			);
		},
	},
	mounted() {
		this.$store
			.dispatch(subscriberConst.FETCH_SUBSCRIBER)
			.then((subscribers) => {
				const subscriber = subscribers[0];
				this.subscriber.firstName = subscriber.prenom;
				this.subscriber.lastName = subscriber.nom;
				this.subscriber.mois_import = subscriber.mois_import;
				this.subscriber.clientNumber = subscriber.referenceClient;
				this.subscriber.civilite = subscriber.civilite;
				this.subscriber.origine = subscriber.origine.libelle;
				this.subscriber.destination = subscriber.destination.libelle;
				this.subscriber.statut = subscriber.etat_client;
				this.subscriber.dateDebutContrat = subscriber.debutContrat;
				this.subscriber.dateFinContrat = subscriber.finContrat;
				this.subscriber.type = subscriber.type;
				this.subscriber.modeDePaiement = subscriber.mode_de_paiement;
				this.subscriber.abonnements = subscriber.abonnements;
				this.subscriber.source = subscriber.source;
				if (subscriber.iban) {
					this.subscriber.iban.value = subscriber.iban.value;
					this.subscriber.iban.bic = subscriber.iban.bic;
					this.subscriber.iban.oneYear = subscriber.iban.keepForOneYear;
					this.subscriber.iban.submissionDate = subscriber.iban.submissionDate;
				}
			});

		this.$store.dispatch(subscriptionConst.FETCH_SUBSCRIPTIONS).then((subs) => {
			if (has(subs[0], "selectedLines")) {
				this.$store
					.dispatch(
						ligneConst.FETCH_TAUX_REGULARITE,
						this.getAllLinesOfSubs(subs)
					)
					.then((resp) => {
						this.lines = resp;
					});
			}
		});

		this.$store
			.dispatch(regionRulesConst.FETCH_REGION_RULES)
			.then((regionRules) => {
				this.regionRules = regionRules;
			});
	},
	methods: {
		nextMonth: function () {
			this.date = moment(this.date).add(1, "month");
		},
		previousMonth: function () {
			this.date = moment(this.date).subtract(1, "month");
		},
		displayDate: function (date) {
			return moment(date).format("MMMM YYYY");
		},
		desinscription() {
			this.unsubscriptionInProgress = true;
			this.onConfirm();
		},
		onConfirm() {
			return $.ajax({
				url: "/api/souscription/desinscription",
				type: "POST",
			})
				.done(() => {
					toastr.success("Votre désinscription à été prise en compte");
					this.$store.dispatch(subscriptionConst.FETCH_SUBSCRIPTIONS);
				})
				.fail((err) => {
					toastr.error(
						`Erreur lors de la tentative de désinscription ${
							JSON.stringify(err.statusText || err.responseJSON) ||
							err.responseText
						}`
					);
					this.unsubscriptionInProgress = false;
				});
		},
		async askForIbanRemoval() {
			const query = {
				body: {
					referenceClient: this.subscriber.clientNumber,
				},
			};
			try {
				await superagent
					.post(`/api/abonne/deleteIban`)
					.set("Content-Type", "application/json")
					.send(query);
				return toastr.success(
					"L'enregistrement de votre demande de suppression de vos informations bancaires a été pris en compte"
				);
			} catch (err) {
				if (err.status === 400) {
					return toastr.error(err.responseJSON.message);
				}
				return toastr.error(
					`Erreur lors de la demande de suppression de votre IBAN`
				);
			}
		},
		displayModificationBtn() {
			if (
				!this.getSubscriptionFromCurrentMonth ||
				this.getSubscriptionFromCurrentMonth.isInactive
			) {
				return false;
			}

			if (
				this.regionRules &&
				this.regionRules.selection.souscriptionEnCoursDeMois
			) {
				return this.date.isSameOrAfter(this.currentDate);
			}
			return this.date.isAfter(this.currentDate);
		},
		displayInformationsIbanBtn() {
			return this.subscriber.iban.value && this.subscriber.iban.bic;
		},
		displayAJoutAbonnementBtn() {
			return (
				this.regionRules &&
				!!this.regionRules.parcoursAbonneParLignes &&
				this.subscriber.source === "MPD"
			);
		},
		openModalAbonnement() {
			$("#modalAbonnement").modal();
		},
		getAllLinesOfSubs(subs) {
			return [
				...new Set(
					subs
						.map((sub) => {
							return sub.selectedLines.map((line) => {
								return line.number_line;
							});
						})
						.flat()
				),
			];
		},
		filterTrainsByDirection(direction) {
			return this.trips.filter(function (train) {
				return train.label === direction;
			});
		},
		// retourne l'information d'Eligibilité d'une souscription par Ligne calculé dans le composant fils
		isCompensableLine(value) {
			this.eligibleLine = value;
		},
		getSubObject(object, path, defaultValue) {
			return get(object, path, defaultValue);
		},
	},
};
</script>

<style scoped></style>
