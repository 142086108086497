import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		origine: {
			libelle: "",
			uic: "",
		},
		destination: {
			libelle: "",
			uic: "",
		},
		reponse: {
			status: "",
			message: "",
			data: {},
		},
		trips: {
			departure: [],
			formattedDateDeparture: null,
			arrival: [],
			formattedDateArrival: null,
			noMoreTrips: {
				arrival: false,
				departure: false,
			},
		},
		subscription: {
			mail: "",
			referenceClient: "",
			startDate: "",
			endDate: "",
			selectedTrains: [],
			region: "",
		},
		subscriptions: [
			{
				mail: "",
				referenceClient: "",
				startDate: "",
				endDate: "",
				selectedTrains: [],
			},
		],
		subscribers: null,
		abonnements: null,
		regionRules: {},
		tauxRegularite: [],
		maxPrices: {},
	},
	mutations,
	actions,
	getters,
});
