<template>
	<div>
		<div
			v-if="
				getObject(typeRemboursement, 'iban') &&
				getObject(typeRemboursement, 'bonVoyage') &&
				!getObject(subscriptionCurrentMonth, 'iban') &&
				!getObject(subscriptionCurrentMonth, 'bonVoyage')
			"
		>
			<b-card class="m-2" header="Choisissez un mode d'indemnisation : ">
				<b-form-group>
					<b-form-radio v-model="selected" value="BonVoyage"
						>Bon voyage
					</b-form-radio>
					<b-form-radio v-model="selected" value="iban"
						>Virement bancaire</b-form-radio
					>
				</b-form-group>
			</b-card>
		</div>
		<div v-if="typeIndemnisationSelected === 'iban'">
			<choix-iban
				class="m-2"
				:subscription-current-month="subscriptionCurrentMonth"
				:validate-state="validateState"
				:iban-existing="iban"
			></choix-iban>
		</div>
		<div v-if="typeIndemnisationSelected === 'BonVoyage'">
			<form-bon-voyage
				:subscription-current-month="subscriptionCurrentMonth"
			></form-bon-voyage>
		</div>
	</div>
</template>

<script>
import _get from "lodash.get";
export default {
	name: "ChoixIndemnisation",
	props: {
		subscriptionCurrentMonth: {
			type: Object,
			default: () => {},
		},
		typeRemboursement: {
			type: Object,
			default: () => {},
		},
		iban: {
			type: Object,
			default: () => {},
		},
	},
	data: function () {
		return {
			selected: "", // Must be an array reference!
		};
	},
	computed: {
		typeIndemnisationSelected() {
			if (_get(this, "subscriptionCurrentMonth.bonVoyage")) {
				return "BonVoyage";
			}
			if (_get(this, "subscriptionCurrentMonth.iban")) {
				return "iban";
			}
			if (
				_get(this, "typeRemboursement.iban") &&
				!_get(this, "typeRemboursement.bonVoyage")
			) {
				return "iban";
			}
			if (
				!_get(this, "typeRemboursement.iban") &&
				_get(this, "typeRemboursement.bonVoyage")
			) {
				return "BonVoyage";
			}
			return this.selected;
		},
	},
	methods: {
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		getObject: _get,
	},
};
</script>

<style scoped></style>
