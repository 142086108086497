<template>
	<div class="text-center my-5 col-12">
		<b-form
			:action="formAction"
			method="post"
			class="form-group"
			@submit="checkForm"
		>
			<b-form-group>
				<label>
					<b>{{ $t("saisieMensuel.inputNumeroCommande") }}</b>
					<i
						v-b-tooltip.hover
						class="fa fa-question-circle fa-2x"
						:title="$t('saisieMensuel.infoBulleNumeroCommande')"
						data-placement="right"
					></i>
				</label>
				<b-form-input
					id="numeroCommande"
					v-model="form.numeroCommande"
					v-validate="'required|length:8'"
					type="text"
					class="m-auto col-sm-4"
					name="numeroCommande"
					:state="validateState('numeroCommande')"
					aria-describedby="numeroCommande-live-feedback"
					data-vv-as="numéro de commande"
					placeholder="AA123456"
				></b-form-input>
				<b-form-invalid-feedback id="numeroCommande-live-feedback">
					{{ veeErrors.first("numeroCommande") }}
				</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group>
				<label>
					<b>Nom* :</b>
					<i
						v-b-tooltip.hover
						class="fa fa-question-circle fa-2x"
						:title="$t('saisieMensuel.infoBulleNom')"
						data-placement="right"
					></i>
				</label>
				<b-form-input
					id="nom"
					v-model="form.nom"
					v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
					class="m-auto col-sm-4"
					name="nom"
					:state="validateState('nom')"
					aria-describedby="nom-live-feedback"
					placeholder="DUPONT"
				></b-form-input>
				<b-form-invalid-feedback id="nom-live-feedback">
					{{ veeErrors.first("nom") }}
				</b-form-invalid-feedback>
			</b-form-group>
			<check-box-rgpd
				id="checkboxValidationRgpd-digital"
				:accord-rgpd="accordRgpd"
				:validate-state="validateState"
				@updateCheckRgpd="updateCheckRgpd"
			></check-box-rgpd>
			<b-button type="submit" class="mt-2 mr-2" variant="success"
				>Connectez-vous</b-button
			>
		</b-form>
	</div>
</template>

<script>
export default {
	name: "SaisieDigital",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				numeroCommande: "",
				nom: "",
				token: "",
			},
			accordRgpd: false,
			captachaToken: "",
		};
	},
	computed: {
		formAction() {
			return this.region === "pays-de-la-loire"
				? `/${this.region}/saisie-abonnement`
				: `/${this.region}/saisie-abonne`;
		},
	},
	watch: {
		"form.nom": function (val) {
			this.form.nom = val.toUpperCase();
		},
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updateCheckRgpd(value) {
			this.accordRgpd = value;
		},
	},
};
</script>

<style scoped></style>
