<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="center mt-5">
		<div class="col-12">
			<div v-if="message" class="alert alert-danger center" role="alert">
				<span v-html="message"></span>
			</div>
			<br />
			<form :action="computedRouteAction" method="post" @submit="checkForm">
				<div class="form-group">
					<label>
						<b>Numéro d'abonné :</b>
						<i
							v-b-tooltip
							class="fa fa-question-circle fa-2x"
							:title="$t('saisieAbonne.infoBulleNumeroAbonne')"
							data-placement="right"
						></i>
					</label>
					<b-form-input
						id="numberSubscriber"
						v-model="seizure.numberSubscriber"
						v-validate="'required'"
						class="form-control col-sm-4 center"
						name="numberSubscriber"
						type="number"
						placeholder="Exemple: 84201644"
						:state="validateState('numberSubscriber')"
					></b-form-input>
					<b-form-invalid-feedback id="numberSubscriber-live-feedback">
						{{ veeErrors.first("numberSubscriber") }}
					</b-form-invalid-feedback>
				</div>
				<br />
				<div class="form-group">
					<label>
						<b>Date de naissance :</b>
						<i
							v-b-tooltip
							class="fa fa-question-circle fa-2x"
							:title="$t('saisieAbonne.infoBulleDateNaissance')"
							data-placement="right"
						></i>
					</label>
					<b-form-input
						id="birthDate"
						v-model="seizure.date"
						v-validate="'required'"
						type="date"
						class="m-auto col-sm-4"
						name="birthDate"
						:state="validateState('birthDate')"
						aria-describedby="birthDate-live-feedback"
						data-vv-as="date de naissance"
					></b-form-input>
					<b-form-invalid-feedback id="birthDate-live-feedback">
						{{ veeErrors.first("birthDate") }}
					</b-form-invalid-feedback>
				</div>
				<check-box-rgpd
					id="checkboxValidationRgpd-abonne"
					:accord-rgpd="accordRgpd"
					:validate-state="validateState"
					@updateCheckRgpd="updateCheckRgpd"
				></check-box-rgpd>
				<div class="form-group">
					<div class="mt-4 pb-5" role="group" aria-label="group 2">
						<button class="submit btn btn-success" type="submit" value="Submit">
							Connectez-vous
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: "SaisieAbonne",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
		message: {
			type: String,
			default: "",
		},
	},
	data: function () {
		return {
			seizure: {
				numberSubscriber: "",
				date: "",
			},
			accordRgpd: false,
		};
	},
	computed: {
		computedRouteAction: function () {
			return `/${this.region}/saisie-abonne`;
		},
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updateCheckRgpd(value) {
			this.accordRgpd = value;
		},
	},
};
</script>

<style scoped>
.center {
	margin: 0 auto;
	text-align: center;
}

.fa-question-circle {
	width: 58px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
</style>
