<template>
	<div>
		<b-card header="Veuillez sélectionner un voyage : ">
			<b-table
				ref="selectableTable"
				selectable
				:select-mode="selectMode"
				:items="trajets"
				:fields="fields"
				responsive="sm"
				selected-variant="success"
				:tbody-tr-class="rowClass"
				class="text-center"
				@row-selected="onRowSelected"
			>
				<template #cell(selected)="data">
					<template v-if="data.rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>
				<!-- eslint-disable-next-line vue/valid-v-slot -->
				<template #cell(eligibilite.eligible)="data">
					<span v-if="data.item.eligibilite.eligible" aria-hidden="true"
						><i class="fa fa-check"></i
					></span>
					<span v-else-if="isEmpty(data.item.eligibilite)">{{
						$t("formulaireIndemnisation.messagePasReponseBigData")
					}}</span>
					<span v-else aria-hidden="true"><i class="fa fa-times"></i></span>
				</template>
				<!-- eslint-disable-next-line vue/valid-v-slot -->
				<template #cell(voyage.retard)="data">
					<span v-if="data.value">{{ data.value }} min</span>
					<span v-else>{{
						$t("formulaireIndemnisation.messagePasReponseBigData")
					}}</span>
				</template>
			</b-table>
		</b-card>
		<br />
	</div>
</template>

<script>
import isEmpty from "lodash.isempty";
export default {
	name: "SelectionVoyageOccasionnel",
	props: {
		trajets: {
			type: Array,
			default: () => [],
		},
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			fields: [
				{
					key: "voyage.origine.label",
					label: "Origine",
				},
				{
					key: "voyage.destination.label",
					label: "Destination",
				},
				{
					key: "voyage.dateVoyage",
					label: "Date du voyage",
				},
				{
					key: "voyage.retard",
					label: "Retard",
				},
				{
					key: "eligibilite.eligible",
					label: "Eligible",
				},
			],
			selected: [],
			selectMode: "single",
		};
	},
	methods: {
		onRowSelected(items) {
			this.selected = items;
			this.$emit("voyageselectionne", items[0]);
		},
		rowClass(item) {
			if (!item) {
				return "";
			}
			if (!isEmpty(item.eligibilite) && !item.eligibilite.eligible) {
				return "table-danger";
			}
			return "";
		},
		isEmpty(value) {
			return isEmpty(value);
		},
	},
};
</script>

<style scoped></style>
