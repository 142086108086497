import ggRegionRulesConst from "./ggRegionRules.constants";

export default {
	[ggRegionRulesConst.FETCH_GG_REGION_RULES]({ commit }, params) {
		return $.get(`/api/ggRegionRules/${params.region}`)
			.then((ggRegionRules) => {
				commit(ggRegionRulesConst.FETCH_GG_REGION_RULES, ggRegionRules);
				return ggRegionRules;
			})
			.catch((err) => {
				toastr.error(
					"Erreur lors de la récupération de la configuration des régions."
				);
				return Promise.reject(err);
			});
	},
};
