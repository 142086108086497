import Vue from "vue";
import VueI18Next from "@panter/vue-i18next";
import i18next from "i18next";
import "jquery-ui/themes/base/all.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "vue-event-calendar/dist/style.css";
import "toastr/build/toastr.min.css";
import "toastr/build/toastr.min.js";

moment.locale("fr");

import { store } from "./store";
import fr from "vee-validate/dist/locale/fr";
import VeeValidate, { Validator } from "vee-validate";
import BootstrapVue from "bootstrap-vue";

import "./pages/accueil/";
import "./pages/accueil-inscrit/";
import "./pages/accueil-inscrit-digital";
import "./pages/selection-horaire/";
import "./pages/formulaire-indemnisation";
import "./pages/formulaire-papier";
import "./pages/selection-horaire/trip-recap/";
import "./pages/selection-horaire/choix-segments/";
import "./pages/selection-horaire/formulaire-recherche-itineraire/";
import "./pages/validation-souscription/";
import "./pages/validation-abonnement/";
import "./pages/periodeSurveillee/";
import "./pages/indemnisation-occasionnel";
import "./pages/indemnisation-occasionnel/selection-voyage-occasionnel";
import "./pages/indemnisation-occasionnel/selection-passager-occasionnel";
import "./pages/gg-formulaire-indemnisation";
import "./pages/gg-formulaire-indemnisation-occasionnel";
import "./pages/gg-accueil";
import "./pages/gg-campagne-terminee";
import "./pages/gg-validation-demande";
import "./common/gg-bandeau-region";
import "./common/bandeau-region";
import "./common/header/";
import "./common/footer/";
import "./common/infoAbonne/";
import "./common/infoAbonnement";
import "./common/rgpd/";
import "./common/selectDays/";
import "./common/form/choixIban";
import "./common/form/formBonVoyage";
import "./common/cardTauxRegularite";
import "./common/saisieAbonne";
import "./common/saisieDigital";
import "./common/saisieDigitalLigne";
import "./common/saisieOccasionnel";
import "./common/saisiePapier";
import "./common/form/ggFormPostale";
import "./common/form/gg-formIdentite";
import "./common/form/formJustificatif";
import "./common/form/gg-formAbonnement";
import "./common/form/ggFormJustificatif";
import "./common/informationsPage";
import "./common/informationsPageLigne";
import "./common/form/ggFormIban";
import "./common/gg-choixIndemnisation";
import "./common/gg-accueil-billettique";
import "./common/gg-accueil-digital";
import "./common/gg-header";
import "./common/gg-footer";
import "./common/choixIndemnisation";
import "./common/checkBoxRgpd";
import "./trip/tableau-trajet/";
import "./trip/tableau-trajet-ligne/";
import "./common/form/formPostale";
import "./common/error";
import "./common/gg-error";
import "./common/saisieOccasionnelMPD";
import "./pages/gg-suivi-demande";
import "./common/gg-accueil-suivi-demande";
import "./common/timeline";
import "./common/form/formIban";
import "./common/form/formIdentite";
import "./common/form/formVoyage";

import vueEventCalendar from "vue-event-calendar";
Vue.use(vueEventCalendar, { locale: "fr", weekStartOn: 1 });

// Changement de variable pour éviter les conflits avec :fields de b-table
// https://bootstrap-vue.js.org/docs/reference/validation/
VeeValidate.configure({
	fieldsBagName: "veeFields",
	errorBagName: "veeErrors",
});
Vue.use(VeeValidate);
Validator.localize("fr", fr);

Vue.use(VueI18Next);

Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

i18next.init({
	lng: "fr",
	resources: window.__local,
	debug: true,
});

const i18n = new VueI18Next(i18next);

new Vue({
	el: "#app",
	i18n,
	store,
	data() {
		return {};
	},
});
