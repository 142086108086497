<template>
	<div id="modalAbonnement" class="modal" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Ajout d'un abonnement</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<b-form
						method="post"
						class="form-group"
						@submit="submitFormAbonnement"
					>
						<b-form-group>
							<label>
								<strong>Référence (REF)* :</strong>
							</label>
							<b-form-input
								id="numeroCommande"
								v-model="form.numeroCommande"
								v-validate="'required|length:8'"
								type="text"
								class="m-auto col-sm-4"
								name="numeroCommande"
								:state="validateState('numeroCommande')"
								aria-describedby="numeroCommande-live-feedback"
								data-vv-as="numéro de commande"
								placeholder="AA123456"
							></b-form-input>
							<b-form-invalid-feedback id="numeroCommande-live-feedback">
								{{ veeErrors.first("numeroCommande") }}
							</b-form-invalid-feedback>
						</b-form-group>
						<b-form-group>
							<label>
								<strong>Nom :</strong>
							</label>
							<b-form-input
								id="nom"
								v-model="name"
								v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
								class="m-auto col-sm-4"
								readonly="readonly"
								name="nom"
								:state="validateState('nom')"
								aria-describedby="nom-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback id="nom-live-feedback">
								{{ veeErrors.first("nom") }}
							</b-form-invalid-feedback>
						</b-form-group>
						<div class="modal-footer">
							<b-button
								class="research btn btn-danger"
								data-dismiss="modal"
								type="button"
								@click="$emit('undo')"
							>
								Annuler
							</b-button>
							<b-button class="research btn btn-success" type="submit">
								Ajouter mon abonnement
							</b-button>
						</div>
					</b-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import superagent from "superagent";

export default {
	name: "ModalAbonnement",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		subscriberName: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				numeroCommande: "",
			},
		};
	},
	computed: {
		name: {
			get() {
				return this.subscriberName;
			},
		},
	},
	methods: {
		submitFormAbonnement(event) {
			this.$validator.validateAll().then(async (result) => {
				event.preventDefault();
				if (!result) {
					return false;
				}

				const query = {
					numeroCommande: this.form.numeroCommande,
					nom: this.name,
				};
				try {
					await superagent.post(`/api/abonne/ajout-abonnement`).query(query);
					toastr.success("Abonnement enregistré");
				} catch (err) {
					toastr.error(err.response.body.message);
				}
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
	},
};
</script>

<style scoped></style>
