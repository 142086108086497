<template>
	<div>
		<b-card
			v-if="initialIban && !ibanExisting.oneYear"
			header="Information bancaire"
			border-variant="info"
			header-bg-variant="info"
			header-text-variant="white"
			class="row"
		>
			<h5>Vous avez renseigné votre IBAN.</h5>
			<em>{{ $t("accueilInscrit.problemeCRC") }}</em>
		</b-card>
		<b-card
			v-else
			header="Information bancaire"
			border-variant="info"
			header-bg-variant="info"
			header-text-variant="white"
			class="row"
		>
			<b-form-group label="IBAN* : " label-for="iban">
				<b-input-group>
					<b-form-input
						id="iban"
						v-model="iban"
						v-validate="{
							required: true,
							regex: /^[A-Z0-9]*$/,
							max: 27,
							iban: true,
						}"
						:readonly="shouldReadOnly"
						name="iban"
						placeholder="Entrez votre IBAN"
						:state="validateState('iban')"
						aria-describedby="iban-live-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="iban-live-feedback">
						{{ veeErrors.first("iban") }}
					</b-form-invalid-feedback>
				</b-input-group>
			</b-form-group>
			<b-form-group label="BIC* : " label-for="bic">
				<b-input-group>
					<b-form-input
						id="bic"
						v-model="bic"
						v-validate="'required|bic'"
						name="bic"
						:readonly="shouldReadOnly"
						placeholder="Entrez votre BIC"
						:state="validateState('bic')"
						aria-describedby="bic-live-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="bic-live-feedback">
						{{ veeErrors.first("bic") }}
					</b-form-invalid-feedback>
				</b-input-group>
			</b-form-group>
			<b-form-group>
				<b-form-checkbox
					id="keepIbanForAYear"
					v-model="keepForOneYear"
					name="keepIbanForAYear"
					:checked="keepForOneYear"
					:state="validateState('keepIbanForAYear')"
				>
					En cochant , j'accepte que mes informations bancaires soient
					conservées pendant 1 An
				</b-form-checkbox>
			</b-form-group>
			<b-form-group>
				<b-button
					id="modal-Iban"
					class="pull-right m-2"
					variant="success"
					@click="showModal"
					>Valider</b-button
				>
				<b-button
					v-if="showModify"
					id="modal-Iban-modify"
					class="pull-right m-2"
					variant="primary"
					:disabled="isDisabled"
					@click="modifyIban"
					>Modifier</b-button
				>
			</b-form-group>
		</b-card>
		<!-- Modal Components -->
		<b-modal
			ref="modal-Iban"
			title="Confirmation de l'IBAN"
			ok-title="Confirmer"
			ok-variant="success"
			cancel-title="Annuler"
			cancel-variant="danger"
			@ok="confirmIban()"
		>
			<p class="my-4">L'IBAN que vous allez soumettre est le suivant:</p>
			<h5>IBAN : {{ formatIban }}</h5>
			<h5>BIC : {{ bic }}</h5>
		</b-modal>
	</div>
</template>

<script>
import IBAN from "iban";
import BIC from "bic";
import { Validator } from "vee-validate";
import superagent from "superagent";
import _ from "lodash";

export default {
	name: "ChoixIban",
	inject: ["$validator"],
	props: {
		subscriptionCurrentMonth: {
			type: Object,
			default: () => {},
		},
		typeRemboursement: {
			type: Object,
			default: () => {},
		},
		validateState: {
			type: Function,
			default: () => {},
		},
		ibanExisting: {
			type: Object,
			default: () => {},
		},
	},
	data: function () {
		return {
			iban: "",
			bic: "",
			initialIban: "",
			keepForOneYear: false,
			shouldReadOnly: false,
			showModify: false,
			isDisabled: false,
		};
	},
	computed: {
		formatIban() {
			return IBAN.printFormat(this.iban, " ");
		},
	},
	watch: {
		iban: function (val) {
			this.value = val.toUpperCase();
		},
		bic: function (val) {
			this.bic = val.toUpperCase();
		},
	},
	mounted() {
		Validator.extend("iban", this.isIban);
		Validator.extend("bic", this.isBic);
		if (!_.isUndefined(this.ibanExisting.value)) {
			this.iban = this.ibanExisting.value;
			this.initialIban = this.ibanExisting.value;
		}
		if (!_.isUndefined(this.ibanExisting.bic)) {
			this.bic = this.ibanExisting.bic;
		}
		if (!_.isEmpty(this.bic) && !_.isEmpty(this.iban)) {
			this.shouldReadOnly = true;
			this.showModify = true;
		}
	},
	methods: {
		modifyIban() {
			this.shouldReadOnly = !this.shouldReadOnly;
			this.isDisabled = true;
		},
		showModal() {
			//permet de vérifier le format de L'iban
			const validityIBAN = this.isIban(this.iban);
			const validityBIC = this.isBic(this.bic);
			if (validityIBAN && validityBIC) {
				this.$refs["modal-Iban"].show();
			} else {
				toastr.error(`Votre IBAN ou votre BIC, n'est pas valide`);
			}
		},
		async confirmIban() {
			const query = this.createIbanObjectAbonne(this.subscriptionCurrentMonth);
			try {
				await superagent
					.post(`/api/abonne/iban`)
					.set("Content-Type", "application/json")
					.send(query);
				return toastr.success(
					"L'enregistrement de votre IBAN a été pris en compte"
				);
			} catch (err) {
				if (err.status === 400) {
					return toastr.error(err.responseJSON.message);
				}
				return toastr.error(
					`Erreur lors de la tentative d'enregistrement de votre IBAN ${this.iban}`
				);
			}
		},
		//Ajoute à l'abonne les informations de l'IBAN
		createIbanObjectAbonne(subscriptionCurrentMonth) {
			return {
				body: {
					id: subscriptionCurrentMonth.id,
					referenceClient: subscriptionCurrentMonth.referenceClient,
					iban: {
						value: this.iban,
						bic: this.bic,
						keepForOneYear: this.keepForOneYear,
					},
				},
			};
		},
		isIban(iban) {
			return IBAN.isValid(iban);
		},
		isBic(bic) {
			return BIC.isValid(bic);
		},
	},
};
</script>
<style scoped></style>
