import { mutations as mutationsTrip } from "../trip";
import { mutations as mutationsSubscription } from "../subscription";
import { mutations as mutationsSubscriber } from "../subscriber";
import { mutations as mutationsRegionRules } from "../regionRules";
import { mutations as mutationsLigneGf } from "../ligne";
import { mutations as mutationsGgRegionRules } from "../ggRegionRules";
import { mutations as mutationsAbonnement } from "../abonnement";

/**
 * Importer ici les mutations vuex des différentes fonctionnalités
 */
export default Object.assign(
	{},
	mutationsTrip,
	mutationsSubscription,
	mutationsSubscriber,
	mutationsRegionRules,
	mutationsLigneGf,
	mutationsGgRegionRules,
	mutationsAbonnement
);
