<template>
	<div>
		<!-- Cas plusieurs correspondances -->
		<div v-if="Array.isArray(tripLine)">
			<table class="table">
				<thead>
					<tr>
						<th v-if="needSelect" scope="col">Via</th>
						<th v-if="!needSelect" scope="col">Nom de la ligne</th>
						<th v-else scope="col"></th>
					</tr>
				</thead>
				<tbody>
					<!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
					<template v-for="(trip, index) in tripLine" v-if="needSelect">
						<!--la class w-100 permet d'agrandir la taille du label pour que le click soit disponible sur l'ensemble de la ligne -->
						<tr :key="index">
							<td>
								<label class="w-100" :for="index">
									{{ displayConnectionDetailsIfNeeded(trip) }}
								</label>
							</td>
							<td>
								<label class="w-100" :for="index">
									<!-- eslint-disable vue/no-mutating-props -->
									<input
										:id="index"
										v-model="choices.data"
										type="radio"
										:value="trip"
									/>
								</label>
							</td>
						</tr>
					</template>
					<!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
					<template v-for="(trip, index) in tripsLine" v-if="!needSelect">
						<tr :key="index">
							<td>{{ trip.label_line }}</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<!-- Cas une seule correspondance et pas de direct -->
		<div v-else-if="tripLine.hasOwnProperty('via')">
			<b-alert show class="primary"
				>{{ $t("selectionHoraire.correspondanceTrouvee") }}
				<b>{{ tripLine.correspondances[0].to.label }}</b
				>.
			</b-alert>
		</div>
		<!-- Cas d'un direct -->
		<div v-else-if="tripLine.lines && tripLine.lines.length > 0">
			<b-alert show variant="info">
				{{ $t("selectionHoraire.directTrouve") }}
			</b-alert>
		</div>
		<div v-else>
			<b-alert show variant="warning">
				{{ $t("selectionHoraire.aucuneLigne") }}
			</b-alert>
		</div>
	</div>
</template>

<script>
import { constants as ligneGfConst } from "../../ligne";

export default {
	name: "TableauTrajetLigne",
	props: {
		tripsLine: {
			type: Array,
			default: function () {
				return [];
			},
		},
		needSelect: {
			type: Boolean,
			default: true,
		},
		choices: {
			type: Object,
			default: function () {
				return { data: "" };
			},
		},
	},
	data: function () {
		return {
			tripLine: [],
			ligneGf: [],
		};
	},
	mounted() {
		if (this.needSelect) {
			this.$store
				.dispatch(ligneGfConst.FETCH_LINES, {
					region: this.$store.getters.getRegionRules.region,
					$select: "label_line,number_line,stations,forced",
				})
				.then((result) => {
					this.ligneGf = result;
					this.tripLine = this.trips();
				});
		}
	},
	methods: {
		referentielLines() {
			const linesDefault = this.ligneGf.filter(
				// eslint-disable-next-line no-prototype-builtins
				(line) => !line.hasOwnProperty("forced")
			);
			const linesForced = this.ligneGf.filter((line) => line.forced === true);
			return { linesDefault, linesForced };
		},
		/**
		 * Permet d'afficher les correspondances au click de la flếche dans le cas où il y a plusieurs correspondances
		 * @param trip
		 */
		displayConnectionDetailsIfNeeded: function (trip) {
			return trip.via
				.map((via) => {
					return via.label;
				})
				.join(" - ");
		},
		valorizeWithLines(trips) {
			return trips.map((trip) => {
				// eslint-disable-next-line no-prototype-builtins
				if (trip.hasOwnProperty("correspondances")) {
					trip.correspondances = trip.correspondances.map((cor) => {
						cor.lines = this.findLinesByOD(cor.from, cor.to);
						return cor;
					});
				} else {
					trip.lines = this.findLinesByOD(trip.from, trip.to);
				}

				return trip;
			});
		},
		/**
		 * Permet d'agréger les trajets trouvés avec un numéro de ligne
		 * @param from
		 * @param to
		 * @returns {Array}
		 */
		findLinesByOD(from, to) {
			const matchingLines = [];
			const refLines = this.referentielLines();

			refLines.linesForced.forEach((line) => {
				const includesFrom = Boolean(
					line.stations.find((station) => station.UIC_Gare === from.uic)
				);
				const includesTo = Boolean(
					line.stations.find((station) => station.UIC_Gare === to.uic)
				);

				if (includesFrom && includesTo) {
					matchingLines.push({
						number_line: line.number_line,
						label_line: line.label_line,
					});
				}
			});
			if (matchingLines.length === 0) {
				refLines.linesDefault.forEach((line) => {
					const includesFrom = Boolean(
						line.stations.find((station) => station.UIC_Gare === from.uic)
					);
					const includesTo = Boolean(
						line.stations.find((station) => station.UIC_Gare === to.uic)
					);
					if (includesFrom && includesTo) {
						matchingLines.push({
							number_line: line.number_line,
							label_line: line.label_line,
						});
					}
				});
			}
			return matchingLines;
		},
		/**
		 * Permet de récupérer les trajet en provenance de trip
		 * @returns {default.props.tripsLine|{default, type}|*}
		 */
		trips() {
			if (this.tripsLine.length === 0) {
				return "";
			}
			if (this.needSelect === false) {
				return this.tripsLine;
			}
			const tripsLine = this.valorizeWithLines(this.tripsLine);
			if (tripsLine.length === 1) {
				// eslint-disable-next-line vue/no-mutating-props
				this.choices.data = tripsLine[0];
				return tripsLine[0];
			}
			if (tripsLine) {
				this.show = false;
			}
			return tripsLine;
		},
	},
};
</script>

<style scoped></style>
