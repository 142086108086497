<template>
	<div>
		<div class="mb-2 text-center">
			<h5>Vous serez remboursé par bon voyage dématérialisé.</h5>
			<em>{{ $t("accueilInscrit.problemeCRC") }}</em>
		</div>
		<div class="mt-2 text-right">
			<b-button
				v-if="!subscriptionCurrentMonth.bonVoyage"
				variant="primary"
				@click="confirmChoice"
				>Valider</b-button
			>
		</div>
	</div>
</template>

<script>
import { constants as subscriptionConst } from "../../../subscription";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";

export default {
	name: "FormBonVoyage",
	props: {
		subscriptionCurrentMonth: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			postale: {},
			disabledAll: false,
		};
	},
	mounted() {
		// Lorsque l'adresse postale existe, les champs sont disabled
		if (!isEmpty(this.subscriptionCurrentMonth.adresse_postale)) {
			this.disabledAll = true;
			this.postale = cloneDeep(this.subscriptionCurrentMonth.adresse_postale);
		}
	},
	methods: {
		confirmChoice() {
			const newSubWithBonVoyage = this.createBonVoyageObject(
				this.subscriptionCurrentMonth
			);
			return $.ajax({
				url: "/api/souscription/bonVoyage",
				type: "POST",
				contentType: "application/json",
				data: JSON.stringify(newSubWithBonVoyage),
			})
				.then(() => {
					this.disabledAll = true;
					toastr.success(
						"L'enregistrement de votre choix a été pris en compte"
					);
					this.$store.dispatch(subscriptionConst.FETCH_SUBSCRIPTIONS);
				})
				.catch((err) => {
					toastr.error(
						`Erreur lors de la tentative d'enregistrement de votre choix ${JSON.stringify(
							err.statusText || err.responseText
						)}`
					);
				});
		},
		//Ajoute a la souscription les information du bon Voyage
		createBonVoyageObject(subscriptionCurrentMonth) {
			return {
				id: subscriptionCurrentMonth.id,
				bonVoyage: true,
				adresse_postale: this.postale,
			};
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updatePostale(postale) {
			this.postale = postale;
		},
	},
};
</script>

<style scoped></style>
