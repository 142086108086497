export default {
	getTrip(state) {
		return state.trips;
	},
	getCurrentTrains(state) {
		const trainsAller = state.trips["departure"].map((item) => {
			return item.numberTrain;
		});
		const trainsRetour = state.trips["arrival"].map((item) => {
			return item.numberTrain;
		});
		return { Aller: trainsAller, Retour: trainsRetour };
	},
};
