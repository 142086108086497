import constants from "./abonnement.constants";
import get from "lodash.get";
import has from "lodash.has";

const url = "/api/abonnement";

const initialPayload = {
	selectedTrains: [
		{
			from: {
				label: "",
				uic: "",
			},
			to: {
				label: "",
				uic: "",
			},
			departureTime: "",
			arrivalTime: "",
			numberTrain: "",
		},
		{
			from: "",
			to: "",
			departureTime: "",
			arrivalTime: "",
			numberTrain: "",
		},
	],
};

export default {
	[constants.FETCH_ABONNEMENT]({ commit }) {
		return $.ajax({
			url: url,
		}).then(function (result) {
			if (!Array.isArray(result)) {
				result = [result];
			}
			commit(constants.FETCH_ABONNEMENT, result);
			return result;
		});
	},

	// eslint-disable-next-line no-unused-vars
	[constants.SAVE_ABONNEMENT](actions = {}, payload = initialPayload) {
		delete payload.modification;
		return $.ajax({
			url: url,
			contentType: "application/json",
			dataType: "json",
			data: JSON.stringify(payload),
			type: "POST",
		})
			.then(function (result) {
				toastr.info("Les trains ont été enregistrée dans l'abonnement");
				setTimeout(() => {
					window.location.href = `validation-abonnement`;
				}, 1000);
				return result;
			})
			.catch(function (err) {
				if (err.status === 400 && err.responseText) {
					toastr.error(err.responseText);
				} else {
					toastr.error(
						"Erreur lors de l'enregistrement des trains pour l'abonnement, veuillez réessayer plus tard."
					);
				}
				return Promise.reject(err);
			});
	},
	// eslint-disable-next-line no-unused-vars
	[constants.SAVE_ABONNEMENT_PAPIER](context, payload) {
		const image = get(payload, "form.justificatif.image");
		const data = new FormData();
		data.append("form", JSON.stringify(get(payload, "form")));
		data.append("file", image);
		data.append("region", get(payload, "region"));
		if (payload.modification) {
			data.append("modification", get(payload, "modification"));
			data.append("idAbonnement", get(payload, "idAbonnement"));
			data.append(
				"referenceAbonnement",
				get(payload, "referenceAbonnement")
			);
		}
		return $.ajax({
			url: "/api/abonnement/papier",
			data: data,
			cache: false,
			contentType: false,
			dataType: "text",
			processData: false,
			type: "POST",
		})
			.then(function (result) {
				toastr.success("Vos informations ont bien été enregistrées.");
				setTimeout(redirection, 1000);
				return result;
			})
			.catch(function (err) {
				if (err.status === 409) {
					toastr.error(
						"Une demande existe déjà pour cet abonnement."
					);
				} else if (err.status === 400) {
					const parsedError = JSON.parse(err.responseText);
					if (has(parsedError, "invalidParameters")) {
						toastr.error(
							"Une erreur est survenue lors de la validation du formulaire, veuillez vérifier les informations saisies."
						);
					} else {
						toastr.error(JSON.parse(err.responseText).message);
					}
				} else {
					toastr.error(
						"Erreur lors de l'enregistrement de la demande d'indemnisation, veuillez réessayer plus tard."
					);
				}
				return Promise.reject(err);
			});
	},
};

const redirection = () => {
	window.location.href = "selection-horaires";
};
