<template>
	<nav
		class="navbar navbar-toggleable-md navbar-light fixed-top justify-content-between bg-light"
	>
		<a class="navbar-brand" :href="`/${region}`">{{ $t("commun.service") }}</a>
		<ul v-if="authenticated === true" class="nav right-menu">
			<li class="nav-item dropdown">
				<a class="nav-link" href="logout">Déconnexion</a>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: "HeaderPDLL",
	props: {
		authenticated: {
			type: Boolean,
			default: false,
		},
		region: {
			type: String,
			default: "",
		},
	},
};
</script>

<style scoped></style>
