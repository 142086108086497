<template>
	<div>
		<div
			v-for="(value, propertyName, index) in formattedTrips"
			:key="index"
			class="col-12"
		>
			<em>{{ propertyName }}</em>
			<br />
			<b>Aller</b>
			<display-trip :trips="value.Aller"></display-trip>
			<b>Retour</b>
			<display-trip :trips="value.Retour"></display-trip>
			<hr />
		</div>
	</div>
</template>

<script>
import _partition from "lodash.partition";
import _groupBy from "lodash.groupby";

export default {
	name: "TripFormatter",
	props: {
		selectedMode: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		formattedTrips: function () {
			const groupByJours = _groupBy(this.selectedMode, (item) => {
				return item.validity.weekdays;
			});

			const res = {};

			for (const [key, value] of Object.entries(groupByJours)) {
				const [aller, retour] = _partition(value, (item) => {
					return item.label === "Aller";
				});
				res[key] = { Aller: aller, Retour: retour };
			}
			return res;
		},
	},
};
</script>
