<template>
	<!-- eslint-disable vue/no-parsing-error -->
	<!-- eslint-disable vue/no-v-html -->
	<div class="my-5 pt-3">
		<div>
			<info-abonnement v-if="digital"></info-abonnement>
			<info-abonne v-else></info-abonne>
			<div class="col text-center">
				<h2>{{ $t("selectionHoraire.titre") }}</h2>
			</div>
			<div v-if="interface.subscriber.name !== ''" class="mt-3">
				<p>
					{{ $t("selectionHoraire.ligne1") }}
					{{ interface.subscriber.civilite }} {{ interface.subscriber.name }},
					<br />
					{{ $t("selectionHoraire.ligne2") }} {{ interface.departure.name }} <>
					{{ interface.arrival.name }}
				</p>
				<p>
					{{ $t("selectionHoraire.ligne3") }}<br />
					{{ $t("selectionHoraire.ligne4") }}
				</p>
				<ul>
					<li
						v-for="(puce, name) in $t('selectionHoraire.puces', {
							returnObjects: true,
						})"
						:key="name"
						v-html="puce"
					></li>
				</ul>
				<em v-if="interface.regionRules.selection.selectionTrainMultiple">
					{{ $t("selectionHoraire.rappelTrainMultiple") }}
				</em>
				<p v-if="!isEmpty($t('selectionHoraire.ligne5'))">
					{{ $t("selectionHoraire.ligne5") }}
				</p>
			</div>
			<div
				v-if="this.interface.subscriber.source === 'papier'"
				class="text-center"
			>
				<b-button variant="primary" @click="redirectionFormulaireModification"
					>Modifier mes informations</b-button
				>
			</div>
		</div>
		<hr />
		<!-- Récapitulatif des trains déjà sélectionnés -->
		<div v-if="selectedTrains.length > 0" class="jumbotron py-2">
			<trip-recap :selected-trains="selectedTrains"></trip-recap>
		</div>
		<!-- Rappel règles et OD -->
		<div>
			<h4
				v-if="!interface.regionRules.parcoursAbonneParLignes"
				class="text-center"
			>
				<b>{{ $t("selectionHoraire.selection") }}</b>
			</h4>
			<h5
				v-if="
					interface.regionRules.selection.mode === 'exception' && !exception
				"
				class="text-center"
			>
				{{ $t("selectionHoraire.exception") }}
			</h5>
			<p class="text-center my-3">
				Voici votre Origine / Destination :
				<strong
					>{{ interface.departure.name }} / {{ interface.arrival.name }}</strong
				>
			</p>
		</div>
		<!-- Sélection des jours de la prochaine recherche/sélection -->
		<div v-if="!interface.regionRules.parcoursAbonneParLignes">
			<select-days
				v-if="displayWeekDaySelection"
				:mode="interface.regionRules.selection.mode"
				:available-week-days="interface.regionRules.selection.availableWeekDays"
				:maxi="interface.regionRules.selection.maxi"
				:already-selected-days="alreadySelectedDays"
				:reach-max-selected-days="reachMaxSelectedDays"
				:exceed-max-selected-days="exceedMaxSelectedDays"
				@updateselecteddays="updateSelectedDays"
			></select-days>
			<!-- Formulaire de recherche -->
			<formulaire-recherche-itineraire
				v-if="displayTripForm"
				:departure="interface.departure"
				:arrival="interface.arrival"
				:weekdays="selectedDays"
				:mode="interface.regionRules.selection.mode"
			></formulaire-recherche-itineraire>
		</div>

		<!-- Résultats de la recherche -->
		<div
			v-if="
				(trajets.Aller.length > 0 || trajets.messageAller) &&
				(trajets.Retour.length > 0 || trajets.messageRetour)
			"
		>
			<div class="row justify-content-md-center mb-4">
				<div class="col-md-6 border">
					<tableau-trajet
						:title="'Aller'"
						:trips="trajets.Aller"
						:message="trajets.messageAller"
						:choix="trajets.selectAller"
						:departure="interface.departure"
						:arrival="interface.arrival"
						:additionnal-time="departureAdditionnalTime"
						:weekdays="selectedDays"
						:need-select="true"
						:multiple-train-selection="isMultipleTrainEnabled"
						:region="region"
						:select-all-in-correspondance="
							interface.regionRules.selection
								.priseEnCompteDeToutesLesCorrespondancesTer
						"
						@select-trip="handleSelectedTripChange"
					></tableau-trajet>
				</div>
				<div class="col-md-6 border">
					<tableau-trajet
						:title="'Retour'"
						:trips="trajets.Retour"
						:message="trajets.messageRetour"
						:choix="trajets.selectRetour"
						:departure="interface.arrival"
						:arrival="interface.departure"
						:additionnal-time="arrivalAdditionnalTime"
						:weekdays="selectedDays"
						:need-select="true"
						:region="region"
						:multiple-train-selection="isMultipleTrainEnabled"
						:select-all-in-correspondance="
							interface.regionRules.selection
								.priseEnCompteDeToutesLesCorrespondancesTer
						"
						@select-trip="handleSelectedTripChange"
					></tableau-trajet>
				</div>
			</div>
		</div>

		<div
			v-if="interface.regionRules.parcoursAbonneParLignes"
			class="text-center"
		>
			<b-spinner
				v-if="tripsLine.length === 0"
				type="grow"
				label="load"
			></b-spinner>
			<tableau-trajet-ligne
				v-else
				:trips-line="!Array.isArray(tripsLine) ? [] : tripsLine"
				:need-select="true"
				:choices="trajets.selectLigne"
			></tableau-trajet-ligne>
		</div>
		<!-- Boutons de validation -->
		<div
			v-if="showValidationButton"
			class="mt-4 d-flex justify-content-around flex-wrap"
		>
			<!-- Bouton d'ajout d'une exception -->
			<button
				v-if="showAddExceptionButton"
				v-b-tooltip
				class="btn btn-warning mb-2"
				type="button"
				data-placement="top"
				:title="$t('selectionHoraire.ajoutException')"
				@click="launchExceptionScenario"
			>
				{{ $t("selectionHoraire.boutonException") }}
			</button>
			<!-- Bouton de nouvelle sélection pour le mode 'many' -->
			<button
				v-if="showAddManyButton"
				v-b-tooltip
				class="btn btn-warning mb-2"
				type="button"
				data-placement="top"
				:title="$t('selectionHoraire.ajoutJours')"
				@click="validateAndResetTripsForMany"
			>
				{{ $t("selectionHoraire.boutonProchaineSelection") }}
			</button>
			<!-- Bouton de validation avec tooltip -->
			<i
				v-if="
					interface.regionRules.selection.mode === 'exception' && !exception
				"
				v-b-tooltip
				class="tooltips"
				:title="$t('selectionHoraire.validerTrains')"
				data-placement="right"
			>
				<button
					type="button"
					class="research btn btn-success mb-2"
					data-toggle="modal"
					@click="openModal"
				>
					{{ $t("selectionHoraire.boutonValiderTrains") }}
				</button>
			</i>
			<!-- Bouton de validation sans tooltip -->
			<button
				v-else
				type="button"
				class="research btn btn-success mb-2"
				data-toggle="modal"
				@click="checkDayIsSelected"
			>
				{{ $t("selectionHoraire.boutonValiderTrains") }}
			</button>
		</div>
		<!-- Validation lignes -->
		<div
			v-if="
				interface.regionRules.parcoursAbonneParLignes &&
				lineExist(trajets.selectLigne.data)
			"
			class="mt-4 d-flex justify-content-around flex-wrap"
		>
			<button
				v-if="this.interface.subscriber.source === 'MPD'"
				type="button"
				class="research btn btn-primary mb-2"
				data-toggle="modal"
				@click="openModalAbonnement"
			>
				{{ $t("selectionHoraire.boutonAjoutAbonnement") }}
			</button>
			<modal-abonnement
				v-if="this.interface.subscriber.source === 'MPD'"
				:subscriber-name="interface.subscriber.name"
			>
			</modal-abonnement>
			<button
				type="button"
				class="research btn btn-success mb-2"
				data-toggle="modal"
				@click="openModal"
			>
				{{ $t("selectionHoraire.boutonValiderTrains") }}
			</button>
		</div>
		<div
			v-if="interface && interface.subscriber"
			class="row d-flex justify-content-center mb-2"
		>
			<!-- On vérifie qu'on est dans un parcours digital ou non afin d'utiliser le traitement "one-shot" ou le traitement classique -->
			<modal-validation
				v-if="digital"
				:departure="interface.departure"
				:arrival="interface.arrival"
				:selected-mode="inValidationSelectedMode"
				:region-rules="interface.regionRules"
				@validate="confirmTripsAbonnement"
				@undo="resetTrip"
			></modal-validation>

			<modal-validation
				v-else
				:departure="interface.departure"
				:arrival="interface.arrival"
				:selected-mode="inValidationSelectedMode"
				:region-rules="interface.regionRules"
				@validate="confirmTrips"
				@undo="resetTrip"
			></modal-validation>
		</div>
	</div>
</template>

<script>
import { constants as subscriptionConst } from "../../subscription";
import { constants as subscriberConst } from "../../subscriber";
import { constants as regionRulesConst } from "../../regionRules";
import { constants as abonnementConst } from "../../abonnement";

import isEmpty from "lodash.isempty";
import tripActions from "../../trip/trip.constants";
import cloneDeep from "lodash.clonedeep";

const SELECT_DAY = "Merci de sélectionner au moins un jour de la semaine";

export default {
	name: "InterfaceDeSelectionDesHorairesDeTrain",
	props: {
		authenticated: {
			type: Boolean,
			default: false,
		},
		region: {
			type: String,
			default: "pays-de-la-loire",
		},
		modification: {
			type: Boolean,
		},
		digital: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			interface: {
				subscriber: {
					name: "",
					civilite: "",
					debutContrat: "",
					finContrat: "",
					clientNumber: "",
					source: "",
					region: "",
				},
				departure: {
					name: "",
				},
				arrival: {
					name: "",
				},
				regionRules: {
					selection: {
						mode: "",
						availableWeekDays: [],
						maxi: null,
						selectionTrainMultiple: false,
						priseEnCompteDeToutesLesCorrespondancesTer: false,
					},
				},
			},
			trajets: {
				selectLigne: {
					data: "",
				},
				Aller: [],
				selectAller: {
					data: [],
				},
				messageAller: "",
				Retour: [],
				selectRetour: {
					data: [],
				},
				messageRetour: "",
				region: "",
			},
			selectedTrains: [],
			selectedLines: [],
			selectedDays: [],
			inValidationSelectedMode: [],
			exception: false, // Cette variable permet de signaler, en mode exception, quand la première sélection a déjà eu lieu et qu'on se situe alors en mode sélection de l'exception
			alreadySelectedDays: [],
			tripValue: [],
			departureAdditionnalTime: 8,
			arrivalAdditionnalTime: 17,
			isMultipleTrainEnabled: false,
		};
	},
	computed: {
		showValidationButton: function () {
			return (
				this.trajets.selectAller.data.length > 0 &&
				this.trajets.selectRetour.data.length > 0
			);
		},
		showAddExceptionButton: function () {
			return (
				this.interface.regionRules.selection.mode === "exception" &&
				!this.exception
			);
		},
		showAddManyButton: function () {
			return (
				this.interface.regionRules.selection.mode === "many" &&
				!this.reachMaxSelectedDays &&
				!this.exceedMaxSelectedDays
			);
		},
		displayWeekDaySelection: function () {
			return (
				this.exception || this.interface.regionRules.selection.mode === "many"
			);
		},
		displayTripForm: function () {
			return !this.exception || (this.exception && this.selectedDays[0]);
		},
		/**
		 * retourne vrai si le nombre de jours séléctionnés est égal au nombre de jours max
		 */
		reachMaxSelectedDays: function () {
			return (
				this.alreadySelectedDays.length + this.selectedDays.length ===
				this.interface.regionRules.selection.maxi
			);
		},
		/**
		 * retourne vrai si le nombre de jours séléctionnés dépasse le nombre de jours max
		 */
		exceedMaxSelectedDays: function () {
			return (
				this.alreadySelectedDays.length + this.selectedDays.length >
				this.interface.regionRules.selection.maxi
			);
		},
		/**
		 * Lance l'action de récupération des trajet pour les lignes
		 * @returns {Array}
		 */
		tripsLine() {
			const params = {
				departure: this.interface.departure,
				arrival: this.interface.arrival,
			};
			//Tant que tripValue n'a pas de valeur, l'appel à trip est effectué en boucle
			if (
				this.tripValue.length === 0 &&
				params.departure.name &&
				params.departure.uic &&
				params.arrival.name &&
				params.arrival.uic
			) {
				// eslint-disable-next-line vue/no-async-in-computed-properties
				this.$store
					.dispatch(tripActions.FETCH_TRIP_LINE, params)
					.then((result) => {
						this.tripValue = result;
					});
			}
			return this.tripValue;
		},
	},
	watch: {
		// Permet de mettre a jour l'heure a passer en parametre a trip dans le cas ou on veut charger plus de trajets
		"trajets.Aller": function (newTrajets) {
			// On prend l'heure du dernier train affiché
			if (newTrajets.length > 0) {
				this.departureAdditionnalTime = parseInt(
					newTrajets[newTrajets.length - 1].departureTime.slice(0, 2)
				);
			}
		},
		"trajets.Retour": function (newTrajets) {
			if (newTrajets.length > 0) {
				this.arrivalAdditionnalTime = parseInt(
					newTrajets[newTrajets.length - 1].departureTime.slice(0, 2)
				);
			}
		},
	},
	mounted: function () {
		this.$store.watch(
			(state) => state.trips,
			(trips) => {
				if (trips.reset) {
					this.resetTrip();
				}
				this.trajets.Aller = [];
				this.trajets.Retour = [];
				if (trips.reset && trips.reset === true) {
					this.trajets.messageAller = "";
					this.trajets.messageRetour = "";
				} else {
					this.trajets.Aller = trips.departure;
					this.trajets.Retour = trips.arrival;
					this.noTrainBinding(trips.departure, "messageAller");
					this.noTrainBinding(trips.arrival, "messageRetour");
				}
			},
			// Permet de declencher le watch en cas de changement nested du state
			{ deep: true }
		);
		// Ici on utilise le fonctionnement en "one-shot", on utilise par conséquent le service prototypé abonnement-gf
		if (this.digital) {
			this.$store
				.dispatch(abonnementConst.FETCH_ABONNEMENT)
				.then((abonnements) => {
					const abonnement = abonnements[0];
					this.interface.subscriber.name = abonnement.abonne.identite.nom;
					this.interface.subscriber.referenceAbonnement =
						abonnement.referenceAbonnement;
					this.interface.subscriber.civilite =
						abonnement.abonne.identite.civilite;
					this.interface.subscriber.debutContrat = abonnement.debutContrat;
					this.interface.subscriber.finContrat = abonnement.finContrat;
					this.interface.departure.name = abonnement.origine.libelle;
					this.interface.departure.uic = abonnement.origine.uic;
					this.interface.arrival.name = abonnement.destination.libelle;
					this.interface.arrival.uic = abonnement.destination.uic;
					this.interface.subscriber.source = abonnement.source;
					this.interface.subscriber.region = abonnement.region;
				});
		} else {
			// Ici on utilise le fonctionnement classique, c'est à dire utilisation d'abonne-gf et souscription-gf
			this.$store
				.dispatch(subscriberConst.FETCH_SUBSCRIBER)
				.then((subscribers) => {
					const subscriber = subscribers[0];
					this.interface.subscriber.name = subscriber.nom;
					this.interface.subscriber.clientNumber = subscriber.referenceClient;
					this.interface.subscriber.civilite = subscriber.civilite;
					this.interface.subscriber.debutContrat = subscriber.debutContrat;
					this.interface.subscriber.finContrat = subscriber.finContrat;
					this.interface.departure.name = subscriber.origine.libelle;
					this.interface.departure.uic = subscriber.origine.uic;
					this.interface.arrival.name = subscriber.destination.libelle;
					this.interface.arrival.uic = subscriber.destination.uic;
					this.interface.subscriber.source = subscriber.source;
				});
		}
		this.$store
			.dispatch(regionRulesConst.FETCH_REGION_RULES)
			.then((regionRules) => {
				this.interface.regionRules = regionRules;
				this.isMultipleTrainEnabled =
					regionRules.selection.selectionTrainMultiple;
			});
	},
	methods: {
		// fonction permettant de mettre a jour les trajets dans le cadre de la selection multiple
		handleSelectedTripChange(data, sens) {
			// Cas pas de trajet sélectionné ou réinitialisation
			if (data.length === 0) {
				this.trajets[`select${sens}`].data = "";
			} else {
				this.trajets[`select${sens}`].data = data;
			}
		},
		lineExist(data) {
			let res = false;
			if (data.lines) {
				res = data.lines.length > 0;
			}
			if (data.correspondances) {
				res = data.correspondances.some((cor) => cor.lines.length > 0);
			}
			return res;
		},
		updateSelectedDays: function (newValue) {
			this.selectedDays = newValue;
		},
		// Confirmation des trains pour le fonctionnement classique (abonne-gf et soucription-gf)
		confirmTrips: function () {
			let subscriber = this.$store.getters.getAbonnes;
			let subscription;
			if (Array.isArray(subscriber)) {
				subscriber = subscriber[0];
			}

			const inValidationSelectedModeWithoutId = cloneDeep(
				this.inValidationSelectedMode
			);
			// l'id n'est plus utile par la suite
			inValidationSelectedModeWithoutId.map((mode) => {
				delete mode.id;
			});
			if (
				this.interface.regionRules.selection.souscriptionAuMois ||
				this.interface.regionRules.selection.souscriptionPourLeMoisSuivant
			) {
				subscription = {
					// premier jour du mois du contrat
					startDate: moment(subscriber.debutContrat, "DD-MM-YYYY")
						.startOf("M")
						.toDate(),
					//dernier jour du mois du contrat
					endDate: moment(subscriber.finContrat, "DD-MM-YYYY")
						.endOf("M")
						.toDate(),
					type: subscriber.type,
					prix_prestation_sncf_fer: subscriber.prix_prestation_sncf_fer,
					region: subscriber.region,
					selectedTrains: inValidationSelectedModeWithoutId,
					doNotRenew: true, // Evite que la souscription soit reconduite au mois suivant
					onMonth: true,
					modification: this.modification,
				};
			} else {
				subscription = {
					// premier jour du mois suivant
					startDate: moment().add(1, "M").startOf("M").toDate(),
					//dernier jour du mois suivant
					endDate: moment().add(1, "M").endOf("M").toDate(),
					type: subscriber.type || "annuel",
					region: subscriber.region || "pays-de-la-loire",
					prix_prestation_sncf_fer: subscriber.prix_prestation_sncf_fer,
					modification: this.modification,
				};
				if (this.interface.regionRules.parcoursAbonneParLignes) {
					subscription.selectedLines = inValidationSelectedModeWithoutId;
				} else {
					subscription.selectedTrains = inValidationSelectedModeWithoutId;
				}
			}
			this.$store.dispatch(subscriptionConst.SAVE_SUBSCRIPTION, subscription);
		},
		// Confirmation des trains pour le fonctionnement "one-shot" (abonnement-gf)
		confirmTripsAbonnement() {
			let abonnement = this.$store.getters.getAbonnements;
			if (Array.isArray(abonnement)) {
				abonnement = abonnement[0];
			}

			const inValidationSelectedModeWithoutId = cloneDeep(
				this.inValidationSelectedMode
			);
			// l'id n'est plus utile par la suite
			inValidationSelectedModeWithoutId.map((mode) => {
				delete mode.id;
			});

			abonnement.selectedTrains = inValidationSelectedModeWithoutId;
			this.$store.dispatch(abonnementConst.SAVE_ABONNEMENT, abonnement);
		},
		/**
		 * resetTrip permet de réinitialiser la selection lors
		 * de l'affichage de la modal
		 */
		resetTrip: function () {
			if (this.interface.regionRules.parcoursAbonneParLignes) {
				this.trajets.selectLigne.data = "";
			} else {
				this.trajets.selectAller.data = [];
				this.trajets.selectRetour.data = [];
			}
		},
		// Affichage d'un message si trip ne trouve pas de circulation
		noTrainBinding(tripData, message) {
			if (tripData.length === 0) {
				this.trajets[message] = "Pas de train en circulation à cet horaire";
			} else {
				this.trajets[message] = "";
			}
		},
		updateSelectedTrains() {
			this.selectedTrains = this.selectedTrains.concat(
				this.trajets.selectAller.data,
				this.trajets.selectRetour.data
			);
		},
		launchExceptionScenario: function () {
			this.exception = true;
			this.updateSelectedTrains();
			return this.$store.dispatch(tripActions.CLEAN_TRIPS);
		},
		redirectionFormulaireModification() {
			window.location.href = `/${this.interface.subscriber.region}/formulaire-papier?modification=true`;
		},
		validateAndResetTripsForMany: function () {
			if (this.selectedDays.length === 0) {
				toastr.error(SELECT_DAY);
				return;
			}

			if (this.exceedMaxSelectedDays) {
				toastr.error(
					`Vous ne pouvez pas sélectionner plus de ${this.interface.regionRules.selection.maxi} jours.`
				);
				return;
			}

			this.sortWeekDays(this.selectedDays);
			if (!this.interface.regionRules.parcoursAbonneParLignes) {
				this.addValidityToTrains();
			}
			this.alreadySelectedDays = this.alreadySelectedDays.concat(
				this.selectedDays
			);
			this.updateSelectedTrains();
			this.selectedDays = [];
			this.resetTrip();
			this.$store.dispatch(tripActions.CLEAN_TRIPS);
		},
		addValidityToTrains() {
			this.trajets.selectAller.data.forEach((item) => {
				item.validity = { weekdays: this.selectedDays };
			});
			this.trajets.selectRetour.data.forEach((item) => {
				item.validity = { weekdays: this.selectedDays };
			});
		},
		checkDayIsSelected: function () {
			if (
				this.selectedDays.length === 0 &&
				this.interface.regionRules.selection.mode === "many"
			) {
				toastr.error(SELECT_DAY);
			} else {
				this.openModal();
			}
		},
		openModal: function () {
			this.exceptionIsMany();
			if (this.interface.regionRules.parcoursAbonneParLignes) {
				let lines = [];
				if (this.trajets.selectLigne.data.correspondances) {
					this.trajets.selectLigne.data.correspondances.forEach(
						(correspondance) => {
							lines.push(this.mapLine(correspondance));
						}
					);
				} else {
					lines.push(this.mapLine(this.trajets.selectLigne.data));
				}
				lines = lines.flat();
				this.selectedTrains = lines;
			} else {
				this.updateSelectedTrains();
			}
			this.adValidityField();

			this.inValidationSelectedMode = this.selectedTrains;
			this.inValidationSelectedMode = this.inValidationSelectedMode.map(
				(selectedTrain) => {
					delete selectedTrain.selectable;
					return selectedTrain;
				}
			);
			this.selectedTrains = [];
			this.selectedDays = [];
			this.alreadySelectedDays = [];
			this.exception = false;
			if (!this.interface.regionRules.parcoursAbonneParLignes) {
				this.$store.dispatch(tripActions.CLEAN_TRIPS);
			}
			$("#modalValidation").modal();
		},

		openModalAbonnement() {
			$("#modalAbonnement").modal();
		},
		exceptionIsMany() {
			if (
				this.exception === true ||
				this.interface.regionRules.selection.mode === "many"
			) {
				if (this.exception === true && this.selectedDays.length !== 1) {
					toastr.error("Merci de sélectionner un jour de la semaine");
					return;
				} else if (this.selectedDays.length === 0) {
					toastr.error(SELECT_DAY);
					return;
				}

				if (this.exceedMaxSelectedDays) {
					toastr.error(
						`Vous ne pouvez pas sélectionner plus de ${this.interface.regionRules.selection.maxi} jours.`
					);
					return;
				}

				this.sortWeekDays(this.selectedDays);
				if (!this.interface.regionRules.parcoursAbonneParLignes) {
					this.addValidityToTrains();
				}
			}
		},
		adValidityField() {
			// ajout du champ validity pour chaque selectedTrain contenant les jours de la semaine ou le train est valide
			this.selectedTrains.forEach((trip) => {
				if (
					!trip.validity &&
					!this.interface.regionRules.parcoursAbonneParLignes
				) {
					trip.validity = {
						// on garde les jours différents des jours d'exception
						weekdays:
							this.interface.regionRules.selection.availableWeekDays.filter(
								(weekday) => {
									return !this.selectedDays.includes(weekday);
								}
							),
					};
				}
			});
		},

		/**
		 * Permet de mettre en forme les données à enregistrer pour les lignes
		 */
		mapLine(trip) {
			let tripLine = [];
			tripLine = trip.lines.map((line) => {
				return {
					from: {
						label: trip.from.label,
						uic: trip.from.uic,
					},
					to: {
						label: trip.to.label,
						uic: trip.to.uic,
					},
					number_line: line.number_line,
					label_line: line.label_line,
				};
			});
			return tripLine;
		},
		sortWeekDays(weekdays) {
			const sorter = {
				lundi: 1,
				mardi: 2,
				mercredi: 3,
				jeudi: 4,
				vendredi: 5,
				samedi: 6,
				dimanche: 7,
			};
			weekdays.sort((day1, day2) => sorter[day1] - sorter[day2]);
		},
		isEmpty(value) {
			return isEmpty(value);
		},
	},
};
</script>

<style scoped>
div.border {
	border-right: 3px solid;
}
</style>
