<template>
	<!-- eslint-disable vue/no-v-html -->
	<div v-if="Display" class="modal-rgpd fixed-bottom bg-dark">
		<p
			class="h6 text-center mx-auto text-white"
			v-html="$t('commun.RGPDText')"
		></p>
		<b-button
			class="mx-auto btn d-flex justify-content-center btn-white"
			@click="hide"
		>
			OK
		</b-button>
	</div>
</template>
<script>
export default {
	name: "Rgpd",
	data: function () {
		return {
			Display: true,
		};
	},
	watch: {
		Display(newDisplay) {
			localStorage.Display = newDisplay;
		},
	},

	mounted() {
		if (localStorage.Display) {
			this.Display = localStorage.Display === false;
		}
	},

	methods: {
		hide: function () {
			this.Display = false;
		},
	},
};
</script>
<style scoped>
.modal-rgpd {
	padding: 1rem;
	z-index: 1035;
	max-height: 100%;
	overflow-y: auto;
}
</style>
