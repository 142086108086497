<template>
	<div class="my-5 pb-5">
		<div class="container text-center pt-4">
			<img
				class="img-fluid mx-3 mb-1"
				:src="'/images/' + region + '/logo_TER.png'"
				alt="Image de la région"
			/>
			<img
				class="img-fluid"
				:src="'/images/' + region + '/bandeau.png'"
				alt="Bandeau de la région"
			/>
		</div>
		<br />
		<div class="text-center">
			<h3 class="pt-3">{{ text }}</h3>
			<a
				v-if="getSubObject(lienObject, 'show')"
				class="h3"
				:href="getSubObject(lienObject, 'url')"
				>{{ getSubObject(lienObject, "texte") }}</a
			>
		</div>
	</div>
</template>

<script>
import get from "lodash.get";

export default {
	name: "GgCampagneTerminee",
	props: {
		region: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			default: "",
		},
		lien: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			lienObject: {},
		};
	},
	mounted() {
		if (this.lien) {
			this.lienObject = JSON.parse(this.lien);
		}
	},
	methods: {
		getSubObject(object, path, defaultValue) {
			return get(object, path, defaultValue);
		},
	},
};
</script>

<style scoped></style>
