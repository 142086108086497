<template>
	<div class="my-5 py-2">
		<b-form
			method="post"
			@submit.stop.prevent="onSubmit"
			@reset.stop.prevent="onReset"
		>
			<b-form-group>
				<b-form-checkbox
					id="checkboxValidationRegion"
					v-model="checkedValidationRegion"
					v-validate="'required:invalidateFalse'"
					name="checkboxValidationRegion"
					:state="validateState('checkboxValidationRegion')"
				>
					Je suis titulaire d’un abonnement sur la région
					<strong>{{ $t("garantieGreve.region") }}</strong>
					<b-form-invalid-feedback
						:state="validateState('checkboxValidationRegion')"
					>
						Veuillez cocher la case
					</b-form-invalid-feedback>
				</b-form-checkbox>
			</b-form-group>
			<br />
			<gg-form-identite
				:identite="form.abonne.identite"
				:adresse_courriel="form.abonne.adresse_courriel"
				:adresse-courriel-confirmation="adresseCourrielConfirmation"
				:options-civilite="optionsCivilite"
				:validate-state="validateState"
				:disabled-input="existingAbonne.identite"
				@updateAdresseCourriel="updateAdresseCourriel"
				@updateAdresseCourrielConfirmation="updateAdresseCourrielConfirmation"
				@update-identite="updateIdentite"
			></gg-form-identite>
			<br />
			<gg-form-abonnement
				:abonnement="form.abonne.abonnement"
				:validate-state="validateState"
				:disabled-input="existingAbonne.abonnement"
				:max-price="regionRules.mode_indemnisation.maxPrice"
				@updateGareDepart="updateGareDepart"
				@updateGareArrivee="updateGareArrivee"
				@update-abonnement="updateAbonnement"
			></gg-form-abonnement>
			<br />
			<gg-form-postale
				:postale="form.demande.type_remboursement.coordonnees_postales"
				:validate-state="validateState"
				:disabled-input="
					getSubObject(
						existingAbonne,
						'demandes[0].type_remboursement.coordonnees_postales'
					)
				"
				@update-postale="updatePostale"
			></gg-form-postale>
			<br />
			<gg-choix-indemnisation
				:type-remboursement="regionRules.mode_indemnisation.typeRemboursement"
				:iban="form.demande.type_remboursement.iban"
				:validate-state="validateState"
			></gg-choix-indemnisation>
			<br />
			<gg-form-justificatif
				v-if="
					(showjustificatifgg &&
						regionRules.campagne.formulaires.demandeJustificatif) ||
					!form.abonne.source ||
					form.abonne.source === 'manuel'
				"
				v-model="form.demande.justificatif.image"
				:validate-state="validateState"
			></gg-form-justificatif>
			<em class="mt-2 mr-2">* données obligatoires</em>
			<div class="d-flex justify-content-md-end">
				<b-button type="reset" class="mt-2 mr-2" variant="danger"
					>Vider les champs
				</b-button>
				<b-button type="submit" class="mt-2 ml-2" variant="primary"
					>Valider la demande
				</b-button>
			</div>
		</b-form>
		<b-modal
			id="modal-confirmation"
			:title="
				$t(
					'garantieGreve.formulaireIndemnisation.titreModalConfirmationFormulaire'
				)
			"
		>
			<p class="my-4">
				{{
					$t(
						"garantieGreve.formulaireIndemnisation.modalConfirmationFormulaire"
					)
				}}
			</p>
			<template #modal-footer>
				<b-row :no-gutters="true">
					<b-col>
						<b-button
							class="mt-3"
							variant="danger"
							@click="$bvModal.hide('modal-confirmation')"
							>Annuler
						</b-button>
					</b-col>
					<b-col>
						<b-button class="mt-3" variant="success" @click="submitForm"
							>Valider
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
		<b-modal
			id="modal-vider"
			:title="
				$t(
					'garantieGreve.formulaireIndemnisation.titreModalConfirmationViderChamp'
				)
			"
			cancel-title="Annuler"
			cancel-variant="danger"
			ok-title="Valider"
			ok-variant="success"
			@ok="effacerDonneeInput"
		>
			<p class="my-4">
				{{
					$t(
						"garantieGreve.formulaireIndemnisation.modalConfirmationViderChamps"
					)
				}}
			</p>
		</b-modal>
	</div>
</template>

<script>
import { constants as ggRegionRulesConst } from "../../ggRegionRules";
import { constants as ggAbonneConst } from "../../ggAbonne";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import merge from "lodash.merge";
import has from "lodash.has";

export default {
	name: "GgFormulaireIndemnisation",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
		alreadyexists: {
			type: Boolean,
		},
		// Abonne est un String car l'objet a été striginfié à ce stade
		abonne: {
			type: String,
			default: () => {},
		},
		showjustificatifgg: {
			type: Boolean,
		},
	},
	data() {
		return {
			form: {
				abonne: {
					identite: {
						civilite: "",
						nom: "",
						prenom: "",
						dateNaissance: "",
						telephone: "",
					},
					adresse_courriel: "",
					abonnement: {
						type_abonnement: "",
						nom_abonnement: "",
						periode_abonnement: {
							debut: "",
							fin: "",
						},
						origine: {
							libelle: "",
							uic: null,
						},
						destination: {
							libelle: "",
							uic: null,
						},
						prix_prestation: null,
					},
				},
				demande: {
					type_remboursement: {
						coordonnees_postales: {
							numero: null,
							voie: "",
							complementAdresse: "",
							codePostal: "",
							ville: "",
							pays: "",
						},
						iban: {
							value: "",
							bic: "",
						},
					},
					justificatif: {
						libelle: "",
						image: null,
					},
				},
			},
			checkedValidationRegion: false,
			optionsCivilite: [
				{ text: "Monsieur", value: "monsieur" },
				{ text: "Madame", value: "madame" },
			],
			adresseCourrielConfirmation: "",
			regionRules: {
				mode_indemnisation: {
					showFormiban: false,
					maxPrice: null,
				},
				campagne: {
					formulaires: {
						demandeJustificatif: false,
					},
				},
			},
			existingAbonne: {},
		};
	},
	mounted() {
		if (this.abonne) {
			this.existingAbonne = JSON.parse(this.abonne);
			this.form.demande = merge(
				this.form.demande,
				get(this.existingAbonne, "demandes[0]")
			);
			this.form.abonne = merge(this.form.abonne, this.existingAbonne);
			delete this.form.abonne.demandes;
		}
		this.$store
			.dispatch(ggRegionRulesConst.FETCH_GG_REGION_RULES, {
				region: this.region,
			})
			.then((ggRegionRules) => {
				this.regionRules = ggRegionRules;
			});
	},
	methods: {
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		onSubmit() {
			this.$validator.validateAll().then(async (result) => {
				if (!result) {
					return;
				}
				this.$bvModal.show("modal-confirmation");
			});
		},
		onReset() {
			this.$bvModal.show("modal-vider");
		},
		effacerDonneeInput() {
			this.form.abonne.identite.civilite = "";
			this.form.abonne.identite.nom = "";
			this.form.abonne.identite.prenom = "";
			this.form.abonne.identite.dateNaissance = "";
			this.form.abonne.identite.telephone = "";
			this.form.abonne.abonnement.type_abonnement = "";
			this.form.abonne.abonnement.nom_abonnement = "";
			this.form.abonne.abonnement.periode_abonnement.debut = "";
			this.form.abonne.abonnement.periode_abonnement.fin = "";
			this.form.abonne.abonnement.prix_prestation = null;
			this.form.abonne.abonnement.origine.libelle = "";
			this.form.abonne.abonnement.origine.uic = null;
			this.form.abonne.abonnement.destination.libelle = "";
			this.form.abonne.abonnement.destination.uic = null;
			this.form.demande.type_remboursement.coordonnees_postales = {
				numero: null,
				voie: "",
				complementAdresse: "",
				codePostal: "",
				ville: "",
				pays: "",
			};
			this.form.demande.type_remboursement.iban = {
				value: "",
				bic: "",
			};
			this.form.demande.justificatif.image = null;

			this.form.demande = merge(
				this.form.demande,
				get(this.existingAbonne, "demandes[0]")
			);
			this.form.abonne = merge(this.form.abonne, this.existingAbonne);
			delete this.form.abonne.demandes;
			this.form.abonne.adresse_courriel = "";
			this.adresseCourrielConfirmation = "";
			this.form.abonne.telephone = "";

			this.$bvModal.hide("modal-vider");
		},
		updateAdresseCourriel(adresse) {
			this.form.abonne.adresse_courriel = adresse;
		},
		updateAdresseCourrielConfirmation(adresse) {
			this.adresseCourrielConfirmation = adresse;
		},
		updateGareDepart(gare) {
			if (has(this.form, "abonne.abonnement.origine.uic")) {
				delete this.form.abonne.abonnement.origine.uic;
			}
			this.form.abonne.abonnement.origine.libelle = gare;
		},
		updateGareArrivee(gare) {
			if (has(this.form, "abonne.abonnement.destination.uic")) {
				delete this.form.abonne.abonnement.destination.uic;
			}
			this.form.abonne.abonnement.destination.libelle = gare;
		},
		async submitForm() {
			if (!this.form.abonne.source) {
				this.form.abonne.source = "manuel";
			}
			this.form.abonne.region = this.region;
			if (
				!this.regionRules.campagne.formulaires.demandeJustificatif &&
				this.form.abonne.source !== "manuel"
			) {
				delete this.form.demande.justificatif;
			} else {
				this.form.demande.justificatif.libelle = `${this.form.abonne.identite.nom}_${this.form.abonne.identite.prenom}`;
			}
			this.form.abonne.abonnement.origine.uic = Number(
				this.form.abonne.abonnement.origine.uic
			);
			this.form.abonne.abonnement.destination.uic = Number(
				this.form.abonne.abonnement.destination.uic
			);
			this.form.abonne.abonnement.prix_prestation = Number(
				this.form.abonne.abonnement.prix_prestation
			);
			if (isEmpty(get(this, "form.demande.type_remboursement.iban.value"))) {
				this.deleteUnusedPaiementMethod();
			}
			this.$bvModal.hide("modal-confirmation");
			try {
				await this.$store.dispatch(ggAbonneConst.GG_SAVE_DEMANDE, {
					existingAbonne: this.existingAbonne,
					abonne: this.form.abonne,
					demande: this.form.demande,
					alreadyexists: this.alreadyexists,
				});
			} catch (e) {
				if (e.message) {
					toastr.error(e.message);
				} else {
					toastr.error(
						"Une erreur est survenue, veuillez réessayer plus tard."
					);
				}
			}
		},
		deleteUnusedPaiementMethod() {
			return delete this.form.demande.type_remboursement.iban;
		},
		getSubObject(object, path) {
			return get(object, path);
		},
		updateIdentite(identite) {
			this.form.abonne.identite = identite;
		},
		updatePostale(postale) {
			this.form.demande.type_remboursement.coordonnees_postales = postale;
		},
		updateAbonnement(abonnement) {
			this.form.abonne.abonnement = abonnement;
		},
	},
};
</script>
