<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="center my-5">
		<div class="col-12">
			<div
				v-if="message"
				class="alert alert-danger center"
				role="alert"
				v-html="message"
			></div>
			<br />
			<form
				id="app"
				:action="computedRouteAction"
				method="post"
				@submit="checkForm"
			>
				<div class="form-group">
					<label>
						<b>{{ $t("saisieOccasionnel.inputDossierVoyage") }}</b>
						<i
							v-b-tooltip.hover
							class="fa fa-question-circle fa-2x"
							:title="$t('saisieOccasionnel.infoBulleDossierVoyageur')"
							data-placement="right"
						></i>
					</label>
					<b-form-input
						id="folderTrip"
						v-model="seizure.folderTrip"
						v-validate="'required|alpha_num|length:6'"
						class="center col-sm-4"
						name="folderTrip"
						placeholder="Exemple: AZERTY | AZ1ER2"
						:state="validateState('folderTrip')"
						aria-describedby="folderTrip-live-feedback"
						data-vv-as="dossier voyage"
						maxlength="6"
					></b-form-input>
					<b-form-invalid-feedback id="folderTrip-live-feedback">
						{{ veeErrors.first("folderTrip") }}
					</b-form-invalid-feedback>
				</div>
				<br />
				<div v-if="askForNumeroTCN" class="form-group">
					<label>
						<b>{{ $t("saisieOccasionnel.inputNumeroTCN") }}</b>
						<i
							v-b-tooltip.hover
							class="fa fa-question-circle fa-2x"
							:title="$t('saisieOccasionnel.infoBulleNumeroTCN')"
							data-placement="right"
						></i>
					</label>
					<b-form-input
						id="numeroTCN"
						v-model="seizure.numeroTCN"
						v-validate="'required|numeric|length:9'"
						class="center col-sm-4"
						name="numeroTCN"
						placeholder="Exemple: 123456789"
						:state="validateState('numeroTCN')"
						aria-describedby="numeroTCN-live-feedback"
						data-vv-as="Numéro TCN"
						maxlength="9"
					></b-form-input>
					<b-form-invalid-feedback id="numeroTCN-live-feedback">
						{{ veeErrors.first("numeroTCN") }}
					</b-form-invalid-feedback>
				</div>
				<br />
				<div class="form-group">
					<label>
						<b>Nom :</b>
						<i
							v-b-tooltip.hover
							class="fa fa-question-circle fa-2x"
							:title="$t('saisieOccasionnel.infoBulleNom')"
							data-placement="right"
						></i>
					</label>
					<b-form-input
						id="name"
						v-model="seizure.name"
						v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
						class="form-control col-sm-4 center"
						name="name"
						placeholder="Exemple: Dupont"
						:state="validateState('name')"
						data-vv-as="Nom"
						aria-describedby="name-live-feedback"
					></b-form-input>
					<b-form-invalid-feedback id="name-live-feedback">
						{{ veeErrors.first("name") }}
					</b-form-invalid-feedback>
				</div>
				<div class="form-group">
					<check-box-rgpd
						id="checkboxValidationRgpd-occasionnel"
						:accord-rgpd="accordRgpd"
						:validate-state="validateState"
						@updateCheckRgpd="updateCheckRgpd"
					></check-box-rgpd>
					<div class="mt-4 pb-5" role="group" aria-label="group 2">
						<button class="submit btn btn-success" type="submit" value="Submit">
							Connectez-vous
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	name: "SaisieOccasionnel",

	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
		message: {
			type: String,
			default: "",
		},
	},
	data: function () {
		return {
			seizure: {
				folderTrip: "",
				numeroTCN: "",
				name: "",
			},
			accordRgpd: false,
		};
	},
	computed: {
		computedRouteAction: function () {
			return `/${this.region}/saisie-occasionnel`;
		},
		askForNumeroTCN: function () {
			return this.region !== "normandie";
		},
	},
	watch: {
		"seizure.folderTrip": function (val) {
			this.seizure.folderTrip = val.toUpperCase();
		},
		"seizure.name": function (val) {
			this.seizure.name = val.toUpperCase();
		},
	},
	methods: {
		checkForm: function (e) {
			return this.$validator.validateAll().then((result) => {
				if (!result) {
					toastr.error("Certains champs n'ont pas été renseignés correctement");
					e.preventDefault();
					return false;
				}
				return true;
			});
		},
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		updateCheckRgpd(value) {
			this.accordRgpd = value;
		},
	},
};
</script>

<style scoped>
.center {
	margin: 0 auto;
	text-align: center;
}

.fa-question-circle {
	width: 58px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
</style>
