import constants from "./occasionnel.constants";
import get from "lodash.get";
import has from "lodash.has";

export default {
	[constants.SAVE_DEMANDE_INDEMNISATION](context, payload) {
		const image = get(payload, "form.justificatif.image");
		const data = new FormData();
		data.append("form", JSON.stringify(get(payload, "form")));
		if (get(payload, "passagersSelected")) {
			data.append(
				"passagersSelected",
				JSON.stringify(get(payload, "passagersSelected"))
			);
		}
		data.append("file", image);
		return $.ajax({
			url: "/api/occasionnel",
			data: data,
			cache: false,
			contentType: false,
			dataType: "text",
			processData: false,
			type: "POST",
		})
			.then(function (result) {
				toastr.success(
					"Demande d'indemnisation enregistrée avec succès."
				);
				return result;
			})
			.catch(function (err) {
				if (err.status === 409) {
					toastr.error(
						"Une demande existe déjà pour ce dossier voyage et ce numéro de train."
					);
				} else if (err.status === 400) {
					const parsedError = JSON.parse(err.responseText);
					if (has(parsedError, "invalidParameters")) {
						const invalidParameters = get(
							parsedError,
							"invalidParameters"
						);
						if (invalidParameters.includes("form.iban.bic")) {
							toastr.error("Le bic renseigné n'est pas valide.");
						} else {
							toastr.error(
								"Une erreur est survenure lors de la validation du formulaire, veuillez vérifier les informations saisies."
							);
						}
					} else {
						toastr.error(JSON.parse(err.responseText).message);
					}
				} else {
					toastr.error(
						"Erreur lors de l'enregistrement de la demande d'indemnisation, veuillez réessayer plus tard."
					);
				}
				return Promise.reject(err);
			});
	},
};
