<template>
	<div v-if="subscriber.civilite !== ''" class="jumbotron py-2">
		<div class="d-flex justify-content-between flex-wrap">
			<div>
				<h5><b>Civilité : </b>{{ subscriber.civilite }}</h5>
				<h5><b>Nom : </b>{{ subscriber.lastName }}</h5>
				<h5><b>Prénom : </b>{{ subscriber.firstName }}</h5>
				<h5><b>Numéro client : </b>{{ subscriber.clientNumber }}</h5>
			</div>
			<div>
				<h5>
					<b>Origine - Destination : </b>{{ subscriber.origine }} -
					{{ subscriber.destination }}
				</h5>
				<h5 v-if="subscriber.statut">
					<b>Statut : </b>{{ subscriber.statut }}
				</h5>
				<h5><b>Nom de l'abonnement : </b>{{ subscriber.produit }}</h5>
				<h5 v-if="subscriber.type === 'annuel'">
					<b>Abonnement : </b> du {{ subscriber.dateDebutContrat }} au
					{{ subscriber.dateFinContrat }}
				</h5>
			</div>
		</div>
	</div>
</template>

<script>
import { constants as subscriberConst } from "../../subscriber";

export default {
	name: "InfoAbonne",
	data: function () {
		return {
			subscriber: {
				firstName: "",
				lastName: "",
				clientNumber: "",
				civilite: "",
				produit: "",
				origine: {
					libelle: "",
				},
				destination: {
					libelle: "",
				},
				type: "",
			},
			selectedData: {
				data: "",
			},
		};
	},
	mounted() {
		this.$store
			.dispatch(subscriberConst.FETCH_SUBSCRIBER)
			.then((subscribers) => {
				const subscriber = subscribers[0];
				this.subscriber.firstName = subscriber.prenom;
				this.subscriber.lastName = subscriber.nom;
				this.subscriber.clientNumber = subscriber.referenceClient;
				this.subscriber.civilite = subscriber.civilite;
				this.subscriber.origine = subscriber.origine.libelle;
				this.subscriber.destination = subscriber.destination.libelle;
				this.subscriber.statut = subscriber.etat_client;
				this.subscriber.dateDebutContrat = subscriber.debutContrat;
				this.subscriber.dateFinContrat = subscriber.finContrat;
				this.subscriber.produit = subscriber.produit;
				this.subscriber.type = subscriber.type;
			});
	},
};
</script>

<style scoped></style>
