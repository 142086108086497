<template>
	<div id="modalValidation" class="modal" tabindex="-1" role="dialog">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Validation de la souscription</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<p>
									{{ $t("modal.selection") }}
									<strong> {{ departure.name }} / {{ arrival.name }} </strong>
									{{ $t("modal.mode") }}
								</p>
							</div>
							<div v-if="regionRules.parcoursAbonneParLignes" class="col-12">
								<display-trip-line
									:trip="selectedMode"
									:departure="departure"
									:arrival="arrival"
								></display-trip-line>
							</div>
							<div v-else>
								<trip-formatter :selected-mode="selectedMode"></trip-formatter>
							</div>
						</div>
					</div>
				</div>
				<p>
					<i class="small"
						>En validant ce formulaire, j’accepte que mes informations soient
						utilisées exclusivement pour le traitement de ma demande</i
					>
				</p>
				<div class="modal-footer">
					<button
						class="research btn btn-danger"
						data-dismiss="modal"
						type="button"
						@click="$emit('undo')"
					>
						Annuler
					</button>
					<button
						class="research btn btn-success"
						type="button"
						@click="$emit('validate')"
					>
						Confirmer ce choix
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalValidation",
	props: {
		departure: {
			type: Object,
			default: () => {},
		},
		arrival: {
			type: Object,
			default: () => {},
		},
		selectedMode: {
			type: Array,
			default: () => [],
		},
		regionRules: {
			type: Object,
			default: () => {},
		},
	},
};
</script>

<style scoped>
p {
	text-align: center;
}
</style>
