<template>
	<div class="container">
		<div class="row justify-content-center">
			<form class="col-sm-6 mb-4">
				<div class="form-container">
					<label for="departureTime"
						><b><i class="fa fa-clock-o grey-text" /> Aller : </b></label
					>
					<select
						id="departureTime"
						v-model="selectedDepartureTime"
						class="form-control"
						name="time"
					>
						<option
							v-for="(hour, index) in hours"
							:key="index"
							:value="hour"
							:selected="hour === selectedDepartureTime"
						>
							{{ hour }}:00
						</option>
					</select>
				</div>
				<div class="form-container">
					<label for="arrivalTime"
						><b><i class="fa fa-clock-o grey-text" /> Retour : </b></label
					>
					<select
						id="arrivalTime"
						v-model="selectedArrivalTime"
						class="form-control"
						name="time"
					>
						<option
							v-for="(hour, index) in hours"
							:key="index"
							:value="hour"
							:selected="hour === selectedArrivalTime"
						>
							{{ hour }}:00
						</option>
					</select>
				</div>
				<div class="form-container mt-4">
					<div class="text-center">
						<b-spinner v-if="displaySpinner"> </b-spinner>
						<button
							v-else
							class="btn btn-success"
							type="button"
							@click="fetchTrips()"
						>
							Rechercher
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import tripActions from "../../../trip/trip.constants";

export default {
	name: "FormulaireRechercheItineraire",
	props: {
		departure: {
			type: Object,
			default: () => {},
		},
		arrival: {
			type: Object,
			default: () => {},
		},
		weekdays: {
			type: Array,
			default: () => [],
		},
		mode: {
			type: String,
			default: () => "",
		},
	},
	data: function () {
		return {
			displaySpinner: false,
			selectedDepartureTime: 8,
			selectedArrivalTime: 17,
			hours: [
				0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
				20, 21, 22, 23,
			],
		};
	},
	watch: {
		// Réinitialise les résultats de la recherche si l'on modifie le formulaire
		selectedDepartureTime: function (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.$store.dispatch(tripActions.CLEAN_TRIPS);
			}
		},
		selectedArrivalTime: function (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.$store.dispatch(tripActions.CLEAN_TRIPS);
			}
		},
	},
	methods: {
		fetchTrips: function () {
			if (this.weekdays.length === 0 && this.mode === "many") {
				toastr.error("Merci de sélectionner au moins un jour de la semaine");
			} else {
				this.displaySpinner = true;
				this.$store.dispatch(tripActions.CLEAN_TRIPS);
				if (this.selectedDepartureTime && this.selectedArrivalTime) {
					toastr.info(
						"Lancement d'une nouvelle recherche",
						"Recherche d'itinéraires"
					);
					this.$store
						.dispatch(tripActions.FETCH_TRIP, {
							time: {
								departure: this.selectedDepartureTime,
								arrival: this.selectedArrivalTime,
							},
							departure: this.departure,
							arrival: this.arrival,
							weekday: this.weekdays[0],
						})
						.then(() => {
							toastr.remove();
							toastr.success("Recherche terminée", "Recherche d'itinéraires");
							this.displaySpinner = false;
							this.scrollToPageBottom();
						})
						.catch(() => {
							this.displaySpinner = false;
						});
				} else {
					toastr.error(
						"Saisie invalide. L'heure de départ ne peut être supérieure à l'heure d'arrivée."
					);
				}
			}
		},
		scrollToPageBottom() {
			window.scroll(0, document.body.scrollHeight);
		},
	},
};
</script>

<style scoped></style>
