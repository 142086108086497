<template>
	<b-card
		:header="$t('formulaireIndemnisation.blocs.voyage.blocName')"
		border-variant="info"
		header-bg-variant="info"
		header-text-variant="white"
		class="row"
	>
		<b-form-group
			:label="$t('formulaireIndemnisation.blocs.voyage.dateVoyage')"
			label-for="dateVoyage"
		>
			<b-form-input
				id="dateVoyage"
				v-validate="{
					required: true,
					date_format: 'dd/MM/yyyy',
					date_between: [delayTime, today, true],
				}"
				name="dateVoyage"
				type="text"
				placeholder="JJ/MM/AAAA"
				:state="validateState('dateVoyage')"
				aria-describedby="DateVoyage-live-feedback"
				data-vv-as="date du voyage"
				:disabled="disabledInput.dateVoyage"
				:value="voyage.dateVoyage"
				@input="setVoyage($event, 'dateVoyage')"
			></b-form-input>
			<b-form-invalid-feedback id="DateVoyage-live-feedback">
				{{ veeErrors.first("dateVoyage") }}
			</b-form-invalid-feedback>
		</b-form-group>

		<b-form-group
			v-if="tripchoices"
			:label="$t('formulaireIndemnisation.blocs.voyage.numeroTrain')"
			label-for="numeroTrain"
		>
			<b-form-input
				id="numeroTrain"
				v-validate="'required'"
				placeholder="Numéro de train"
				name="numeroTrain"
				:state="validateState('numeroTrain')"
				aria-describedby="numeroTrain-live-feedback"
				data-vv-as="numéro du train"
				:disabled="disabledInput.numTrain"
				:value="voyage.numTrain"
				@input="setVoyage($event, 'numTrain')"
			></b-form-input>
			<b-form-invalid-feedback id="numeroTrain-live-feedback">
				{{ veeErrors.first("numeroTrain") }}
			</b-form-invalid-feedback>
		</b-form-group>

		<b-form-group
			v-else
			:label="$t('formulaireIndemnisation.blocs.voyage.numeroTrain')"
			label-for="numeroTrain"
		>
			<b-form-select
				v-if="!isEmpty(trainsNumber)"
				id="numeroTrain"
				v-validate="'required'"
				:options="trainsNumber"
				placeholder="Numéro de train"
				name="numeroTrain"
				:state="validateState('numeroTrain')"
				aria-describedby="numeroTrain-live-feedback"
				data-vv-as="numéro du train"
				:disabled="disabledInput.numTrain"
				:value="voyage.numTrain"
				@input="setVoyage($event, 'numTrain')"
			></b-form-select>
			<b-form-input
				v-else
				id="numeroTrain"
				v-validate="'required'"
				name="numeroTrain"
				:state="validateState('numeroTrain')"
				aria-describedby="numeroTrain-live-feedback"
				data-vv-as="numéro du train"
				:disabled="disabledInput.numTrain"
				:value="voyage.numTrain"
				@input="setVoyage($event, 'numTrain')"
			></b-form-input>
			<b-form-invalid-feedback id="numeroTrain-live-feedback">
				{{ veeErrors.first("numeroTrain") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group
			:label="$t('formulaireIndemnisation.blocs.voyage.gareDepart')"
			label-for="origine"
		>
			<b-form-input
				id="origine"
				v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
				name="origine"
				:state="validateState('origine')"
				aria-describedby="origine-live-feedback"
				:disabled="disabledInput.label"
				:value="voyage.origine.label"
				@input="setVoyage($event, 'origine.label')"
			></b-form-input>
			<b-form-invalid-feedback id="origine-live-feedback">
				{{ veeErrors.first("origine") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group
			:label="$t('formulaireIndemnisation.blocs.voyage.gareArrivee')"
			label-for="destination"
		>
			<b-form-input
				id="destination"
				v-validate="'required|regex:^[a-zA-Zàâèéêëöùúûüç \'-]+$'"
				name="destination"
				:state="validateState('destination')"
				aria-describedby="destination-live-feedback"
				:disabled="disabledInput.label"
				:value="voyage.destination.label"
				@input="setVoyage($event, 'destination.label')"
			></b-form-input>
			<b-form-invalid-feedback id="destination-live-feedback">
				{{ veeErrors.first("destination") }}
			</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group
			:label="
				passagersSelected && passagersSelected.length > 1
					? $t('formulaireIndemnisation.blocs.voyage.prix.manyTickets')
					: $t('formulaireIndemnisation.blocs.voyage.prix.oneTicket')
			"
			label-for="prix"
		>
			<b-input-group>
				<span class="input-group-text"><i class="fa fa-euro fa-lg"></i></span>
				<b-form-input
					id="prix"
					v-validate="'required'"
					type="number"
					step="0.01"
					name="prix"
					:state="validateState('prix')"
					aria-describedby="prix-live-feedback"
					:disabled="disabledInput.prix"
					:value="voyage.prix"
					@input="setVoyage($event, 'prix')"
				></b-form-input>
				<b-form-invalid-feedback id="prix-live-feedback">
					{{ veeErrors.first("prix") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>
		<b-form-group
			v-if="!voyage.estSupprime && !voyage.retard"
			:label="$t('formulaireIndemnisation.blocs.voyage.impact')"
		>
			<b-form-radio-group
				id="selectedImpact"
				v-model="selectedImpactInput"
				v-validate="'required'"
				name="selectedImpact"
				:options="optionsImpact"
				:state="validateState('selectedImpact')"
				data-vv-as="selection impact"
			>
				<b-form-invalid-feedback :state="validateState('selectedImpact')">
					{{ veeErrors.first("selectedImpact") }}
				</b-form-invalid-feedback>
			</b-form-radio-group>
		</b-form-group>
		<b-alert
			v-if="voyage.estSupprime || selectedImpact === 'suppression'"
			class="text-center"
			variant="info"
			show
			>Votre train a été supprimé</b-alert
		>
		<div
			v-if="
				voyage.estSupprime &&
				getSubObject(regionRules, 'occasionnels_indemnisation.deletionDetails')
			"
		>
			<p>
				{{ $t("formulaireIndemnisation.blocs.voyage.suppression.question") }}
				{{ voyage.destination.label }} ?
			</p>
			<!-- eslint-disable vue/no-v-html -->
			<p>
				<em
					v-html="
						$t('formulaireIndemnisation.blocs.voyage.suppression.information')
					"
				></em>
			</p>
			<b-form-group label="">
				<b-form-radio-group
					id="radio-group-2"
					name="radio-sub-component"
					stacked
					:value="voyage.retard"
					@change="setVoyage($event, 'retard')"
				>
					<b-form-radio
						v-for="(palier, name) in $t(
							'formulaireIndemnisation.blocs.voyage.suppression.paliers',
							{ returnObjects: true }
						)"
						:key="name"
						:value="name"
						>{{ palier }}</b-form-radio
					>
				</b-form-radio-group>
			</b-form-group>
		</div>
		<b-form-group
			v-if="
				(voyage.retard && !voyage.estSupprime) || selectedImpact === 'retard'
			"
			label="Retard annoncé à l'arrivée : "
			label-for="retard"
		>
			<b-input-group>
				<span class="input-group-text">minutes</span>
				<b-form-input
					id="retard"
					v-validate="`required|min_value:${seuilRetardMinimum}|max_value:1440`"
					type="number"
					name="retard"
					:state="validateState('retard')"
					aria-describedby="retard-live-feedback"
					:disabled="disabledInput.retard && !isEmpty(tripchoices.eligibilite)"
					:value="voyage.retard"
					@input="setVoyage($event, 'retard')"
				></b-form-input>
				<b-form-invalid-feedback id="retard-live-feedback">
					{{ veeErrors.first("retard") }}
				</b-form-invalid-feedback>
			</b-input-group>
		</b-form-group>
	</b-card>
</template>

<script>
import isEmpty from "lodash.isempty";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

export default {
	name: "FormVoyage",
	inject: ["$validator"],
	props: {
		regionRules: {
			type: Object,
			default: function () {
				return {};
			},
		},
		/**
		 * Contient les données du voyage de l'utilisateur
		 * @model
		 */
		voyage: {
			type: Object,
			default: () => {
				return {
					dateVoyage: "",
					numTrain: null,
					origine: {
						label: "",
					},
					destination: {
						label: "",
					},
					retard: "",
					prix: null,
					estSupprime: false,
				};
			},
		},
		/**
		 * Permet de faire un controle sur les inputs
		 */
		validateState: {
			type: Function,
			default: () => {},
		},
		/**
		 * Objet regroupant l'ensemble des données récupérer du parent afin de disabled les champs renseignés
		 */
		disabledInput: {
			type: Object,
			default: () => {},
		},
		tripchoices: {
			type: Object,
			default: () => {},
		},
		seuilRetardMinimum: {
			type: Number,
			default: null,
		},
		selectedImpact: {
			type: String,
			default: "",
		},
		passagersSelected: {
			type: Array,
			default: () => [],
		},
		delayTime: {
			type: String,
			default: "",
		},
		trainsNumber: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			today: moment().startOf("day").format("DD/MM/YYYY"),
			optionsImpact: [
				{ text: "un retard", value: "retard" },
				{ text: "une suppression", value: "suppression" },
			],
		};
	},
	computed: {
		selectedImpactInput: {
			get: function () {
				return this.selectedImpact;
			},
			set: function (newValue) {
				this.$emit("update:selectedImpact", newValue);
			},
		},
	},
	methods: {
		isEmpty(value) {
			return isEmpty(value);
		},
		/**
		 * Récupération des valeurs dans un objet avec la méthode get de lodash
		 * @param {object} object
		 * @param {string} path
		 * @param {*} defaultValue
		 * @returns {*}
		 */
		getSubObject(object, path, defaultValue) {
			return get(object, path, defaultValue);
		},
		setVoyage(value, property) {
			const voyage = cloneDeep(this.voyage);
			_set(voyage, `${property}`, value);
			this.$emit("update-voyage", voyage);
		},
	},
};
</script>

<style scoped></style>
