<template>
	<div>
		<div class="row">
			<ul class="pt-2">
				<li
					v-for="(item, index) in items"
					:key="index"
					class="timeline"
					:class="{
						'actif-li': item.actif && !item.last,
						'inactif-li': !item.actif && !item.last,
					}"
				>
					<div class="pb-5" :class="{ actif: item.date, inactif: !item.date }">
						<span v-if="item.date"
							><b>{{ item.date }}</b></span
						>
						<span>{{ item.message }}</span>
						<span>{{ item.secondMessage }}</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "Timeline",
	props: {
		/**
		 * Items est un tableau d'objet comportant des objets de la forme suivante :
		 * {
		 *     "date": String,
		 *     "message": String,
		 *     "secondMessage": String,
		 *     "actif": Boolean - permet d'afficher la bordure à gauche en pointillé,
		 *     "last": Boolean - permet de déterminer si c'est le dernier élément de la timeline
		 * }
		 */
		items: {
			type: Array,
			default: () => {
				return [
					{
						date: "",
						message: "",
						secondMessage: "",
						actif: false,
						last: false,
					},
				];
			},
		},
	},
};
</script>

<style scoped>
/* Position de la barre de la timeline */
li.timeline {
	list-style-type: none;
	position: relative;
}

/* Permet de centrer la barre de la timeline */
li.timeline:before {
	content: " ";
	position: absolute;
	left: 28px;
	height: 100%;
	z-index: 400;
}

/*Permet d'avoir une barre pleine avec une certaine largeur et couleur*/
li.timeline.actif-li:before {
	border: 2px solid #6ede88;
}

/*Permet d'avoir une barre pointillé avec une certaine largeur et couleur*/
li.timeline.inactif-li:before {
	border: 2px dashed #d4d9df;
}

li {
	padding-left: 20px;
}

/* Permet de créer le rond sur la timeline*/
li > div:before {
	content: " ";
	position: absolute;
	border-radius: 50%;
	width: 20px;
	z-index: 400;
}

/* Permet de gérer la larger de la bordure du rond la couleur et la hauteur */
.actif:before {
	border: 3px solid #6ede88;
	padding-bottom: 13px;
	background: #6ede88;
}

/* Permet de gérer la larger de la bordure du rond la couleur et la hauteur */
.inactif:before {
	border: 3px solid #d4d9df;
	padding-bottom: 13px;
	background: white;
}

/* Décalage de la date et du message */
li > div > span {
	padding-left: 2em;
	display: table-caption;
	width: 201%;
}
</style>
