<template>
	<div>
		<b-card header="Veuillez sélectionner les passagers : ">
			<b-table
				ref="selectableTable"
				selectable
				:select-mode="selectMode"
				:items="passagersEligible"
				:fields="fields"
				responsive="sm"
				selected-variant="success"
				:tbody-tr-class="rowClass"
				class="text-center"
				@row-selected="onRowSelected"
			>
				<!-- eslint-disable-next-line vue/valid-v-slot -->
				<template #cell(pricesByTrain[0].price)="data">
					<span>{{ data.item.pricesByTrain[0].price }} €</span>
				</template>
				<template #cell(selected)="data">
					<template v-if="data.rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>
			</b-table>
		</b-card>
	</div>
</template>

<script>
import isEmpty from "lodash.isempty";
export default {
	name: "SelectionPassagerOccasionnel",
	props: {
		passagersEligible: {
			type: Array,
			default: () => [],
		},
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			fields: [
				{
					key: "firstName",
					label: "Prénom",
				},
				{
					key: "lastName",
					label: "Nom",
				},
				{
					key: "birthDate",
					label: "Date de naissance",
				},
				{
					key: "pricesByTrain[0].price",
					label: "Prix du billet",
				},
			],
			selected: [],
			selectMode: "multi",
		};
	},
	methods: {
		onRowSelected(items) {
			this.selected = items;
			this.$emit("passagerSelectionne", items);
		},
		rowClass(item) {
			if (!item) {
				return;
			}
		},
		isEmpty(value) {
			return isEmpty(value);
		},
	},
};
</script>

<style scoped></style>
