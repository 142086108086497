<template>
	<div class="my-5 py-2">
		<b-form
			method="post"
			@submit.stop.prevent="onSubmit"
			@reset.stop.prevent="onReset"
		>
			<b-form-group>
				<b-form-checkbox
					id="checkboxValidationRegion"
					v-model="checkedValidationRegion"
					v-validate="'required:invalidateFalse'"
					name="checkboxValidationRegion"
					:state="validateState('checkboxValidationRegion')"
				>
					Je suis titulaire d’un titre de transport TER
					<strong>{{ $t("garantieGreve.region") }}</strong>
					<b-form-invalid-feedback
						:state="validateState('checkboxValidationRegion')"
					>
						Veuillez cocher la case
					</b-form-invalid-feedback>
				</b-form-checkbox>
			</b-form-group>
			<br />
			<gg-form-identite
				:identite="form.abonne.identite"
				:adresse_courriel="form.abonne.adresse_courriel"
				:adresse-courriel-confirmation="adresseCourrielConfirmation"
				:options-civilite="optionsCivilite"
				:validate-state="validateState"
				@updateAdresseCourriel="updateAdresseCourriel"
				@updateAdresseCourrielConfirmation="updateAdresseCourrielConfirmation"
				@update-identite="updateIdentite"
			></gg-form-identite>
			<br />
			<gg-form-postale
				:postale="form.demande.type_remboursement.coordonnees_postales"
				:validate-state="validateState"
				@update-postale="updatePostale"
			></gg-form-postale>
			<br />
			<gg-form-iban
				:iban="getSubObject(form, 'demande.type_remboursement.iban')"
				:validate-state="validateState"
			></gg-form-iban>
			<br />
			<em class="mt-2 mr-2">* données obligatoires</em>
			<div class="d-flex justify-content-md-end">
				<b-button type="reset" class="mt-2 mr-2" variant="danger"
					>Vider les champs
				</b-button>
				<b-button type="submit" class="mt-2 ml-2" variant="primary"
					>Valider la demande
				</b-button>
			</div>
		</b-form>
		<b-modal
			id="modal-confirmation"
			:title="
				$t(
					'garantieGreve.formulaireIndemnisation.titreModalConfirmationFormulaire'
				)
			"
		>
			<p class="my-4">
				{{
					$t(
						"garantieGreve.formulaireIndemnisation.modalConfirmationFormulaire"
					)
				}}
			</p>
			<template #modal-footer>
				<b-row :no-gutters="true">
					<b-col>
						<b-button
							class="mt-3"
							variant="danger"
							@click="$bvModal.hide('modal-confirmation')"
							>Annuler
						</b-button>
					</b-col>
					<b-col>
						<b-button variant="success" @click="submitForm">Valider </b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
		<b-modal
			id="modal-vider"
			:title="
				$t(
					'garantieGreve.formulaireIndemnisation.titreModalConfirmationViderChamp'
				)
			"
			cancel-title="Annuler"
			cancel-variant="danger"
			ok-title="Valider"
			ok-variant="success"
			@ok="effacerDonneeInput"
		>
			<p class="my-4">
				{{
					$t(
						"garantieGreve.formulaireIndemnisation.modalConfirmationViderChamps"
					)
				}}
			</p>
		</b-modal>
	</div>
</template>

<script>
import { constants as ggAbonneConst } from "../../ggAbonne";
import get from "lodash.get";

export default {
	name: "GgFormulaireIndemnisationOccasionnel",
	provide() {
		return {
			$validator: this.$validator,
		};
	},
	props: {
		region: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			form: {
				abonne: {
					identite: {
						civilite: "",
						nom: "",
						prenom: "",
						dateNaissance: "",
						telephone: "",
					},
					adresse_courriel: "",
				},
				demande: {
					type_remboursement: {
						coordonnees_postales: {
							numero: null,
							voie: "",
							complementAdresse: "",
							codePostal: "",
							ville: "",
							pays: "",
						},
						iban: {
							value: "",
							bic: "",
						},
					},
				},
			},
			checkedValidationRegion: false,
			optionsCivilite: [
				{ text: "Monsieur", value: "monsieur" },
				{ text: "Madame", value: "madame" },
			],
			adresseCourrielConfirmation: "",
		};
	},
	methods: {
		validateState(ref) {
			if (
				this.veeFields[ref] &&
				(this.veeFields[ref].dirty || this.veeFields[ref].validated)
			) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
		onSubmit() {
			this.$validator.validateAll().then(async (result) => {
				if (!result) {
					return;
				}
				this.$bvModal.show("modal-confirmation");
			});
		},
		onReset() {
			this.$bvModal.show("modal-vider");
		},
		effacerDonneeInput() {
			this.form.abonne.identite.civilite = "";
			this.form.abonne.identite.nom = "";
			this.form.abonne.identite.prenom = "";
			this.form.abonne.identite.dateNaissance = "";
			this.form.abonne.identite.telephone = "";
			this.form.demande.type_remboursement.coordonnees_postales = {
				numero: null,
				voie: "",
				complementAdresse: "",
				codePostal: "",
				ville: "",
				pays: "",
			};
			this.form.demande.type_remboursement.iban = {
				value: "",
				bic: "",
			};

			this.form.abonne.adresse_courriel = "";
			this.adresseCourrielConfirmation = "";
			this.form.abonne.telephone = "";

			this.$bvModal.hide("modal-vider");
		},
		updateAdresseCourriel(adresse) {
			this.form.abonne.adresse_courriel = adresse;
		},
		updateAdresseCourrielConfirmation(adresse) {
			this.adresseCourrielConfirmation = adresse;
		},
		updateIdentite(identite) {
			this.form.abonne.identite = identite;
		},
		updatePostale(postale) {
			this.form.demande.type_remboursement.coordonnees_postales = postale;
		},
		updateAbonnement(abonnement) {
			this.form.abonne.abonnement = abonnement;
		},
		async submitForm() {
			if (!this.form.abonne.source) {
				this.form.abonne.source = "manuel";
			}
			this.form.abonne.region = this.region;
			this.$bvModal.hide("modal-confirmation");
			try {
				await this.$store.dispatch(ggAbonneConst.GG_SAVE_DEMANDE_OCCASIONNEL, {
					abonne: this.form.abonne,
					demande: this.form.demande,
				});
			} catch (e) {
				if (e.message) {
					toastr.error(e.message);
				} else {
					toastr.error(
						"Une erreur est survenue, veuillez réessayer plus tard."
					);
				}
			}
		},
		getSubObject(object, path) {
			return get(object, path);
		},
	},
};
</script>
