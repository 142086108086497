import regionRulesConst from "./regionRules.constants";

export default {
	[regionRulesConst.FETCH_REGION_RULES](state, regionRules) {
		state.regionRules = regionRules;
	},
	[regionRulesConst.FETCH_MAX_PRICES](state, regionRules) {
		state.maxPrices = regionRules;
	},
};
