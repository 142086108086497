<template>
	<div>
		<h2 class="text-center mb-3">Trains déjà sélectionnés</h2>
		<div class="row">
			<div
				v-for="(trip, index) in selectedTrains"
				:key="index"
				class="col-md-6"
			>
				<hr />
				<p>
					{{ trip.from.label }} départ à {{ trip.departureTime }}
					<i class="fa fa-arrow-right"></i> {{ trip.to.label }} arrivée à
					{{ trip.arrivalTime }}<br />
					TER N°{{ trip.numberTrain }}<br />
					<span v-if="trip.validity && trip.validity.weekdays">
						les
						<span
							v-for="(weekday, id) in trip.validity.weekdays"
							:key="weekday + id"
							>{{ weekday }}s
						</span></span
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TripRecap",
	props: {
		selectedTrains: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style scoped>
div {
	text-align: center;
	vertical-align: middle;
}
</style>
