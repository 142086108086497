<template>
	<b-form-checkbox
		:id="id"
		v-validate="'required:invalidateFalse'"
		name="checkboxValidationRgpd"
		:checked="accordRgpd"
		:state="validateState('checkboxValidationRgpd')"
		@change="$emit('updateCheckRgpd', $event)"
	>
		{{ $t("commun.RGPDCheckbox") }}
		<b-form-invalid-feedback :state="validateState('checkboxValidationRgpd')">
			Veuillez cocher la case
		</b-form-invalid-feedback>
	</b-form-checkbox>
</template>

<script>
export default {
	name: "CheckBoxRgpd",
	inject: ["$validator"],
	props: {
		id: {
			type: String,
			default: "",
		},
		accordRgpd: {
			type: Boolean,
		},
		validateState: {
			type: Function,
			default: () => {},
		},
	},
};
</script>

<style scoped></style>
