import { getters as gettersTrip } from "../trip";
import { getters as gettersSubscription } from "../subscription";
import { getters as gettersSubscriber } from "../subscriber";
import { getters as gettersRegionRules } from "../regionRules";
import { getters as gettersGgRegionRules } from "../ggRegionRules";
import { getters as gettersAbonnement } from "../abonnement";

/**
 * Permet d'importer l'ensemble des getters vuex des différentes fonctionnalités
 */
export default Object.assign(
	{},
	gettersTrip,
	gettersSubscription,
	gettersSubscriber,
	gettersRegionRules,
	gettersGgRegionRules,
	gettersAbonnement
);
